import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import { rootState } from '../../../store/reducers';
import Button from '../../UI/Form/Button/Button';
import './changeBookingConfirmationModal.scss';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '40rem',
  },
};

type Props = {
  isOpen: boolean;
  onModalClose: () => void;
  onModalConfirm: (status: string, partialUpdate?: boolean) => void;
  closeModelWithoutAction: () => void;
  bookingStatus: string;
};

const ChangeBookingConfirmationModal: React.FC<Props> = ({
  onModalConfirm,
  isOpen,
  onModalClose,
  bookingStatus,
  closeModelWithoutAction,
}) => {
  const { t } = useTranslation();

  const isAttachmentsUploading = useSelector(
    (state: rootState) => state.board.isAttachmentsUploading
  );
  // console.log('isAttachmentsUploading', isAttachmentsUploading);

  return (
    <Modal
      closeTimeoutMS={500}
      isOpen={isOpen}
      contentLabel="Confirmation"
      style={customStyles}
      shouldCloseOnOverlayClick={false}
      onRequestClose={onModalClose}
    >
      <div className="booking-actions">
        <p>
          {isAttachmentsUploading
            ? t('clinicBoard_page.upload-msg')
            : t('clinicBoard_page.switchBookingConfirmation')}
        </p>
        {isAttachmentsUploading ? (
          <div className="d-flex justify-content-between">
            <div className="modal-btn-group">
              <Button
                type="button"
                name={t('clinicBoard_page.wait')}
                saveData={closeModelWithoutAction}
              />
            </div>
            <div className="modal-btn-group">
              <Button
                type="button"
                btnType="warning"
                name={t('clinicBoard_page.discard')}
                saveData={onModalClose}
              />
            </div>
          </div>
        ) : bookingStatus === 'completed' ? (
          <div className="d-flex justify-content-between">
            <div className="modal-btn-group">
              <Button
                type="button"
                name={t('clinicBoard_page.save')}
                saveData={() => {
                  onModalConfirm('completed', true);
                }}
              />
            </div>
            <div className="modal-btn-group">
              <Button
                type="button"
                btnType="warning"
                name={t('clinicBoard_page.dismiss')}
                saveData={onModalClose}
              />
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-between">
            <div className="modal-btn-group">
              <Button
                type="button"
                name={t('clinicBoard_page.end-visit')}
                saveData={() => {
                  onModalConfirm('completed');
                }}
              />
              <Button
                type="button"
                btnType="hold"
                name={t('clinicBoard_page.hold')}
                saveData={() => {
                  onModalConfirm('hold');
                }}
              />
            </div>
            <div className="modal-btn-group">
              <Button
                type="button"
                btnType="warning"
                name={t('clinicBoard_page.dismiss')}
                saveData={onModalClose}
              />
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ChangeBookingConfirmationModal;
