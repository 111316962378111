import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { mobileRegex } from '../../../../assets/constants/regexValues';
import routes from '../../../../assets/constants/routes';
import addIcon from '../../../../assets/img/add.svg';
import searchIcon from '../../../../assets/img/search.svg';
import {
  removeSearchedPatients,
  searchPatientByMobile,
  setCurrentPatient,
  shouldSearchInputFocus,
} from '../../../../store/actions';
import {
  PATIENT_PREVIOUS_BOOKINGS,
  SET_PATIENT_ORDERS,
} from '../../../../store/actions/actionTypes';
import { rootState } from '../../../../store/reducers';
import { Patient } from '../../../../types';
import getFullName from '../../../../utils/getFullName';
import parseArabic from '../../../../utils/parseArabic';
import './SearchPatient.scss';

const SearchPatient: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const [searchValue, setSearchValue] = useState('');

  const [showPatientList, setShowPatientList] = useState(false);

  const patients = useSelector(
    (state: rootState) => state.booking.searchedPatients
  );

  const currentPatient = useSelector(
    (state: rootState) => state.booking.currentPatient
  );

  const isFocused = useSelector((state: rootState) => state.booking.isFocused);
  const showPatientButton = useSelector(
    (state: rootState) => state.booking.showPatientButton
  );

  const [ref, setReff] = useState<HTMLInputElement>();

  const divRef = useRef<any>('');

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setShowPatientList(false);
        dispatch(shouldSearchInputFocus(false));
      }
    };

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dispatch, divRef]);

  useEffect(() => {
    if (isFocused && ref) ref.focus();
    // eslint-disable-next-line
  }, [isFocused]);

  const inputFocusHandler = () => {
    setShowPatientList(true);
  };

  const searchPatientHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const mobile = parseArabic(e.target.value);
    // console.log('mobile', mobile);
    setSearchValue(mobile);
    if (mobileRegex.test(mobile)) {
      dispatch(searchPatientByMobile(mobile));
    } else {
      dispatch(removeSearchedPatients());
    }
  };

  const changePatientHandler = (patient: Patient) => {
    if (currentPatient && currentPatient.pk === patient.pk) return;
    dispatch({ type: SET_PATIENT_ORDERS, payload: [] });
    dispatch({ type: PATIENT_PREVIOUS_BOOKINGS, payload: [] });
    dispatch(setCurrentPatient(patient));
    closePatientListOnBlur();
    setSearchValue('');
    dispatch(removeSearchedPatients());
    setShowPatientList(false);
    history.push(routes.ADD_BOOKING);
  };

  const addPatientHandler = () => {
    // console.log('add patient');
    setSearchValue('');
    setShowPatientList(false);
    dispatch(removeSearchedPatients());
    history.push({
      pathname: routes.ADD_PATIENT,
      state: { mobile: searchValue },
    });
  };

  const closePatientListOnBlur = () => {
    // dispatch(removeSearchedPatients());
    setShowPatientList(false);
  };

  return (
    <div ref={divRef} tabIndex={2} className="search d-flex">
      <div className="search-input d-flex align-items-center">
        <img src={searchIcon} alt="searchIcon" />
        <NumberFormat
          getInputRef={(el: any) => setReff(el)}
          placeholder={t('board-header_page.search-msg')}
          onChange={searchPatientHandler}
          onFocus={inputFocusHandler}
          value={searchValue}
          format="###########"
          mask="*"
        />

        {showPatientList && (
          <div className="patient-list scrollHide">
            {patients.length <= 0 && (
              <p className="no-result m-0">
                {t('board-header_page.no-result')}
              </p>
            )}
            {patients.map((patient, index) => (
              <div
                key={index}
                onClick={() => {
                  changePatientHandler(patient);
                }}
                className="patient-item d-flex align-items-center justify-content-between"
              >
                <p className="ar-font m-0">
                  {patient && patient.name ? getFullName(patient.name) : ''}
                </p>
                <p className="mobile m-0">
                  {patient && patient.mobile ? patient.mobile : patient.lmobile}
                </p>
              </div>
            ))}
          </div>
        )}
      </div>
      {showPatientButton && (
        <div className="add-patient ">
          <button onClick={addPatientHandler}>
            <img src={addIcon} alt="addIcon" />
            <span>{t('board-header_page.add-patient')}</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default SearchPatient;
