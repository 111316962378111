import React, { useState, useEffect } from 'react';
import './Setting.scss';
import { useTranslation } from 'react-i18next';

import WorkingDays from '../../components/AddUnit/WorkingDays/WorkingDays';
import ExaminationTypes from '../../components/AddUnit/ExaminationTypes/ExaminationTypes';
import VitalSigns from '../../components/AddUnit/VitalSigns/VitalSigns';
import UnitDetails from '../../components/AddUnit/UnitDetails/UnitDetails';
import ScansTypes from '../../components/AddUnit/ScansTypes/ScansTypes';
import TestsTypes from '../../components/AddUnit/TestsTypes/TestsTypes';

import { useSelector, useDispatch } from 'react-redux';
import { rootState } from '../../store/reducers';
import { useHistory } from 'react-router-dom';
import routes from '../../assets/constants/routes';
import apiService from '../../services/api';
import { getUnitEmployees, updateUnit } from '../../store/actions';
import unitTypes from '../../utils/unitTypes';

import workingDaysIcon from '../../assets/img/workingDays.svg';
import workingDaysActiveIcon from '../../assets/img/workingDaysActive.svg';
import specialityIcon from '../../assets/img/speciality.svg';
import specialityActiveIcon from '../../assets/img/specialityActive.svg';
import vitalSignsIcon from '../../assets/img/vitalSigns.svg';
import vitalSignsActiveIcon from '../../assets/img/vitalSignsActive.svg';
import clinicIcon from '../../assets/img/clinic.svg';
import clinicActiveIcon from '../../assets/img/clinic-active.png';
import { errorToast } from '../../utils/toast';
import { UserRoles } from '../../assets/constants/roles';
import { getUnitDetails } from '../../store/actions/booking.actions';

const segmentsData = {
  workingDays: 'workingDays',
  examinationTypes: 'examinationTypes',
  vitalSigns: 'vitalSigns',
  scansTypes: 'scansTypes',
  testsTypes: 'testsTypes',
  clinicDetails: 'clinicDetails',
};

const Setting: React.FC = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [isCheckEmps, setIsCheckEmps] = useState(false);

  const currentUnitDetails = useSelector(
    (statee: rootState) => statee.booking.currentUnitDetails
  );

  const [selectedSegment, setSelectedSegment] = useState(
    segmentsData.workingDays
  );

  const isSubmitting = useSelector(
    (statee: rootState) => statee.booking.isLoading
  );

  useEffect(() => {
    const changeLangHandler = (lang: string = 'en') => {
      i18n.changeLanguage(lang);
    };
    if (i18n.language === 'ar') changeLangHandler('en');
    // return () => changeLangHandler('ar');
  }, [i18n]);

  useEffect(() => {
    if (
      currentUnitDetails &&
      currentUnitDetails.pk &&
      selectedSegment === segmentsData.clinicDetails
    ) {
      dispatch(getUnitEmployees(currentUnitDetails.pk));
    }
    // eslint-disable-next-line
  }, [currentUnitDetails, selectedSegment]);

  useEffect(() => {
    if (currentUnitDetails && currentUnitDetails.pk)
      dispatch(getUnitDetails(currentUnitDetails.pk));
    // eslint-disable-next-line
  }, []);

  const changeSegmentHandler = (value: string) => {
    setSelectedSegment(value);
  };

  const checkEmpsHandler = async (emps: any[]) => {
    try {
      let isValidEmp = true;
      for (const emp of emps) {
        const data = await apiService.checkUser({ mobile: emp.mobile });
        // console.log('data.data', data.data);
        const empData = data.data;
        if (empData.exist && empData.user.role === UserRoles.Physician) {
          errorToast(
            t('addClinic_page.emp-errMsg', {
              mobile: emp.mobile,
              unitType: currentUnitDetails?.type,
            })
          );
          isValidEmp = false;
          setIsCheckEmps(false);
          return isValidEmp;
        }
      }
      return isValidEmp;
    } catch (error) {
      console.error('error[checkEmpsHandler]', error);
      return false;
    }
  };

  const nextStepHandler = async (payload: { type: string; data: any }) => {
    // console.log('payload', payload);
    if (Object.keys(payload.data).length <= 0) return;
    let isValidEmps = true;
    if (
      payload.type === 'clinicDetails' &&
      payload.data.addeddEmployees &&
      payload.data.addeddEmployees.length > 0
    ) {
      setIsCheckEmps(true);
      isValidEmps = await checkEmpsHandler(payload.data.addeddEmployees);
      setIsCheckEmps(false);
    }
    if (isValidEmps) {
      dispatch(
        updateUnit({
          ...payload,
          id: currentUnitDetails && currentUnitDetails.pk,
        })
      );
    }
  };

  const goBackHandler = async (payload: { type: string; data: any }) => {
    history.push(routes.HOME);
  };

  const sortShifts = (workingDays: any) => {
    const orderedDays: any = {};

    if (!workingDays) return {};
    for (const day of Object.keys(workingDays)) {
      const shifts = workingDays[day];
      const ordered: any = {};
      Object.keys(shifts)
        .sort()
        .forEach((key) => {
          ordered[key] = shifts[key];
        });
      orderedDays[day] = ordered;
    }
    // console.log('orderedDays', orderedDays);
    return orderedDays;
  };

  return (
    <>
      {currentUnitDetails && (
        <div className="setting-page d-flex flex-column" dir={i18n.dir()}>
          <div className="segment align-self-center mt-3">
            <ul>
              <li
                onClick={() => {
                  changeSegmentHandler(segmentsData.workingDays);
                }}
                className={`base token mobile-icon ${
                  selectedSegment === segmentsData.workingDays ? 'selected' : ''
                }`}
              >
                <img
                  className="d-none d-sm-block"
                  src={
                    selectedSegment === segmentsData.workingDays
                      ? workingDaysActiveIcon
                      : workingDaysIcon
                  }
                  alt="mobile"
                />
                {t('addClinic_page.workingDays')}
              </li>

              {currentUnitDetails.type === unitTypes.clinic && (
                <>
                  <li
                    onClick={() => {
                      changeSegmentHandler(segmentsData.examinationTypes);
                    }}
                    className={`base ${
                      selectedSegment === segmentsData.examinationTypes
                        ? 'selected'
                        : ''
                    }`}
                  >
                    <img
                      className="d-none d-sm-block"
                      src={
                        selectedSegment === segmentsData.examinationTypes
                          ? specialityActiveIcon
                          : specialityIcon
                      }
                      alt="personal"
                    />
                    {t('addClinic_page.examinationTypes')}
                  </li>
                  <li
                    onClick={() => {
                      changeSegmentHandler(segmentsData.vitalSigns);
                    }}
                    className={`base ${
                      selectedSegment === segmentsData.vitalSigns
                        ? 'selected'
                        : ''
                    }`}
                  >
                    <img
                      className="d-none d-sm-block"
                      src={
                        selectedSegment === segmentsData.vitalSigns
                          ? vitalSignsActiveIcon
                          : vitalSignsIcon
                      }
                      alt="check"
                    />
                    {t('addClinic_page.vitalSigns')}
                  </li>
                </>
              )}
              {/* {currentUnitDetails.type === unitTypes.lab && (
                <li
                  onClick={() => {
                    changeSegmentHandler(segmentsData.testsTypes);
                  }}
                  className={`base ${
                    selectedSegment === segmentsData.testsTypes
                      ? 'selected'
                      : ''
                  }`}
                >
                  <img
                    className="d-none d-sm-block"
                    src={
                      selectedSegment === segmentsData.testsTypes
                        ? tetsIconActive
                        : tetsIcon
                    }
                    alt="personal"
                  />
                  {t('addLab_page.testsTypes')}
                </li>
              )}
              {currentUnitDetails.type === unitTypes.image && (
                <li
                  onClick={() => {
                    changeSegmentHandler(segmentsData.scansTypes);
                  }}
                  className={`base ${
                    selectedSegment === segmentsData.scansTypes
                      ? 'selected'
                      : ''
                  }`}
                >
                  <img
                    className="d-none d-sm-block"
                    src={
                      selectedSegment === segmentsData.scansTypes
                        ? scanIconActive
                        : scanIcon
                    }
                    alt="personal"
                  />
                  {t('addImagingCenter_page.scansTypes')}
                </li>
              )} */}
              <li
                onClick={() => {
                  changeSegmentHandler(segmentsData.clinicDetails);
                }}
                className={`base ${
                  selectedSegment === segmentsData.clinicDetails
                    ? 'selected'
                    : ''
                }`}
              >
                <img
                  className="d-none d-sm-block"
                  src={
                    selectedSegment === segmentsData.clinicDetails
                      ? clinicActiveIcon
                      : clinicIcon
                  }
                  alt="check"
                />
                {t('addClinic_page.clinicDetails')}
              </li>
            </ul>
          </div>
          <div className="addClinic-page__main d-flex align-self-center  flex-column-reverse flex-sm-row mt-3 p-3">
            {selectedSegment === segmentsData.workingDays ? (
              <WorkingDays
                nextStepHandler={nextStepHandler}
                goBackHandler={goBackHandler}
                currentSegment={segmentsData.workingDays}
                initialState={
                  currentUnitDetails && currentUnitDetails?.workingDays
                    ? sortShifts(currentUnitDetails?.workingDays)
                    : {}
                }
                unit={currentUnitDetails}
                isSettingPage={true}
                isSubmitting={isSubmitting}
              />
            ) : selectedSegment === segmentsData.examinationTypes ? (
              <ExaminationTypes
                goBackHandler={goBackHandler}
                nextStepHandler={nextStepHandler}
                currentSegment={segmentsData.examinationTypes}
                initialState={
                  currentUnitDetails && currentUnitDetails?.examinationTypes
                    ? { ...currentUnitDetails?.examinationTypes }
                    : {}
                }
                unit={currentUnitDetails}
                isSettingPage={true}
                isSubmitting={isSubmitting}
              />
            ) : selectedSegment === segmentsData.vitalSigns ? (
              <VitalSigns
                nextStepHandler={nextStepHandler}
                currentSegment={segmentsData.vitalSigns}
                goBackHandler={goBackHandler}
                initialState={
                  currentUnitDetails && currentUnitDetails?.vitalSigns
                    ? { ...currentUnitDetails?.vitalSigns }
                    : {}
                }
                unit={currentUnitDetails}
                isSettingPage={true}
                isSubmitting={isSubmitting}
              />
            ) : selectedSegment === segmentsData.testsTypes ? (
              <TestsTypes
                nextStepHandler={nextStepHandler}
                goBackHandler={goBackHandler}
                currentSegment={segmentsData.scansTypes}
                initialState={
                  currentUnitDetails && currentUnitDetails?.testsTypes
                    ? { ...currentUnitDetails?.testsTypes }
                    : {}
                }
                unit={currentUnitDetails}
                isSettingPage={true}
                isSubmitting={isSubmitting}
              />
            ) : selectedSegment === segmentsData.scansTypes ? (
              <ScansTypes
                nextStepHandler={nextStepHandler}
                goBackHandler={goBackHandler}
                currentSegment={segmentsData.scansTypes}
                initialState={
                  currentUnitDetails && currentUnitDetails?.scansTypes
                    ? { ...currentUnitDetails?.scansTypes }
                    : {}
                }
                unit={currentUnitDetails}
                isSettingPage={true}
                isSubmitting={isSubmitting}
              />
            ) : (
              <UnitDetails
                nextStepHandler={nextStepHandler}
                goBackHandler={goBackHandler}
                currentSegment={segmentsData.clinicDetails}
                unit={currentUnitDetails}
                isSettingPage={true}
                isSubmitting={isSubmitting || isCheckEmps}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Setting;
