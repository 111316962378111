import React, { useEffect, useState } from 'react';
import VitalInput from '../VitalInputComponent/VitalInputComponent';

import '../AllInputComponent.scss';

const BloodPressureInput = (props: any) => {
  const { onChange, values } = props;
  const [status, setStatus] = useState('Normal');
  const [bloodPressure, setBloodPressure] = useState({ b1: 0, b2: 0 });
  // console.log(values);

  const bloodPressureIndicator = (b1: String, b2: String) => {
    setBloodPressure({ b1: Number(b1), b2: Number(b2) });

    if (Number(b1) < 90 && Number(b2) < 60) {
      setStatus('Low');
    } else if (Number(b1) < 120 && Number(b2) < 80) {
      setStatus('Normal');
    } else if (Number(b1) < 129 && Number(b2) < 80) {
      setStatus('High');
    } else if (Number(b1) < 139 && Number(b2) < 90) {
      setStatus('Stage1');
    } else {
      setStatus('Stage2');
    }
  };

  useEffect(() => {
    bloodPressureIndicator(values.b1, values.b2);
  }, [values]);

  return (
    <div className="col-md-12">
      <div className="form-group row vital-item">
        <label className="col-sm-3 col-form-label">
          Blood<br></br>Pressure:
        </label>
        <div className="col-sm-2">
          <VitalInput
            className="form-control"
            name={'b1'}
            value={values.b1 || ''}
            type="number"
            setStatus={(s: string) => {
              setStatus(s);
            }}
            setBloodPressure={(s: any) => {
              setBloodPressure(s);
            }}
            bloodPressure={bloodPressure}
            onChange={(e: any) => {
              onChange(e.target.name, e.target.value);
            }}
          />
        </div>
        <div className="col-sm-1 ext">/</div>
        <div className="col-sm-2">
          <VitalInput
            className="form-control"
            name={'b2'}
            value={values.b2 || ''}
            type="number"
            setStatus={(s: string) => {
              setStatus(s);
            }}
            setBloodPressure={(s: any) => {
              setBloodPressure(s);
            }}
            bloodPressure={bloodPressure}
            onChange={(e: any) => {
              onChange(e.target.name, e.target.value);
            }}
          />
        </div>
        <div className="col-sm-1 ext mr-auto">
          <span className="span-bottom">mm</span>
        </div>
        {values.b2 && (
          <div className="col-sm-1">
            <i className={`${status} fas fa-flag`}></i>
          </div>
        )}
        {values.b2 && <div className={`${status} col-sm-1`}>{status}</div>}
      </div>
    </div>
  );
};
export default BloodPressureInput;
