import moment from 'moment';
import i18n from '../services/i18n';

export const getBookingTime = (startDate: Date): string => {
  return moment(startDate).format('HH:mm').toString();
};

export const isSameDate = (firstDate: Date, secondDate: Date) => {
  return moment(firstDate).isSame(secondDate, 'day');
};

export const formatDate = (date: any) => {
  return moment(new Date(date)).format('DD/MM/yyyy');
};

export const formatTodayDate = (date: any) => {
  return moment(date).format('DD/MM');
};

export const formatCAlDate = (date: Date) => {
  return moment(date).format('YYYY-MM-DD');
};

export const formatCAlDateShort = (date: any) => {
  return moment(date).format('YY-MM-DD');
};

export const formatDateWithDay = (date: any) => {
  return moment(date).format('DD/MM/yyyy dddd');
};

export const formatTime = (date: any) => {
  return moment(date).format('HH:mm');
};

export const formatTimeAmPm = (date: any) => {
  return moment(date).format('hh:mm a');
};

export const getDayName = (date: any) => {
  // moment.locale('ar');
  return moment(new Date(date)).locale(i18n.language).format('dddd');
};

export const getMonthName = (date: any) => {
  return moment(new Date(date)).locale(i18n.language).format('MMMM DD');
};

export const resetDayTime = (date: Date) => {
  return moment(date).hour(0).minute(0).second(0).format();
};

export const addDayToDate = (date: Date, amount: number) => {
  return moment(date).add(amount, 'day').format();
};

export const getAgeFromBD = (birthday: Date): string => {
  const bd = moment(birthday);
  const today = moment();
  const years = today.diff(bd, 'years');
  const months = today.diff(bd, 'months');
  const days = today.diff(bd, 'days');
  // console.log(birthday);
  // console.log(years);
  // console.log(months);
  // console.log(days);

  return years > 0
    ? `${years} years`
    : months > 0
    ? `${months} months`
    : `${days} days`;
};

export const getAgeByYearFromBD = (birthday: Date): string => {
  const bd = moment(birthday);
  const today = moment();
  const years = today.diff(bd, 'years');
  // console.log(birthday);
  // console.log(years);
  // console.log(months);
  // console.log(days);

  return `${years}`;
};

export const getDateFromAge = (amount: number, type: any) => {
  return moment().subtract(amount, type).format();
};

export const getMonthNameFromNumber = (value: number) => {
  const monthName = moment(value, 'M').format('MMMM');
  return monthName;
};

export const getMonthNumber = (month: string) => {
  const monthNumber = +moment().month(month).format('M') - 1;
  return monthNumber;
};

export const daysInMonth = (date: Date) => {
  return Array.from(
    Array(moment(new Date(date)).daysInMonth()),
    (_, i) => i + 1
  );
};

export const monthsInYear = () => {
  return { months: moment.months(), year: moment().year() };
};

export const addMonthToYear = (date: Date) => {
  return moment(date).add(1, 'month').format();
  // return { months: moment.months(), year: moment().year() };
};
