import React, { useEffect, useState } from 'react';
import VitalInput from '../VitalInputComponent/VitalInputComponent';

import '../AllInputComponent.scss';
import { indicator } from '../../../../../utils/indicator';

const TemperatureInput = (props: any) => {
  const { onChange, values } = props;
  const [status, setStatus] = useState('');

  useEffect(() => {
    setStatus(indicator(values.temp, 36.1, 37.2));
  }, [values]);

  return (
    <div className="col-md-12">
      <div className="form-group row vital-item">
        <label className="col-sm-3 col-form-label">Temperature:</label>
        <div className="col-sm-2">
          <VitalInput
            className="form-control"
            name={'temp'}
            value={values.temp || ''}
            type="number"
            setStatus={(s: string) => {
              setStatus(s);
            }}
            onChange={(e: any) => {
              onChange(e.target.name, e.target.value);
            }}
          />
        </div>
        <div className="col-sm-1 ext mr-auto">
          <span className="span-bottom">C</span>
        </div>
        {values.temp && (
          <div className="col-sm-1">
            <i className={`${status} fas fa-flag`}></i>
          </div>
        )}
        {values.temp && <div className={`${status} col-sm-1`}>{status}</div>}
      </div>
    </div>
  );
};

export default TemperatureInput;
