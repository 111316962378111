import axios from 'axios';
import { getStoredData } from '../../utils/storage';
import { errorToast, successToast } from '../../utils/toast';
import { apiUrl } from '../../utils/serverUrl';
// const { REACT_APP_API_URL } = process.env;

class ApiService {
  private services: any = {
    register: {
      url: '/clinician/register',
      type: 'POST',
    },
    login: {
      url: '/clinician/login',
      type: 'POST',
    },
    checkUser: {
      url: '/clinician/checkUser',
      type: 'GET',
    },
    getUserProfile: {
      url: '/clinician/getUserProfile',
      type: 'GET',
    },
    updateUserProfile: {
      url: '/clinician/',
      type: 'PUT',
    },
    updateUserPassword: {
      url: '/clinician/password',
      type: 'PUT',
    },
    changePasswordUser: {
      url: '/clinician/changePassword',
      type: 'POST',
    },
    checkToken: {
      url: '/clinician/checkToken',
      type: 'POST',
    },
    requestToken: {
      url: '/clinician/requestToken',
      type: 'GET',
    },
    getUploadAccess: {
      url: '/image/',
      type: 'GET',
    },
    signUrl: {
      url: '/image/sign',
      type: 'GET',
    },
    getClinicianUnits: {
      url: '/unit/getUserUnits',
      type: 'GET',
    },
    getUnitDetails: {
      url: '/unit/{id}',
      type: 'GET',
    },
    addUnit: {
      url: '/unit/',
      type: 'POST',
    },
    updateUnit: {
      url: '/unit/{id}',
      type: 'PUT',
    },
    getUnitEmployees: {
      url: '/unit/{id}/employees',
      type: 'GET',
    },
    getUnitTestsTypes: {
      url: '/unit/testsTypes/{type}',
      type: 'GET',
    },
    completeProfile: {
      url: '/clinician/completeProfile',
      type: 'POST',
    },
    addPatient: {
      url: '/patient',
      type: 'POST',
    },
    updatePatient: {
      url: '/patient',
      type: 'PUT',
    },
    searchPatient: {
      url: '/patient/search',
      type: 'GET',
    },
    findPatientById: {
      url: '/patient/{id}',
      type: 'GET',
    },
    searchPatientWithUnitBooking: {
      url: '/patient/search/{unitId}',
      type: 'GET',
    },
    getPatientOrders: {
      url: '/patient/{id}/orders',
      type: 'GET',
    },
    addPatientOrders: {
      url: '/patient/{id}/orders',
      type: 'POST',
    },
    addBooking: {
      url: '/booking',
      type: 'POST',
    },
    updateBooking: {
      url: '/booking',
      type: 'PUT',
    },
    clinicBookings: {
      url: '/booking',
      type: 'GET',
    },
    patientPreviousBooking: {
      url: '/booking/previous/{id}',
      type: 'GET',
    },
    patientUpCommingBooking: {
      url: '/booking/comming/{id}',
      type: 'GET',
    },
    patientUnitBookings: {
      url: '/booking/{pId}',
      type: 'GET',
    },
    markComplete: {
      url: '/booking/{id}/complete',
      type: 'PUT',
    },
    updateBookingTest: {
      url: '/booking/{id}/tests',
      type: 'POST',
    },
    deleteBookingTest: {
      url: '/booking/{id}/tests',
      type: 'DELETE',
    },
    endPatientBooking: {
      url: '/patient/{id}/encounter',
      type: 'POST',
    },
    getPatientBookingHistory: {
      url: '/patient/{id}/history',
      type: 'POST',
    },
    searchSymp: {
      url: '/search/symp',
      type: 'GET',
    },
    searchDiag: {
      url: '/search/diag',
      type: 'GET',
    },
    searchAllergy: {
      url: '/search/allergy',
      type: 'GET',
    },
    searchChronic: {
      url: '/search/chronic',
      type: 'GET',
    },
    searchOrder: {
      url: '/search/order',
      type: 'GET',
    },
    searchPresc: {
      url: '/search/presc',
      type: 'GET',
    },
    getLabBookings: {
      url: '/booking/lab/{id}',
      type: 'GET',
    },
    getPatientLabEncounters: {
      url: '/patient/{id}/lab',
      type: 'GET',
    },
    addPatientLabEncounters: {
      url: '/patient/{id}/lab',
      type: 'POST',
    },
    getPatientRadEncounters: {
      url: '/patient/{id}/rad',
      type: 'GET',
    },
    addPatientRadEncounters: {
      url: '/patient/{id}/rad',
      type: 'POST',
    },
    getUnitMonthStatistics: {
      url: '/statistics/',
      type: 'GET',
    },
    getPatientData: {
      url: '/patient/link/{link}',
      type: 'GET',
    },
    validatePromoCode: {
      url: '/clinician/promo/{promoCode}',
      type: 'GET',
    },
    searchPlaces: {
      url: '/unit/location/places',
      type: 'GET',
    },
    subscribeUnit: {
      url: '/unit/{id}/subscribe',
      type: 'POST',
    },
    redeemPoints: {
      url: '/unit/{id}/redeem',
      type: 'POST',
    },
    getSubscriptionPlans: {
      url: '/unit/plans',
      type: 'GET',
    },
  };

  async send(serviceName: any, options: any): Promise<any> {
    const service = { ...this.services[serviceName] };
    let afterReplace: string;
    let v: any;
    let hideMessage: false;
    afterReplace = '';
    for (const option of Object.keys(options)) {
      option === 'hideMsg'
        ? (hideMessage = options[option])
        : (v = options[option]);
      afterReplace = service.url.replace(new RegExp(`{${option}}`, 'm'), v);
      if (service.url !== afterReplace) {
        delete options[option];
        service.url = afterReplace;
      }
    }
    const ss = this.get(service);
    const headers: any = {
      'Content-Type': 'application/json; charset=utf-8',
      Accept: 'application/json',
    };
    const lang = localStorage.getItem('i18nextLng') || null;
    if (lang) {
      headers['accept-language'] = lang;
    }
    const token = getStoredData('authToken');
    if (token) {
      headers.authorization = `Bearer ${token}`;
    }

    return axios
      .request({
        baseURL: ss.url,
        method: ss.type,
        headers,
        data: ss.type !== 'GET' ? options : {},
        params: ss.type === 'GET' ? { ...options, lang } : { lang },
      })
      .then((data) => {
        if (
          data &&
          data.data &&
          data.data.msg &&
          ![true, false].includes(data.data.exist)
        ) {
          if (!hideMessage) {
            successToast(data.data.msg);
          }
        }
        return data;
      })
      .catch((error) => {
        if (error) {
          if (error.message === 'Network Error' && !error.response) {
            console.error('Network Error');
            errorToast(error.message);
            // throw new Error(error.message);
            return Promise.reject(error.message);
          } else if (error.response) {
            console.error('response error', error.response);
            errorToast(error.response.data.msg);
            return Promise.reject(error.response);
          } else if (error.request) {
            console.error('request error', error.request);
            return Promise.reject(error.message);
          }
        }
      });
  }

  private get(service: { url: string; type: any }) {
    return { url: apiUrl + service.url, type: service.type };
  }
}

export default new ApiService();
