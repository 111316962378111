import React, { useEffect, useState } from 'react';
import VitalInput from '../VitalInputComponent/VitalInputComponent';

import '../AllInputComponent.scss';
import { getAgeByYearFromBD } from '../../../../../utils/moment';
import { useSelector } from 'react-redux';
import { rootState } from '../../../../../store/reducers';
import { indicator } from '../../../../../utils/indicator';

const RespiratoryRateInput = (props: any) => {
  const { onChange, values, DOB } = props;
  const [status, setStatus] = useState('Normal');

  const age = getAgeByYearFromBD(DOB);

  useEffect(() => {
    if (Number(age) < 1) {
      setStatus(indicator(values.respiratoryRate, 30, 60));
    } else if (Number(age) < 3) {
      setStatus(indicator(values.respiratoryRate, 24, 40));
    } else if (Number(age) < 6) {
      setStatus(indicator(values.respiratoryRate, 22, 34));
    } else if (Number(age) < 12) {
      setStatus(indicator(values.respiratoryRate, 18, 30));
    } else {
      setStatus(indicator(values.respiratoryRate, 12, 16));
    }
  }, [values]);

  return (
    <div className="col-md-12">
      <div className="form-group row vital-item">
        <label className="col-sm-3 col-form-label">
          Respiratory <br></br>Rate:
        </label>
        <div className="col-sm-2">
          <VitalInput
            className="form-control"
            name={'respiratoryRate'}
            value={values.respiratoryRate || ''}
            type="number"
            setStatus={(s: string) => {
              setStatus(s);
            }}
            onChange={(e: any) => {
              onChange(e.target.name, e.target.value);
            }}
            age={age}
          />
        </div>
        <div className="col-sm-1 ext mr-auto">
          <span className="span-bottom">b/min</span>
        </div>
        {values.respiratoryRate && (
          <div className="col-sm-1">
            <i className={`${status} fas fa-flag`}></i>
          </div>
        )}
        {values.respiratoryRate && (
          <div className={`${status} col-sm-1`}>{status}</div>
        )}
      </div>
    </div>
  );
};

export default RespiratoryRateInput;
