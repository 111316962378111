import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import roles, { UserRoles } from '../../../../assets/constants/roles';

import mobileIcon from '../../../../assets/img/mobile.svg';
import roleIcon from '../../../../assets/img/role.svg';
import closeIcon from '../../../../assets/img/close.svg';
import { mobileRegex } from '../../../../assets/constants/regexValues';
import parseArabic from '../../../../utils/parseArabic';
import unitTypes from '../../../../utils/unitTypes';

type Props = {
  name: string;
  index: number;
  initEmp: any;
  setInputHandler: (
    name: string,
    keyName: string,
    value: string | null
  ) => void;
  removeEmployeeHandler: (name: number) => void;
  unitType: string;
};

const AddSingleEmployee: React.FC<Props> = ({
  name,
  index,
  initEmp,
  setInputHandler,
  removeEmployeeHandler,
  unitType,
}) => {
  const { t, i18n } = useTranslation();
  const [mobileError, setMobileError] = useState('');
  const [employeeRoles, setEmployeeRoles] = useState<any>({});
  const [selectValue, setSelectValue] = useState(() => {
    const initialRole =
      initEmp && initEmp.role ? initEmp.role : UserRoles.Assistant;
    return initialRole;
  });

  const [mobileValue, setMobileValue] = useState(
    initEmp && initEmp.mobile ? initEmp.mobile : ''
  );

  // const inputValueProps =
  //   initEmp && initEmp.mobile ? { value: initEmp.mobile } : {};
  // let mobilNu = initEmp && initEmp.mobile ? initEmp.mobile : '';
  const setInputHandlerr = (e: React.ChangeEvent<HTMLInputElement>) => {
    // console.log('e.target.value', e.target.value);
    const mobilNu = parseArabic(e.target.value);
    // console.log('mobilNu', mobilNu);
    setMobileValue(mobilNu);
    if (mobileRegex.test(mobilNu)) {
      setMobileError('');
      setInputHandler(name, 'mobile', mobilNu);
      setInputHandler(name, 'role', selectValue);
    } else {
      setMobileError(t('booking_page.valid-mobile'));
      setInputHandler(name, 'mobile', mobilNu);
      setSelectValue(UserRoles.Assistant);
    }
  };

  const setselectHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectValue(e.target.value);
    setInputHandler(name, 'role', e.target.value);
  };

  useEffect(() => {
    let values = {};
    if (unitType === unitTypes.clinic) {
      const { assistant, receptionist, nurse } = roles;
      values = { assistant, receptionist, nurse };
    } else if (unitType === unitTypes.lab) {
      const { assistant, receptionist, nurse, labTech } = roles;
      values = { assistant, receptionist, nurse, labTech };
    } else if (unitType === unitTypes.image) {
      const { assistant, receptionist, nurse, radioTech } = roles;
      values = { assistant, receptionist, nurse, radioTech };
    }
    setEmployeeRoles(values);
  }, [unitType]);

  return (
    <div className="form d-flex flex-column flex-sm-row p-3">
      <div className="mobile d-flex flex-column">
        <div className="name-group mobile-icon d-flex align-items-center mb-2">
          <img src={mobileIcon} className="" alt="mobile icon" />
          <h6>{t('setting_page.mobile')}</h6>
        </div>
        <div className="inputs">
          <input
            autoComplete="off"
            type="number"
            name="mobile"
            onChange={setInputHandlerr}
            value={mobileValue}
            // {...inputValueProps}
          />
          <div className="error">
            {mobileError ? <span className="error">{mobileError}</span> : null}
          </div>
        </div>
      </div>
      <div className="role d-flex flex-column">
        <div className="name-group d-flex align-items-center mb-2">
          <div className="d-flex align-items-center" style={{ flex: 1 }}>
            <img src={roleIcon} alt="role icon" />
            <h6>{t('employess_page.emp-role')}</h6>
          </div>
          {index > 0 && (
            <div className="close-icon">
              <img
                src={closeIcon}
                alt="close icon"
                onClick={() => {
                  removeEmployeeHandler(index);
                }}
              />
            </div>
          )}
        </div>
        <div className="inputs">
          <select name="role" value={selectValue} onChange={setselectHandler}>
            {Object.keys(employeeRoles).map((key) => (
              <option value={key} key={`option-${key}`}>
                {employeeRoles[key][i18n.language]}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default AddSingleEmployee;
