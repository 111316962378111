import api from './api';

export const addUnit = async (payload: any) => {
  return await api.send('addUnit', payload);
};

export const getClinicianUnits = async () => {
  return await api.send('getClinicianUnits', {});
};

export const getUnitDetails = async (payload: any) => {
  return await api.send('getUnitDetails', payload);
};

export const getUnitTestsTypes = async (payload: any) => {
  return await api.send('getUnitTestsTypes', payload);
};

export const getUnitEmployees = async (payload: any) => {
  return await api.send('getUnitEmployees', payload);
};

export const updateUnit = async (payload: any) => {
  return await api.send('updateUnit', payload);
};

export const searchPlaces = async (payload: any) => {
  return await api.send('searchPlaces', payload);
};
export const subscribeUnit = async (payload: any) => {
  return await api.send('subscribeUnit', payload);
};
export const redeemPoints = async (payload: any) => {
  return await api.send('redeemPoints', payload);
};

export const getSubscriptionPlans = async (payload: any) => {
  return await api.send('getSubscriptionPlans', payload);
};
