import React from 'react';
import { useDispatch } from 'react-redux';
import {
  getBookingColor,
  getBookingStatusColor,
} from '../../../../../utils/booking';
import getFullName from '../../../../../utils/getFullName';
import { colors } from '../../../../../utils/getRandomColor';
import './BookingItem.scss';
import {
  GirlAvatarIcon,
  OutLineCheck,
  PauseIcon,
  SolidCheck,
} from '../../../../../assets/constants/images';
import avatarIcon from '../../../../../assets/img/avatar.svg';
import { ClinicData } from '../../../../../types';
import { StartExaminationAction } from '../../../../../store/actions/board.actions';

type Props = {
  ind: number;
  setCurrentBookingHandler: (booking: any) => void;
  booking: any;
  currentBooking: any;
  calcAgeHandler: (patient: any) => string;
  currentUnitDetails: ClinicData | null;
};

const BookingItem = (props: Props) => {
  const dispatch = useDispatch();
  const {
    setCurrentBookingHandler,
    booking,
    currentBooking,
    calcAgeHandler,
    currentUnitDetails,
  } = props;
  return (
    <div
      onClick={() => {
        setCurrentBookingHandler(booking);
        dispatch(StartExaminationAction(true));
      }}
      className="booking-list-item  d-flex justify-content-between align-items-center"
      style={{
        backgroundColor: `${getBookingColor(booking, currentUnitDetails)}`,
        borderRight: `8px solid ${
          currentBooking?.sk === booking.sk
            ? colors.current
            : getBookingColor(booking, currentUnitDetails)
        }`,
      }}
    >
      <div className="d-flex  justify-content-start">
        {booking ? (
          booking.patient.gender === 'male' ? (
            <img className="avatar" src={avatarIcon} alt="avatarIcon" />
          ) : (
            // <AvatarIcon className="avatar" />
            <GirlAvatarIcon className="avatar" />
          )
        ) : (
          ''
        )}
      </div>
      <div className="d-flex w-75 align-items-start  flex-column justify-content-end">
        <p>{getFullName(booking ? booking.patient.name : null)}</p>
        <p className="age">{booking ? calcAgeHandler(booking.patient) : ''}</p>
      </div>
      <div className="d-flex justify-content-end ">
        <div className="d-flex tag payment">
          {booking.paymentStatus !== 'paid' ? <span>$</span> : null}
        </div>

        <div className={`d-flex tag status ${booking.status}`}>
          <span>
            {booking.status === 'hold' ? (
              <PauseIcon fill={getBookingStatusColor(booking.status)} />
            ) : booking.status === 'completed' ? (
              <SolidCheck fill={getBookingStatusColor(booking.status)} />
            ) : (
              <OutLineCheck fill={getBookingStatusColor(booking.status)} />
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default BookingItem;
