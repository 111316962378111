import React, { useState, useRef, useEffect } from 'react';
import './ClinicianUnits.scss';

import { useSelector, useDispatch } from 'react-redux';
import { rootState } from '../../../../store/reducers';
import {
  setCurrentEmpUnit,
  setCurrentBooking,
  setClinicianUnits,
} from '../../../../store/actions';
import { useTranslation } from 'react-i18next';
import getFullName from '../../../../utils/getFullName';
import { Unit } from '../../../../types';

import arrowIcon from '../../../../assets/img/arrow-down.svg';
import avatarIcon from '../../../../assets/img/avatar.png';
import clinicIcon from '../../../../assets/img/clinic-icon.svg';
import labIcon from '../../../../assets/img/Lab.svg';
import imagingCenterIcon from '../../../../assets/img/Center.svg';
import addIcon from '../../../../assets/img/add.svg';

import unitTypes from '../../../../utils/unitTypes';
import { useHistory } from 'react-router-dom';
import routes from '../../../../assets/constants/routes';
import { isAuthenticatedPhysician } from '../../../../utils/storage';
import { UserRoles } from '../../../../assets/constants/roles';
import { setBoardCurrentBooking } from '../../../../store/actions/board.actions';

const changeAvatar = (type: string) => {
  if (type === unitTypes.clinic) return clinicIcon;
  if (type === unitTypes.lab) return labIcon;
  if (type === unitTypes.image) return imagingCenterIcon;
  return avatarIcon;
};

type Props = {
  units: Unit[];
  resetStore: () => void;
};

const ClinicianUnits: React.FC<Props> = ({ units, resetStore }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef<any>(null);

  const [openDropDown, setOpenDropDown] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpenDropDown(false);
      }
    };

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  const currentEmpUnit = useSelector(
    (state: rootState) => state.booking.currentEmpUnit
  );

  const changeCurrentUnitHandler = (unit: any) => {
    if (currentEmpUnit && currentEmpUnit.sk === unit.sk) return;
    const allUnit = [...units];
    // console.log('allUnit', allUnit);
    resetStore();
    dispatch(setClinicianUnits(allUnit));
    dispatch(setCurrentEmpUnit(unit));
    dispatch(setCurrentBooking(null));
    dispatch(setBoardCurrentBooking(null));
    openDropDownHandlerOnBlur();
    history.push(
      [UserRoles.Physician, UserRoles.LabTech, UserRoles.RadioTech].includes(
        unit.role
      )
        ? routes.BOARD
        : routes.BOOKING
    );
  };

  const openDropDownHandlerOnBlur = () => {
    setOpenDropDown(false);
  };

  const openDropDownHandler = () => {
    setOpenDropDown(!openDropDown);
  };

  return (
    <div
      tabIndex={-1}
      className="selectt d-flex justify-content-between align-items-center mr-0 mr-sm-5 ml-0 ml-sm-5"
      onClick={openDropDownHandler}
      ref={ref}
    >
      {currentEmpUnit && (
        <div className="current-dr d-flex align-items-center">
          <img
            className="avatar"
            src={changeAvatar(currentEmpUnit.type)}
            alt=""
          />
          <p>
            {currentEmpUnit && currentEmpUnit.unitName
              ? currentEmpUnit.unitName
              : currentEmpUnit && currentEmpUnit.owner
              ? getFullName(currentEmpUnit.owner.name)
              : ''}
          </p>
        </div>
      )}
      <img src={arrowIcon} alt="arrowIcon" />
      {openDropDown ? (
        <div className="dr-list">
          {units.map((unit, index) => (
            <div
              key={index}
              onClick={() => {
                changeCurrentUnitHandler(unit);
              }}
              className="dr-item d-flex align-items-center"
            >
              <img
                className="avatar"
                src={changeAvatar(unit.type)}
                alt="avatarIcon"
              />
              <p>
                {unit && unit.unitName
                  ? unit.unitName
                  : unit && unit.owner
                  ? getFullName(unit.owner.name)
                  : ''}
              </p>
            </div>
          ))}
          {isAuthenticatedPhysician() && (
            <div className="dr-item d-flex align-items-center justify-content-center">
              <div className="add-unit flex-fill align-self-stretch d-flex align-items-center">
                <button
                  className="d-flex align-items-center justify-content-center"
                  onClick={() => {
                    openDropDownHandlerOnBlur();
                    history.push(routes.SELECT_UNIT_TYPE);
                  }}
                >
                  <img src={addIcon} alt="addIcon" />
                  <span>{t('board-header_page.add-unit')}</span>
                </button>
              </div>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default ClinicianUnits;
