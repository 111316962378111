import React, { useState, useEffect } from 'react';

import './BmiInput.scss';

const BmiInput = (props: any) => {
  const { values } = props;
  const [status, setStatus] = useState('Normal');

  const bmiIndicator = (values.bmi / 40) * 100;
  const indicator =
    bmiIndicator > 97 ? 97 : bmiIndicator <= 4 ? 0 : bmiIndicator;

  useEffect(() => {
    if (!values.bmi) {
      values.bmi = '';
    }
    if (values.bmi < 18.5) {
      setStatus('Underweight');
    } else if (values.bmi < 25) {
      setStatus('Normal');
    } else if (values.bmi < 30) {
      setStatus('Overweight');
    } else if (values.bmi < 35) {
      setStatus('Obese1');
    } else {
      setStatus('Obese2');
    }
  }, [values]);

  return (
    <div className="col-md-12">
      <div className="form-group row vital-item">
        <label className="col-sm-3 col-form-label">BMI:</label>
        <div className="col-sm-2">
          <Bmi
            className="form-control"
            name={'bmi'}
            value={values.bmi || ''}
            type="number"
          />
        </div>
        <div className="col-sm-1 ext  mr-auto">
          <span className="span-bottom">b/min</span>
        </div>
        {console.log(values.bmi)}
        {values.bmi !== '' && (
          <div className="col-sm-1">
            <i className={`${status} fas fa-flag`}></i>
          </div>
        )}
        {values.bmi !== '' && (
          <div className={`${status} col-sm-1`}>{status}</div>
        )}
        <div className="col-md-12 progress-container" style={{ color: 'red' }}>
          <span className="dot" style={{ left: `${indicator}%` }}></span>
          <div className="progress-parent">
            <div className="progress-bar-color progress-child-underweight"></div>
            <div className="progress-bar-color progress-child-normal"></div>
            <div className="progress-bar-color progress-child-overweight"></div>
            <div className="progress-bar-color progress-child-obese1"></div>
            <div className="progress-bar-color progress-child-obese2"></div>
          </div>
          <div className="progress-label">
            <div className="progress-bar-label progress-child-underweight-label">
              18.5
            </div>
            <div className="progress-bar-label progress-child-normal-label">
              25
            </div>
            <div className="progress-bar-label progress-child-obese1-label">
              30
            </div>
            <div className="progress-bar-label progress-child-obese1-label">
              35
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BmiInput;

const Bmi = (props: any) => {
  const { className, name, value } = props;
  return (
    <input
      readOnly
      className={`${className}`}
      name={name}
      value={value}
      type="number"
    />
  );
};
