import React, { useState } from 'react';
import { vitalValidations } from '../../../../../assets/constants/vitalValidations';
import { indicator } from '../../../../../utils/indicator';

import '../AllInputComponent.scss';

const VitalInput = (props: any) => {
  const {
    className,
    name,
    value,
    onChange,
    setStatus,
    setBloodPressure,
    bloodPressure,
    age,
  } = props;
  const { min, max }: any = vitalValidations[name];
  const [error, setError] = useState(false);

  const bloodPressureIndicator = (b1: String, b2: String) => {
    setBloodPressure({ b1: Number(b1), b2: Number(b2) });

    if (Number(b1) < 90 && Number(b2) < 60) {
      setStatus('Low');
    } else if (Number(b1) < 120 && Number(b2) < 80) {
      setStatus('Normal');
    } else if (Number(b1) < 129 && Number(b2) < 80) {
      setStatus('High');
    } else if (Number(b1) < 139 && Number(b2) < 90) {
      setStatus('Stage1');
    } else {
      setStatus('Stage2');
    }
  };
  return (
    <input
      className={`${className} ${error ? 'error' : ''}`}
      name={name}
      value={value}
      type="Number"
      min={min}
      max={max}
      onChange={(e) => {
        if (Number(e.target.value) < 0 || Number(e.target.value) > max) {
          setError(true);
        } else if (Number(e.target.value) < min) {
          setError(true);
          onChange(e);
        } else {
          setError(false);
          onChange(e);
        }
        e.target.value = e.target.value.replace(/[^0-9.]/g, '');
        if (setStatus) {
          if (name === 'b1')
            bloodPressureIndicator(e.target.value, bloodPressure.b2);
          else if (name === 'b2')
            bloodPressureIndicator(bloodPressure.b1, e.target.value);
          else if (name === 'temp')
            setStatus(indicator(e.target.value, 36.1, 37.2));
          else if (name === 'pulseRate')
            setStatus(indicator(e.target.value, 60, 100));
          else if (name === 'fastBloodGlucose')
            setStatus(indicator(e.target.value, 0, 199));
          else if (name === 'respiratoryRate') {
            if (Number(age) < 1) {
              setStatus(indicator(e.target.value, 30, 60));
            } else if (Number(age) < 3) {
              setStatus(indicator(e.target.value, 24, 40));
            } else if (Number(age) < 6) {
              setStatus(indicator(e.target.value, 22, 34));
            } else if (Number(age) < 12) {
              setStatus(indicator(e.target.value, 18, 30));
            } else {
              setStatus(indicator(e.target.value, 12, 16));
            }
          }
        }
      }}
    />
  );
};

export default VitalInput;
