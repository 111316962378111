import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import addImgIcon from '../../../../assets/img/add-img.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/img/delete.svg';
import { DELETE_ATTACHMENTS_FILE } from '../../../../store/actions/actionTypes';
import { rootState } from '../../../../store/reducers';
import './AttachmentsComponent.scss';
import UploadManager from './UploadManagerProgress/UploadManagerProgress';

const AttachmentsComponent: React.FC = () => {
  const ref = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();

  const [attaches, setAttaches] = useState<any[]>([]);

  const { attachments, reservation } = useSelector(
    (state: rootState) => state.board
  );

  const currentBooking = useSelector(
    (state: rootState) => state.booking.currentBooking
  );

  const [uploadingImages, setUploadingImages] = useState<File[]>([]);

  useEffect(() => {
    // console.log('reservation', reservation);
    // console.log('attachments', attachments);
    const bookingId = currentBooking
      ? currentBooking.sk
      : reservation.b?.date
      ? reservation.b.date.toString()
      : null;
    if (!bookingId || attachments.length === 0) {
      setAttaches([]);
      return;
    }
    const ff = attachments.filter(
      (ele: any) => !ele.hasOwnProperty('bId') || ele.bId === bookingId
    );
    // console.log('ff', ff);
    setAttaches(ff);
  }, [attachments, currentBooking, reservation]);

  const openFileExplorerHandler = () => {
    const node = ref.current;
    if (node) {
      node.click();
    }
  };

  const selectImagesHandler = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = e.target.files;
    if (!files) return;

    const filteredImages = Array.from(files).filter(
      (file) => file.type || file.type.startsWith('image')
    );

    setUploadingImages(filteredImages);
  };

  const deleteImage = (idx: number) => {
    dispatch({
      type: DELETE_ATTACHMENTS_FILE,
      payload: idx,
    });
  };

  const openInNewTab = (image: { localUrl?: string; url: string }) => {
    // console.log('image', image);
    const url = image.localUrl ? image.localUrl : image.url;
    window.open(url, '_blank');
  };

  return (
    <div className="component procedures-component d-flex flex-column align-items-start">
      <div className="d-flex justify-content-between w-100">
        <div className="title align-self-center ">Attachments:</div>
        <div className="">
          <UploadManager
            files={uploadingImages}
            bookingId={
              currentBooking
                ? currentBooking.sk
                : reservation.b?.date
                ? reservation.b.date.toString()
                : null
            }
          />
        </div>
      </div>
      <div
        className="add-img d-flex align-items-center my-3"
        onClick={openFileExplorerHandler}
      >
        <input
          type="file"
          ref={ref}
          accept="image/*"
          onChange={selectImagesHandler}
          multiple
        />
        <img src={addImgIcon} alt="" />
        <p>upload Images</p>
      </div>
      <div className="procedures-container d-flex flex-wrap justify-content-between">
        {attaches.length > 0 &&
          attaches.map((image: any, idx: number) => (
            <div
              key={image.url + idx}
              className="img-container d-flex align-items-start justify-content-between"
            >
              <div className="main-img" onClick={() => openInNewTab(image)}>
                <img src={image.localUrl ? image.localUrl : image.url} alt="" />
              </div>

              <div
                className="delete-icon"
                onClick={() => {
                  deleteImage(idx);
                }}
              >
                <DeleteIcon fill="var(--color-danger)" />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default AttachmentsComponent;
