import React from 'react';
import { useTranslation } from 'react-i18next';
import bookingStatus from '../../assets/constants/bookingStatus';
import GirlIcon from '../../assets/img/GirlIcon.svg';
import userIcon from '../../assets/img/Mask Group 343.svg';
import calenderIcon from '../../assets/img/Mask Group 358.svg';
import { Booking, Patient } from '../../types';
import getFullName from '../../utils/getFullName';
import { formatDate, getAgeFromBD } from '../../utils/moment';

type BoardPatientInfoProps = {
  currentPatient: Patient;
  currentBooking: Booking | null;
  reservation: any;
  onSave: (status: string, partialUpdate?: boolean) => void;
};

const BoardPatientInfo: React.FC<BoardPatientInfoProps> = ({
  currentPatient,
  currentBooking,
  reservation,
  onSave,
}) => {
  const { t } = useTranslation();
  const calcAgeHandler = (patient: any) => {
    if (!patient) return '';
    return getAgeFromBD(new Date(patient.dob));
  };
  return (
    <div className="patient-info">
      <div className="d-flex justify-content-between">
        <div className="d-flex flex-fill flex-row bd-highlight">
          <img
            src={currentPatient?.gender === 'male' ? userIcon : GirlIcon}
            alt=""
          />
          <div className="name">
            {getFullName(currentPatient?.name || null)}
          </div>
        </div>
        <div className="d-flex flex-fill flex-row bd-highlight">
          <img src={calenderIcon} alt="" />
          <div className="age">{calcAgeHandler(currentPatient)}</div>
        </div>
      </div>
      {(currentBooking && currentBooking.status === bookingStatus.completed) ||
      (!currentBooking &&
        reservation &&
        reservation.status === bookingStatus.completed) ? (
        <div className="btns">
          <button
            type="button"
            className="btn btn-primary btn-lg btn-block"
            onClick={() => {
              onSave('completed', true);
            }}
          >
            {t('clinicBoard_page.save')}
          </button>
        </div>
      ) : (currentBooking && currentBooking.status !== 'completed') ||
        (!currentBooking &&
          reservation &&
          reservation.status !== 'completed') ? (
        <div className="btns">
          <button
            type="button"
            className="btn btn-primary btn-lg"
            onClick={() => {
              onSave('completed');
            }}
          >
            {t('clinicBoard_page.end-visit')}
          </button>
          <button
            type="button"
            className="btn btn-secondary btn-lg"
            onClick={() => {
              onSave('hold');
            }}
          >
            {t('clinicBoard_page.hold')}
          </button>
        </div>
      ) : null}
      <div>
        {!currentBooking && reservation && reservation.b
          ? `Date: ${formatDate(reservation.b.date)}`
          : null}
      </div>
    </div>
  );
};

export default BoardPatientInfo;
