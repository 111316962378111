import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { rootState } from '../../../../store/reducers';
import SectionHeaderComponent from '../SectionHeaderComponent/SectionHeaderComponent';
import BloodPressureInput from './BloodPressureInputComponent/BloodPressureInputComponent';
import TemperatureInput from './TemperatureInputComponent/TemperatureInputComponent';
import PulseRateInput from './PulseRateInputComponent/PulseRateInputComponent';
import FastBloodGlucoseInput from './FastBloodGlucoseInputComponent/FastBloodGlucoseInputComponent';
import BmiInput from './BmiInputComponent/BmiInput';
import RespiratoryRateInput from './RespiratoryRateInputComponent/RespiratoryRateInputComponent';
import WeightInput from './WeightInputComponent/WeightInputComponent';
import HeightInput from './HeightInputComponent/HeightInputComponent';

import './AllInputComponent.scss';

type Props = {
  vitalSigns: any[];
  column?: number;
  onChange?: any;
};
const getVitalSigns = (clinic: any) => {
  if (!clinic) return [];
  return (
    Object.keys(clinic.vitalSigns).filter(
      (key: string) => clinic.vitalSigns[key] === true
    ) || []
  );
};
const VitalSignsComponent = (props: Props) => {
  const { vitalSigns, onChange } = props;
  let [title] = useState<string>('Vitals');

  // const [bmi, setBmi] = useState({ height: 0, weight: 0 });

  const currentUnitDetails = useSelector(
    (state: rootState) => state.booking.currentUnitDetails
  );
  const currentPatient = useSelector(
    (state: rootState) => state.booking.currentPatient
  );

  const fields = getVitalSigns(currentUnitDetails);
  if (fields.length <= 0) {
    title = '';
  }

  // useEffect(() => {
  //   const obj: any = { ...vitalSigns };
  //   const height= obj.height?Number(obj.height):0
  //   const weight= obj.weight?Number(obj.weight):0

  //   if (height > 0 && weight > 0 && (bmi.height>0 || bmi.weight>0)) {
  //     const b = weight / (height * height);
  //     obj.bmi = b.toFixed(1);
  //     onChange({ ...obj });
  //   }
  // }, [bmi]);

  // useEffect(() => {
  //   const obj: any = { ...vitalSigns };
  //   if (obj.height && obj.weight) {
  //     const b = obj.weight / (obj.height * obj.height);
  //     obj.bmi = b.toFixed(1);
  //     onChange({ ...obj });
  //   } else {
  //     obj.bmi = '';
  //     onChange({ ...obj });
  //   }
  // }, []);

  const calcBMI = (obj: any) => {
    const h = obj.height && obj.height !== '' ? Number(obj.height) : 0;
    const weight = obj.weight && obj.height !== '' ? Number(obj.weight) : 0;
    const height = h / 100;
    if (height > 0 && weight > 0) {
      const b = weight / (height * height);
      b <= 60 ? (obj.bmi = b.toFixed(1)) : (obj.bmi = '');
    } else {
      obj.bmi = '';
    }
    onChange({ ...obj });
  };

  const onSignChange = (name: string, value: number) => {
    const obj: any = { ...vitalSigns };
    obj[name] = value;
    onChange({ ...obj });
    if (name === 'weight' || name === 'height') {
      calcBMI(obj);
    }
  };

  return (
    <div className="component vital-signs-component">
      <SectionHeaderComponent
        title={title}
        column={1}
        // onDone={(value: string) => {}}
      />
      {console.log(fields)}
      <div className="form-container">
        <div className="container">
          <div className="row">
            {fields.includes('bloodPressure') && (
              <BloodPressureInput
                values={vitalSigns}
                onChange={(name: string, value: number) => {
                  onSignChange(name, value);
                }}
              />
            )}
            {fields.includes('temperature') && (
              <TemperatureInput
                values={vitalSigns}
                onChange={(name: string, value: number) => {
                  onSignChange(name, value);
                }}
              />
            )}
            {fields.includes('pulseRate') && (
              <PulseRateInput
                values={vitalSigns}
                onChange={(name: string, value: number) => {
                  onSignChange(name, value);
                }}
              />
            )}
            {fields.includes('fastBloodGlucose') && (
              <FastBloodGlucoseInput
                values={vitalSigns}
                onChange={(name: string, value: number) => {
                  onSignChange(name, value);
                }}
              />
            )}
            {fields.includes('respiratoryRate') && (
              <RespiratoryRateInput
                values={vitalSigns}
                onChange={(name: string, value: number) => {
                  onSignChange(name, value);
                }}
                DOB={currentPatient?.dob}
              />
            )}
            {fields.includes('weight') && (
              <WeightInput
                values={vitalSigns}
                onChange={(name: string, value: number) => {
                  onSignChange(name, value);
                }}
              />
            )}
            {fields.includes('height') && (
              <HeightInput
                values={vitalSigns}
                onChange={(name: string, value: number) => {
                  onSignChange(name, value);
                }}
              />
            )}
            {fields.includes('bmi') && (
              <BmiInput
                values={vitalSigns}
                onChange={(name: string, value: number) => {
                  onSignChange(name, value);
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VitalSignsComponent;
