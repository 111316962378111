import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import bookingStatus from '../../assets/constants/bookingStatus';
import { UserRoles } from '../../assets/constants/roles';
import ClinicBookingDetails from '../../components/UnitBoard/ClinicBookingDetails/ClinicBookingDetails';
import LabBookingDetails from '../../components/UnitBoard/LabBookingDetails/LabBookingDetails';
import BookingPanel from '../../components/UnitBoard/UnitPanel/UnitPanel';
import { WithSubscription } from '../../hocs';
// import { useTranslation } from 'react-i18next';
import i18n from '../../services/i18n';
import {
  getClinicBookings,
  setBookingDate,
  setCurrentBooking,
  setUnitBookings,
  updateBooking,
} from '../../store/actions/booking.actions';
import { rootState } from '../../store/reducers';
import { formatCAlDate, getDayName } from '../../utils/moment';
import { getStoredData } from '../../utils/storage';
import unitTypes from '../../utils/unitTypes';
import './UnitBoard.scss';

const UnitBoard: React.FC = () => {
  const dispatch = useDispatch();
  const currentUnitDetails = useSelector(
    (state: rootState) => state.booking.currentUnitDetails
  );
  const { currentEmpUnit } = useSelector((state: rootState) => state.booking);

  useEffect(() => {
    const checkLanguage = async (lang: string) => {
      await i18n.changeLanguage(lang);
    };
    if (currentEmpUnit?.role === 'physician') {
      if (i18n.language === 'ar') checkLanguage('en');
    } else {
      const lang = localStorage.getItem('i18nextLng') || 'ar';
      if (i18n.language !== lang) checkLanguage('ar');
    }

    return () => {
      const role = getStoredData('user')?.role;
      // console.log('role', role);
      if ([UserRoles.Physician].includes(role)) {
        // dispatch(setUnitBookings([]));
      }
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (currentUnitDetails) {
      const startOfDay = new Date(
        new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000)
          .toJSON()
          .slice(0, 10)
      ).getTime();
      // moment.tz.setDefault().startOf('day').toDate().getTime();
      const endOfDay = moment().endOf('day').toDate().getTime();
      dispatch(setBookingDate(startOfDay));
      dispatch(getClinicBookings(currentUnitDetails?.pk, startOfDay, endOfDay));
    }
  }, [currentUnitDetails]);

  const currentBooking = useSelector(
    (state: rootState) => state.booking.currentBooking
  );

  const bookingDate = useSelector(
    (state: rootState) => state.booking.bookingDate
  );

  const allBookings = useSelector((state: rootState) => state.booking.bookings);

  const endDate = useSelector((state: rootState) => state.booking.endDate);

  const changeBookingDateHandler = (date: Date) => {
    if (!currentUnitDetails) return;

    const dd = new Date(
      new Date(
        new Date(date).getTime() - new Date().getTimezoneOffset() * 60000
      )
        .toJSON()
        .slice(0, 10)
    ).getTime();
    // moment(date).startOf('day').toDate().getTime();
    const newEndDate = moment(date).endOf('day').toDate().getTime();

    if (bookingDate === dd) return;
    dispatch(setCurrentBooking(null));
    dispatch(setBookingDate(dd));
    const filteredBookings = allBookings.filter(
      (ele) => ele.date >= dd && ele.date <= newEndDate
    );
    if ((dd > endDate && filteredBookings.length <= 0) || dd < endDate) {
      dispatch(getClinicBookings(currentUnitDetails.pk, dd, newEndDate));
    }
  };

  const getDataForStatistics = () => {
    if (!currentBooking || !currentUnitDetails) return null;

    const today = new Date(
      new Date(bookingDate).toJSON().slice(0, 10)
    ).getTime();

    const date = formatCAlDate(new Date(bookingDate));

    // get day name
    const dayName = getDayName(new Date(bookingDate)).toLocaleLowerCase();

    // get starting and end periods[slots] for unit in this day and if not found get first in setting
    const defaultSlots =
      (currentUnitDetails.workingDays &&
        currentUnitDetails.workingDays[dayName]) ||
      (currentUnitDetails.workingDays &&
        currentUnitDetails.workingDays[
          Object.keys(currentUnitDetails.workingDays)[0]
        ]) ||
      {};
    const bookingHour = new Date(currentBooking.date).getHours();
    let shiftName = 'shift0';

    for (const key of Object.keys(defaultSlots)) {
      const [hhFrom] = defaultSlots[key].from.split(':');
      const [hhTo] = defaultSlots[key].to.split(':');
      if (bookingHour >= +hhFrom && bookingHour <= +hhTo) {
        shiftName = key;
        break;
      }
    }
    return { today, date, shiftName };
  };

  const updateBookingStatusHandler = (status: string) => {
    if (!currentBooking || currentBooking.status === bookingStatus.canceled)
      return;

    const statisticsData = getDataForStatistics();
    // console.log('statisticsData', statisticsData);
    if (statisticsData) {
      dispatch(
        updateBooking(currentBooking.pk, currentBooking.sk, 'status', {
          status,
          today: statisticsData.today,
          date: statisticsData.date,
          shiftName: statisticsData.shiftName,
          unitType: currentUnitDetails?.type,
        })
      );
    }
  };

  const updateBookingCostHandler = (paid: number) => {
    // console.log('cost', paid);
    if (!currentBooking || !currentUnitDetails || !paid) return;
    const statisticsData = getDataForStatistics();
    // console.log('statisticsData', statisticsData);
    if (!statisticsData) return;
    dispatch(
      updateBooking(currentBooking.pk, currentBooking.sk, 'cost', {
        paid,
        today: statisticsData.today,
        date: statisticsData.date,
        shiftName: statisticsData.shiftName,
        unitType: currentUnitDetails.type,
      })
    );
  };

  const payBookingHandler = (paymentStatus: string) => {
    if (
      !currentBooking ||
      currentBooking.status === bookingStatus.canceled ||
      !currentUnitDetails
    )
      return;

    const statisticsData = getDataForStatistics();
    // console.log('statisticsData', statisticsData);
    if (statisticsData) {
      dispatch(
        updateBooking(currentBooking.pk, currentBooking.sk, 'paymentStatus', {
          paymentStatus,
          today: statisticsData.today,
          date: statisticsData.date,
          type: currentBooking.type,
          shiftName: statisticsData.shiftName,
          unitType: currentUnitDetails?.type,
        })
      );
    }
  };

  return (
    <WithSubscription currentUnitDetails={currentUnitDetails}>
      <div className="booking-container d-flex flex-column flex-sm-row mt-3">
        <BookingPanel
          changeBookingDateHandler={changeBookingDateHandler}
          startDate={new Date(bookingDate)}
        />
        {!currentUnitDetails ? null : currentUnitDetails &&
          currentUnitDetails.type === unitTypes.clinic ? (
          <ClinicBookingDetails
            currentBooking={currentBooking}
            updateBookingStatusHandler={updateBookingStatusHandler}
            payBookingHandler={payBookingHandler}
            clinic={currentUnitDetails}
          />
        ) : (
          <LabBookingDetails
            currentBooking={currentBooking}
            updateBookingStatusHandler={updateBookingStatusHandler}
            updateBookingCostHandler={updateBookingCostHandler}
          />
        )}
      </div>
    </WithSubscription>
  );
};

export default UnitBoard;
