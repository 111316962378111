import React, { useState, useEffect } from 'react';
import './ExaminationTypes.scss';
import Toggle from 'react-toggle';
import { useTranslation } from 'react-i18next';
import parseArabic from '../../../../utils/parseArabic';

type Props = {
  shouldToggle: boolean;
  isCustom: boolean;
  setInputHandler: (
    name: string,
    color: string,
    keyName: string,
    value: string | null,
    isFirstToggle?: boolean
  ) => void;
  type: string;
  color: string;
  name: string;
  price?: string;
  value?: string;
  time?: string;
  period?: string;
  isFollowUp: boolean;
};

const ExaminationType: React.FC<Props> = ({
  type,
  shouldToggle,
  isCustom,
  color,
  name,
  price,
  time,
  period,
  value,
  setInputHandler,
  isFollowUp,
}) => {
  const { t } = useTranslation();
  const [toggle, setSetToggle] = useState(shouldToggle);

  const [priceValue, setPrice] = useState(
    typeof price !== undefined || typeof price !== 'undefined' ? price : ''
  );
  const [timeValue, setTime] = useState(time ? time : '');
  const [periodValue, setPeriod] = useState(period ? period : '');

  const handleTofuChange = (e: any) => {
    setSetToggle(e.target.checked);
    if (!e.target.checked) {
      setInputHandler(name, color, e.target.name, null);
      // setPrice('');
      // setTime('');
      // setPeriod('');
    } else {
      setInputHandler(name, color, e.target.name, null, e.target.checked);
    }
  };

  useEffect(() => {
    return () => {
      setPrice('');
      setTime('');
      setPeriod('');
    };
  }, []);

  const setInputHandlerr = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const attrName = e.target.name;
    const attrValue: any = e.target.value;
    if (attrName === 'name') {
      setInputHandler(name, color, e.target.name, attrValue);
    }
    let parsedNumber = parseArabic(attrValue);
    const isValid = /^[0-9]*$/.test(parsedNumber);
    if (isValid) {
      parsedNumber = +parsedNumber;
      if (attrName === 'price') {
        setPrice(parsedNumber);
      } else if (attrName === 'time') {
        setTime(parsedNumber);
      } else if (attrName === 'period') {
        setPeriod(parsedNumber);
      }
      setInputHandler(name, color, e.target.name, parsedNumber);
    }
  };

  return (
    <div
      className="examinationTypes-container_examine p-4"
      style={{
        backgroundColor: color,
        alignSelf: toggle ? 'inherit' : 'baseline',
      }}
    >
      <div className="examinationTypes-container_examine-toggle  d-flex justify-content-between ">
        <div>
          {!isCustom ? (
            <p>{t('examinationAndPrices_page.' + type)}</p>
          ) : (
            <input
              className="custom-name"
              placeholder={t('examinationAndPrices_page.custom')}
              defaultValue={value}
              type="text"
              name="name"
              onChange={setInputHandlerr}
            />
          )}
        </div>
        <div>
          <Toggle
            className="custom-classname"
            defaultChecked={toggle}
            icons={false}
            onChange={handleTofuChange}
          />
        </div>
      </div>
      {toggle ? (
        <div className="examinationTypes-container_examine-inputs mt-3 d-flex justify-content-between">
          <div className="price">
            <p>{t('examinationAndPrices_page.price')}</p>
            <div className="d-flex">
              <input
                type="number"
                name="price"
                value={priceValue == '0' ? '' : priceValue}
                onChange={setInputHandlerr}
              />
              <span className="align-self-end ml-2 mr-2">
                {t('examinationAndPrices_page.egp')}
              </span>
            </div>
          </div>
          {isFollowUp && (
            <div className="period">
              <p>{t('examinationAndPrices_page.period')}</p>
              <div className="d-flex">
                <input
                  type="number"
                  name="period"
                  value={periodValue == '0' ? '' : periodValue}
                  onChange={setInputHandlerr}
                />
                <span className="align-self-end ml-2 mr-2">
                  {t('examinationAndPrices_page.days')}
                </span>
              </div>
            </div>
          )}

          <div className="time">
            <p>{t('examinationAndPrices_page.time')}</p>
            <div className="d-flex">
              <input
                type="number"
                name="time"
                value={timeValue == '0' ? '' : timeValue}
                onChange={setInputHandlerr}
              />
              <span className="align-self-end ml-2 mr-2">
                {t('examinationAndPrices_page.minutes')}
              </span>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ExaminationType;
