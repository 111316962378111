import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import apiService from '../../../../../services/api';
import { rootState } from '../../../../../store/reducers';

type Props = {
  file: any;
  index: number;
  sendUrl: (url: any, index: number) => void;
};

const ImageUploader: React.FC<Props> = ({ file, index, sendUrl }) => {
  const [percentCompleted, setPercentCompleted] = useState(0);
  const [cancelToken, setCancelToken] = useState<CancelTokenSource | null>(
    null
  );
  const isAttachmentsReset = useSelector(
    (state: rootState) => state.board.isAttachmentsReset
  );

  useEffect(() => {
    if (file && file.success) {
      setPercentCompleted(100);
      return;
    }
    if (!file || !file.file) return;
    const uploadFile = file.file;
    upload(uploadFile);
    // eslint-disable-next-line
  }, [file]);

  useEffect(() => {
    // console.log('isAttachmentsReset', isAttachmentsReset);
    if (isAttachmentsReset && cancelToken) {
      cancelToken.cancel('cancelled by user');
    }
    // eslint-disable-next-line
  }, [isAttachmentsReset]);

  const upload = async (value: File) => {
    const url = await uploadWithProgress(value);
    // console.log('url', url);
    if (!url) return;
    sendUrl(url, index);
  };

  const uploadWithProgress = async (uploadedfile: File) => {
    try {
      const token = axios.CancelToken.source();
      setCancelToken(token);
      if (!uploadedfile) return '';
      const uploadUrl = await apiService.getUploadAccess(uploadedfile.type);
      // console.log('uploadUrl', uploadUrl);
      if (!uploadUrl) return '';

      const config: AxiosRequestConfig = {
        cancelToken: token.token,
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          // console.log(loaded, total);
          const uploadPercent = Math.round((loaded * 100) / total);
          // console.log('uploadPercent', uploadPercent);
          if (uploadPercent < 100) setPercentCompleted(uploadPercent);
        },
        headers: {
          'Content-Type': uploadedfile.type,
        },
      };

      await axios.put(uploadUrl, uploadedfile, config);
      setPercentCompleted(100);
      const imgUrl: string = uploadUrl.split('?')[0];
      return imgUrl;
    } catch (error) {
      // console.error('error', error);
      console.error('error[uploadWithProgress]', error.response);
      return '';
    }
  };

  return (
    <div className="upload-items">
      <div className="upload-item">
        <div className="upload-item-header">
          <p>{file?.file?.name}</p>
        </div>
        <div className="d-flex align-items-center">
          <p className="mx-1">{`${percentCompleted}%`}</p>
          <div className="progress" style={{ height: '2px' }}>
            <div
              className="progress-bar"
              role="progressbar"
              style={{
                width: `${percentCompleted}%`,
              }}
              aria-valuenow={50}
              aria-valuemin={file.success ? 100 : 0}
              aria-valuemax={100}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageUploader;
