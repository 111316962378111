import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import parseArabic from '../../../utils/parseArabic';
import { mobileRegex } from '../../../assets/constants/regexValues';
import mobileIcon from '../../../assets/img/mobile.svg';
import closeIcon from '../../../assets/img/close.svg';
import roleIcon from '../../../assets/img/role.svg';
import roles, { UserRoles } from '../../../assets/constants/roles';
import arrowIcon from '../../../assets/img/arrow-down.svg';
import unitTypes from '../../../utils/unitTypes';

type Props = {
  removeAddedEmployeeHandler: (
    mobile: string,
    role: string,
    index: number
  ) => void;
  addEmpHandler: (index: number, keyName: string, value: string | null) => void;
  index: number;
  unitType: string;
};

const AddNewEmployess: React.FC<Props> = ({
  index,
  removeAddedEmployeeHandler,
  addEmpHandler,
  unitType,
}) => {
  const { t, i18n } = useTranslation();
  const [mobileError, setMobileError] = useState('');
  const [mobileValue, setMobileValue] = useState('');
  const [roleValue, setRoleValue] = useState(UserRoles.Assistant);
  const [employeeRoles, setEmployeeRoles] = useState<any>({});

  const setInputHandler = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const name = e.target.name;
    const value = e.target.value;
    const mobilNu = parseArabic(value);
    if (name === 'role') {
      setRoleValue(value);
      addEmpHandler(index, 'role', value);
    } else {
      setMobileValue(mobilNu);
      if (mobileRegex.test(mobilNu)) {
        setMobileError('');
        addEmpHandler(index, 'mobile', value);
        // addEmpHandler(index, 'role', roleValue);
      } else {
        setMobileError(t('booking_page.valid-mobile'));
        addEmpHandler(index, 'mobile', value);
        // addEmpHandler(index, 'role', roleValue);
      }
    }
  };

  useEffect(() => {
    let values = {};
    if (unitType === unitTypes.clinic) {
      const { assistant, receptionist, nurse } = roles;
      values = { assistant, receptionist, nurse };
    } else if (unitType === unitTypes.lab) {
      const { assistant, receptionist, nurse, labTech } = roles;
      values = { assistant, receptionist, nurse, labTech };
    } else if (unitType === unitTypes.image) {
      const { assistant, receptionist, nurse, radioTech } = roles;
      values = { assistant, receptionist, nurse, radioTech };
    }
    setEmployeeRoles(values);
  }, [unitType]);

  return (
    <div className="employees d-flex p-3 justify-content-between">
      <div>
        <div className="lock-icon mobile-icon d-flex align-items-center">
          <img src={mobileIcon} alt="mobile icon" />
          <h6>{t('setting_page.mobile')}</h6>
        </div>
        <div className="passwordInput mt-3">
          <input
            name="mobile"
            type="number"
            value={mobileValue}
            autoComplete="off"
            placeholder={t('setting_page.mobile')}
            onChange={setInputHandler}
          />
          <div className="error">
            {mobileError ? <span className="error">{mobileError}</span> : null}
          </div>
        </div>
      </div>
      <div>
        <div className="lock-icon d-flex align-items-center">
          <img src={roleIcon} alt="lockIcon"></img>
          <h6>{t('employess_page.emp-role')}</h6>
          <div className="close-iconn">
            <img
              src={closeIcon}
              alt="close icon"
              onClick={() => {
                removeAddedEmployeeHandler(mobileValue, roleValue, index);
              }}
            />
          </div>
        </div>
        <div className="passwordInput mt-3">
          <select name="role" value={roleValue} onChange={setInputHandler}>
            {Object.keys(employeeRoles).map((key) => (
              <option key={key} value={key}>
                {employeeRoles[key][i18n.language]}
              </option>
            ))}
          </select>
          <img className="arrow" src={arrowIcon} alt="arrowIcon" />
        </div>
      </div>
    </div>
  );
};

export default AddNewEmployess;
