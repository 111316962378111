import React from 'react';
import './UnitPanel.scss';
import Toggle from 'react-toggle';

import { useTranslation } from 'react-i18next';
import PanelBookings from './UnitPanelBookings/UnitPanelBookings';
import CustomDatePicker from '../../UI/Form/CustomDatePicker/CustomDatePicker';
import Button from '../../UI/Form/Button/Button';
import { shouldSearchInputFocus } from '../../../store/actions';
import { useDispatch, useSelector } from 'react-redux';

import { rootState } from '../../../store/reducers';
import showIcon from '../../../assets/img/show.svg';
import hideIcon from '../../../assets/img/hide.svg';
import {
  setShowAll,
  setSomeSlotsShown,
  setAllBooking,
} from '../../../store/actions';

type Props = {
  changeBookingDateHandler: (date: Date) => void;
  startDate: Date;
};

const UnitPanel: React.FC<Props> = ({
  changeBookingDateHandler,
  startDate,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // const isAllSlotsShown = true;
  // const [isAllBookings, setIsAllBookings] = useState(false);
  const showAll = useSelector((state: rootState) => state.booking.showAll);
  const allbooking = useSelector(
    (state: rootState) => state.booking.allbooking
  );
  const showHideBookingsHandler = () => {
    dispatch(setShowAll(!showAll));
    dispatch(setSomeSlotsShown(false));
  };

  const handleToggleChange = (e: any) => {
    dispatch(setAllBooking(e.target.checked));
    // dispatch(setShowAll(true));
    // dispatch(setSomeSlotsShown(false));
  };

  return (
    <div className="panel-container d-flex flex-column align-items-start">
      <div className="panel-action d-flex align-items-center">
        <h6>{t('booking_page.bookings')}</h6>
        <CustomDatePicker
          minDate={new Date()}
          startDate={startDate}
          changeBookingDateHandler={changeBookingDateHandler}
        />
        <Button
          type="button"
          name={t('booking_page.add-booking')}
          saveData={() => {
            dispatch(shouldSearchInputFocus(true));
          }}
        />
      </div>
      <div className="booking-action w-100">
        <button onClick={showHideBookingsHandler}>
          <img src={showAll ? hideIcon : showIcon} alt="showIcon" />
          <span className="mx-2">
            {t(
              showAll
                ? 'booking_page.hide-booking'
                : 'booking_page.show-booking'
            )}
          </span>
        </button>
        <span className="toggle-span">
          <Toggle
            className="custom-classname"
            defaultChecked={allbooking}
            icons={false}
            onChange={handleToggleChange}
          />
          <span className="mx-2">{t('booking_page.all-bookings')}</span>
        </span>
      </div>
      <div className="panel-bookings w-100 mt-4">
        <PanelBookings isAllSlotsShown={showAll} isAllBookings={allbooking} />
      </div>
    </div>
  );
};

export default UnitPanel;
