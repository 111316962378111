import React, { useEffect, useState } from 'react';
import './OnlinePrescription.scss';
import { useTranslation } from 'react-i18next';
import mobileIcon from '../../../assets/img/mobile.svg';
import locationIcon from '../../../assets/img/location.svg';
import locationIcon2 from '../../../assets/img/location-3.svg';
import personalIcon from '../../../assets/img/personal.svg';
import phoneIcon from '../../../assets/img/phone.svg';
import parseArabic from '../../../utils/parseArabic';
import Button from '../../UI/Form/Button/Button';
import backArrow from '../../../assets/img/backArrow.svg';
import EventTracker from '../../../utils/analytics';
import { specialCharsWithNumers } from '../../../assets/constants/regexValues';
import MapModal from '../UnitDetails/MapModal/MapModal';
import questionIcon from '../../../assets/img/question.svg';
import question2Icon from '../../../assets/img/question2.svg';
type Props = {
  nextStepHandler: (payload: { type: string; data: any }) => void;
  goBackHandler: (payload: { type: string; data: any }) => void;
  currentSegment: string;
  initialState: any;
};
function OnlinePrescription({
  currentSegment,
  initialState,
  nextStepHandler,
  goBackHandler,
}: Props) {
  const { t } = useTranslation();
  const [drTitle, setDrTitle] = useState('');
  const [drSubTitle, setDrSubTitle] = useState('');
  const [phone, setPhone] = useState('');
  const [mobile, setMobile] = useState('');
  const [location, setLocation] = useState<{
    lat: number;
    lng: number;
  } | null>(null);
  const [address, setAddress] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [showNotice, setShowNotice] = useState(false);

  useEffect(() => {
    if (initialState.phone) setPhone(initialState.phone || '');
    if (initialState.mobile) setMobile(initialState.mobile || '');
    if (initialState.drTitle) setDrTitle(initialState.drTitle || '');
    if (initialState.drSubTitle) setDrSubTitle(initialState.drSubTitle || '');
    if (
      initialState.location &&
      initialState.location.lng &&
      initialState.location.lat
    )
      setLocation({
        lng: initialState.location.lng,
        lat: initialState.location.lat,
      });
    if (initialState.location && initialState.location.address)
      setAddress(initialState.location.address);
    //  dispatch(setCurrentEmpUnit(unit));
  }, []);
  const saveOnlinePrescriptionHandler = () => {
    // console.log('saveVitalSignshandler', initialState);
    // if (Object.keys(dataChanged).length <= 0) {
    //   errorToast(t('employess_page.choose_one'));
    //   return;
    // }
    const payload: {
      drTitle: string;
      drSubTitle: string;
      phone: any;
      mobile: any;
      location: any;
    } = {
      drTitle,
      drSubTitle,
      phone,
      mobile,
      location: {},
    };
    if (location && location.lat && location.lng) {
      payload.location = { ...location };
    }
    payload.location = { address, ...payload.location };
    EventTracker.sendEvent('SET_ONLINE_PRESCRIPTION', {});
    nextStepHandler({ type: currentSegment, data: payload });
  };

  const goBack = () => {
    // if (Object.keys(initialState).length <= 0) {
    //   errorToast(t('employess_page.choose_one'));
    //   return;
    // }
    const payload: {
      drTitle: string;
      drSubTitle: string;
      phone: any;
      mobile: any;
      location: any;
    } = {
      drTitle,
      drSubTitle,
      phone,
      mobile,
      location: {},
    };
    if (location && location.lat && location.lng) {
      payload.location = { ...location };
    }
    payload.location = { address, ...payload.location };
    goBackHandler({ type: currentSegment, data: payload });
  };

  const showHideNotice = () => {
    setShowNotice(!showNotice);
  };

  const changeClinicDetails = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const attrName = e.target.name;
    const attrValue = e.target.value;
    // console.log(attrName, attrValue);
    if (attrName === 'unitPhone') {
      const num = parseArabic(attrValue).replace(/[^0-9]/g, '');
      setPhone(num);
    } else if (attrName === 'unitMobile') {
      const num = parseArabic(attrValue).replace(/[^0-9]/g, '');
      setMobile(num);
    } else if (attrName === 'drTitle') {
      const val = parseArabic(attrValue).replace(specialCharsWithNumers, '');
      setDrTitle(val);
    } else if (attrName === 'drSubTitle') {
      const val = parseArabic(attrValue).replace(specialCharsWithNumers, '');
      setDrSubTitle(val);
    }
  };

  const checkLocationHandler = () => {
    // check unit location details
    if (location && location.lat && location.lng) {
      openMapModalHandler();
      // request browser location
    } else if (window.navigator.geolocation) {
      // Geolocation available
      window.navigator.geolocation.getCurrentPosition(
        (position: Position) => {
          const { latitude, longitude } = position.coords;
          // console.log('position', position);
          setLocationAndOpenModal(latitude, longitude);
        },
        (err) => {
          // console.error('err', err);
          // errorToast(err.message);
          //   setLocationFromGov();
        }
      );
    }
  };

  const openCloseModalHandler = () => {
    setOpenModal(!openModal);
  };

  const openMapModalHandler = () => {
    openCloseModalHandler();
  };
  const setLocationAndOpenModal = (lat: number, lng: number) => {
    setLocation({ lat, lng });
    openMapModalHandler();
  };

  const changeAddressHandler = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    // console.log('e.target.value', e.target.value);
    setAddress(e.target.value);
  };

  return (
    <div className="onlinePrescription-container d-flex flex-column">
      {openModal && (
        <MapModal
          isOpen={openModal}
          onModalClose={openCloseModalHandler}
          confirmHandler={({ lat, lng, address: newAddress }) => {
            console.log('[unit details component]', lat, lng, newAddress);
            if (lat && lng) setLocation({ lat, lng });
            if (newAddress) setAddress(newAddress);
            if (!newAddress) setAddress('');
            openCloseModalHandler();
          }}
          location={location}
        />
      )}
      <div className="onlinePrescription-container__main">
        <div className="pres-online">
          <div className="notice d-flex align-items-center ">
            <h5>{t('addClinic_page.onlineDetails')} </h5>

            <img
              src={showNotice ? questionIcon : question2Icon}
              alt=""
              onClick={showHideNotice}
              onBlur={() => {
                setShowNotice(false);
              }}
              tabIndex={1}
            />
            {showNotice && <p>{t('addClinic_page.notice')}</p>}
          </div>
          <div className="pres-online-data mt-3 d-flex justify-content-between">
            <div className="unit-data d-flex flex-column p-3">
              {/* first part */}
              {/* <div> */}
              <div className="name-title d-flex flex-column">
                {/* dr title */}
                <div>
                  <div className="lock-icon d-flex align-items-center">
                    <img src={personalIcon} alt="type name"></img>
                    <h6>{t('addClinic_page.dr-title')}</h6>
                  </div>
                  <div className="passwordInput mt-3">
                    <input
                      className="shared"
                      name="drTitle"
                      type="text"
                      value={drTitle}
                      placeholder={t('addClinic_page.dr-title-holder')}
                      onChange={changeClinicDetails}
                      maxLength={30}
                    />
                  </div>
                </div>
                {/* dr sub title */}
                <div className="mt-3">
                  <div className="lock-icon d-flex align-items-center">
                    <img src={personalIcon} alt="type name"></img>
                    <h6>{t('addClinic_page.dr-subTitle')}</h6>
                  </div>
                  <div className="passwordInput mt-3">
                    <textarea
                      className="shared right"
                      name="drSubTitle"
                      value={drSubTitle}
                      rows={3}
                      placeholder={t('addClinic_page.dr-subtitle-holder')}
                      onChange={changeClinicDetails}
                      maxLength={130}
                    />
                  </div>
                </div>
              </div>
              <div className="tel d-flex flex-column mt-4">
                {/* unit phone */}
                <div>
                  <div className="lock-icon d-flex align-items-center">
                    <img src={phoneIcon} alt="type name"></img>
                    <h6>{t('addClinic_page.tel')}</h6>
                  </div>
                  <div className="passwordInput mt-3">
                    <input
                      name="unitPhone"
                      type="number"
                      value={phone}
                      placeholder={t('addClinic_page.mobile-holder')}
                      onChange={changeClinicDetails}
                      maxLength={10}
                    />
                  </div>
                </div>
                {/* unit mobile */}
                <div className="mt-3">
                  <div className="lock-icon mobile-icon d-flex align-items-center">
                    <img src={mobileIcon} alt="type name"></img>
                    <h6>{t('addClinic_page.mobile')}</h6>
                  </div>
                  <div className="passwordInput mt-3">
                    <input
                      name="unitMobile"
                      type="number"
                      value={mobile}
                      placeholder={t('addClinic_page.mobile-holder')}
                      onChange={changeClinicDetails}
                      maxLength={11}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* </div> */}
            {/* second part */}
            <div className="map-container p-3">
              {location && location.lat && location.lng ? (
                <>
                  <div
                    className="flex-fill"
                    style={{
                      overflow: 'hidden',
                      height: '17.7rem',
                    }}
                  >
                    <iframe
                      title="map"
                      width="100%"
                      height="400"
                      frameBorder="0"
                      style={{
                        border: 0,
                        borderRadius: '1rem',
                        marginTop: '-100px',
                        marginBottom: '-145px',
                        pointerEvents: 'none',
                      }}
                      src={`https://maps.google.com/maps?q= + ${location.lat} + , + ${location.lng} + &t=&z=15&ie=UTF8&iwloc=&output=embed`}
                    ></iframe>
                  </div>
                  <div className="d-flex align-items-center mt-3">
                    <div className=" btn-container">
                      <button
                        className="d-flex justify-content-center align-items-center"
                        onClick={checkLocationHandler}
                      >
                        <img src={locationIcon2} alt="" />
                        <span>
                          {location && location.lat && location.lng
                            ? t('addClinic_page.edit-location')
                            : t('addClinic_page.add-location')}
                        </span>
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <div
                  className="no-map d-flex flex-column justify-content-center align-items-center"
                  onClick={checkLocationHandler}
                >
                  <img src={locationIcon} alt="" />
                  <p>{t('addClinic_page.pick')}</p>
                </div>
              )}

              {/* unit address */}
              <div className="mt-3">
                <div className="lock-icon d-flex align-items-center">
                  <img src={locationIcon} alt="type name"></img>
                  <h6>{t('addClinic_page.address')}</h6>
                </div>
                <div className="passwordInput mt-3">
                  <textarea
                    name="address"
                    className="shared address"
                    value={address}
                    rows={2}
                    placeholder={t('addClinic_page.address-holder')}
                    onChange={changeAddressHandler}
                    maxLength={97}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="offers"></div>
      </div>
      <Button
        saveData={() => {
          saveOnlinePrescriptionHandler();
        }}
        name={t('working_days_page.next')}
      />
      <div
        className="back go-back align-self-end"
        onClick={() => {
          goBack();
        }}
      >
        <span>{t('otp_page.back')}</span>
        <img src={backArrow} alt="back" />
      </div>
    </div>
  );
}

export default OnlinePrescription;
