import {
  changePassword,
  checkToken,
  checkUser,
  completeProfile,
  login,
  register,
  requestToken,
  getUserProfile,
  updateUserProfile,
  validatePromoCode,
  updateUserPassword,
} from './clinicians';
import { addEmployee, getEmployees, revokeEmployeeAccess } from './employee';
import { upload, getUploadAccess } from './media';
import {
  addUnit,
  getUnitDetails,
  getClinicianUnits,
  getUnitTestsTypes,
  getUnitEmployees,
  updateUnit,
  searchPlaces,
  subscribeUnit,
  redeemPoints,
  getSubscriptionPlans,
} from './unit';
import {
  addBooking,
  updateBooking,
  getClinicBookings,
  getPatientPreviousBookings,
  getPatientUpCommingBooking,
  // searchReservationBy,
  // updateReservation,
  getLabBookings,
  markComplete,
  getPatientUnitBookings,
  updateBookingTest,
  deleteBookingTest,
} from './booking';

import { getUnitMonthStatistics } from './statistics';

import {
  updatePatient,
  addPatient,
  findPatientBy,
  findPatientWithUnitBookingBy,
  getPatientOrders,
  getPatientLabEncounters,
  getPatientRadEncounters,
  addPatientOrders,
  addPatientLabEncounters,
  addPatientRadEncounters,
  getPatientData,
} from './patient';

export default {
  // clinician
  changePassword,
  checkToken,
  checkUser,
  completeProfile,
  login,
  register,
  requestToken,
  getUserProfile,
  updateUserProfile,
  validatePromoCode,
  updateUserPassword,
  // employee
  addEmployee,
  getEmployees,
  revokeEmployeeAccess,
  // unit
  addUnit,
  getUnitDetails,
  getClinicianUnits,
  getUnitTestsTypes,
  getUnitEmployees,
  updateUnit,
  searchPlaces,
  subscribeUnit,
  redeemPoints,
  getSubscriptionPlans,
  // booking
  addBooking,
  updateBooking,
  getClinicBookings,
  getPatientPreviousBookings,
  getPatientUpCommingBooking,
  getPatientUnitBookings,
  updateBookingTest,
  // searchReservationBy,
  // updateReservation,
  updatePatient,
  getLabBookings,
  markComplete,
  deleteBookingTest,
  // patient
  addPatient,
  findPatientBy,
  findPatientWithUnitBookingBy,
  getPatientOrders,
  getPatientLabEncounters,
  getPatientRadEncounters,
  addPatientOrders,
  addPatientLabEncounters,
  addPatientRadEncounters,
  getPatientData,
  // media
  upload,
  getUploadAccess,
  // statistics
  getUnitMonthStatistics,
};
