import React, { useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { UserRoles } from '../../assets/constants/roles';
import routes from '../../assets/constants/routes';
import clinicIconActive from '../../assets/img/clinicIcon-active.svg';
import clinicIcon from '../../assets/img/clinicIcon.svg';
import employeesIcon from '../../assets/img/employees.svg';
import employeesActiveIcon from '../../assets/img/employeesActive.svg';
import specialityIcon from '../../assets/img/speciality.svg';
import specialityActiveIcon from '../../assets/img/specialityActive.svg';
import workingDaysIcon from '../../assets/img/workingDays.svg';
import workingDaysActiveIcon from '../../assets/img/workingDaysActive.svg';
// import VitalSigns from '../../components/AddUnit/VitalSigns/VitalSigns';
import Employees from '../../components/AddUnit/Employees/Employees';
import ExaminationTypes from '../../components/AddUnit/ExaminationTypes/ExaminationTypes';
import OnlinePrescription from '../../components/AddUnit/OnlinePrescription/OnlinePrescription';
import UnitName from '../../components/AddUnit/UnitName/UnitName';
import WorkingDays from '../../components/AddUnit/WorkingDays/WorkingDays';
import apiService from '../../services/api';
import {
  getClinicUnitsWithUpdateCurrentUnit,
  resetCurrentUnit,
  setCurrentBooking,
} from '../../store/actions/booking.actions';
import EventTracker from '../../utils/analytics';
import { errorToast } from '../../utils/toast';
import unitTypes from '../../utils/unitTypes';
import './AddClinic.scss';

type Action = {
  type: string;
  payload: any;
};

const segmentsData = {
  workingDays: 'workingDays',
  examinationTypes: 'examinationTypes',
  vitalSigns: 'vitalSigns',
  employees: 'employees',
  clinicName: 'clinicName',
  onlinePrescription: 'onlinePrescription',
};

const initialState = {
  [segmentsData.workingDays]: {},
  [segmentsData.examinationTypes]: {},
  [segmentsData.onlinePrescription]: {},
  [segmentsData.vitalSigns]: {
    temperature: true,
    bloodPressure: true,
    weight: true,
    height: true,
  },
  [segmentsData.employees]: {},
};

const reducer = (state: any, action: Action) => {
  switch (action.type) {
    case segmentsData.clinicName:
      return { ...state, ...action.payload };
    case segmentsData.workingDays:
      return { ...state, workingDays: action.payload };
    case segmentsData.examinationTypes:
      return { ...state, examinationTypes: action.payload };
    case segmentsData.onlinePrescription:
      return { ...state, onlinePrescription: action.payload };
    // case segmentsData.vitalSigns:
    //   return { ...state, vitalSigns: action.payload };
    case segmentsData.employees:
      return { ...state, employees: action.payload };
    default:
      return state;
  }
};

const AddClinic: React.FC = () => {
  const { t } = useTranslation();
  const reduxDiapatch = useDispatch();
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [selectedSegment, setSelectedSegment] = useState(
    segmentsData.workingDays
  );

  const changeSegmentHandler = (value: string) => {
    setSelectedSegment(value);
  };

  const [isClinicNamePage, setIsClinicNamePage] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const nextStepHandler = async (payload: { type: string; data: any }) => {
    if (payload.type === segmentsData.clinicName) {
      dispatch({ type: segmentsData.clinicName, payload: payload.data });
      setIsClinicNamePage(false);
    } else if (payload.type === segmentsData.workingDays) {
      dispatch({ type: segmentsData.workingDays, payload: payload.data });
      changeSegmentHandler(segmentsData.examinationTypes);
    } else if (payload.type === segmentsData.examinationTypes) {
      dispatch({ type: segmentsData.examinationTypes, payload: payload.data });
      changeSegmentHandler(segmentsData.onlinePrescription);
    } else if (payload.type === segmentsData.onlinePrescription) {
      dispatch({
        type: segmentsData.onlinePrescription,
        payload: payload.data,
      });
      changeSegmentHandler(segmentsData.employees);
    }
    //  else if (payload.type === segmentsData.vitalSigns) {
    //   dispatch({ type: segmentsData.vitalSigns, payload: payload.data });
    //   changeSegmentHandler(segmentsData.employees);
    // }
    else {
      dispatch({ type: segmentsData.employees, payload: payload.data });
    }
  };

  const goBackHandler = async (payload: { type: string; data: any }) => {
    if (payload.type === segmentsData.employees) {
      dispatch({ type: segmentsData.employees, payload: payload.data });
      changeSegmentHandler(segmentsData.onlinePrescription);
    } else if (payload.type === segmentsData.onlinePrescription) {
      dispatch({
        type: segmentsData.onlinePrescription,
        payload: payload.data,
      });
      changeSegmentHandler(segmentsData.examinationTypes);
    }
    //  else if (payload.type === segmentsData.vitalSigns) {
    //   dispatch({ type: segmentsData.vitalSigns, payload: payload.data });
    //   changeSegmentHandler(segmentsData.examinationTypes);
    // }
    else if (payload.type === segmentsData.examinationTypes) {
      dispatch({ type: segmentsData.examinationTypes, payload: payload.data });
      changeSegmentHandler(segmentsData.workingDays);
    }
  };

  const checkEmpsHandler = async (emps: any[]) => {
    try {
      let isValidEmp = true;
      for (const emp of emps) {
        const data = await apiService.checkUser({ mobile: emp.mobile });
        const empData = data.data;
        if (empData.exist && empData.user.role === UserRoles.Physician) {
          errorToast(
            t('addClinic_page.emp-errMsg', {
              mobile: emp.mobile,
              unitType: unitTypes.clinic,
            })
          );
          isValidEmp = false;
          setIsSubmitting(false);
          return isValidEmp;
        }
      }
      return isValidEmp;
    } catch (error) {
      console.error('error[checkEmpsHandler]', error);
      return false;
    }
  };

  const saveClinicData = async () => {
    try {
      setIsSubmitting(true);
      const sentState = { ...state };
      delete sentState.onlinePrescription;
      const payload = {
        ...sentState,
        ...state.onlinePrescription,
        type: unitTypes.clinic,
      };

      // console.log('payload', payload);
      const clinicEmp = Object.values(payload.employees);
      const isValidEmps = await checkEmpsHandler(clinicEmp);
      if (isValidEmps) {
        // console.log('saveClinicData', payload);
        const { data } = await apiService.addUnit(payload);
        const { unit } = data;
        if (unit) {
          EventTracker.sendEvent('LEAD', {
            content_category: unit.type,
            content_name: unit.name,
          });
          EventTracker.sendEvent('SUBMIT_APPLICATION', {});
          EventTracker.sendEvent('START_TRIAL', { unit: unit.pk });
          reduxDiapatch(getClinicUnitsWithUpdateCurrentUnit(unit, history));
        }
        setIsSubmitting(false);
        history.push(routes.BOARD);
      }
    } catch (error) {
      setIsSubmitting(false);
      console.error('error[saveClinicData]', error);
    }
  };
  useEffect(() => {
    reduxDiapatch(setCurrentBooking(null));
    reduxDiapatch(resetCurrentUnit());
    // eslint-disable-next-line
  }, []);
  return (
    <div className="addClinic-page d-flex flex-column">
      {isClinicNamePage ? (
        <UnitName
          nextStepHandler={nextStepHandler}
          currentSegment={segmentsData.clinicName}
        />
      ) : (
        <>
          <div className="segment mt-3">
            <ul>
              <li
                className={`base token ${
                  selectedSegment === segmentsData.workingDays ? 'selected' : ''
                }`}
              >
                <img
                  className="d-none d-sm-block"
                  src={
                    selectedSegment === segmentsData.workingDays
                      ? workingDaysActiveIcon
                      : workingDaysIcon
                  }
                  alt="mobile"
                />
                {t('addClinic_page.workingDays')}
              </li>
              <li
                className={`base ${
                  selectedSegment === segmentsData.examinationTypes
                    ? 'selected'
                    : ''
                }`}
              >
                <img
                  className="d-none d-sm-block"
                  src={
                    selectedSegment === segmentsData.examinationTypes
                      ? specialityActiveIcon
                      : specialityIcon
                  }
                  alt="personal"
                />
                {t('addClinic_page.examinationTypes')}
              </li>
              {/**     <li
                className={`base ${
                  selectedSegment === segmentsData.vitalSigns ? 'selected' : ''
                }`}
              >
                <img
                  className="d-none d-sm-block"
                  src={
                    selectedSegment === segmentsData.vitalSigns
                      ? vitalSignsActiveIcon
                      : vitalSignsIcon
                  }
                  alt="check"
                />
                {t('addClinic_page.vitalSigns')}
                </li> **/}
              <li
                className={`base ${
                  selectedSegment === segmentsData.onlinePrescription
                    ? 'selected'
                    : ''
                }`}
              >
                <img
                  className="d-none d-sm-block"
                  src={
                    selectedSegment === segmentsData.onlinePrescription
                      ? clinicIconActive
                      : clinicIcon
                  }
                  alt="check"
                />
                {t('addClinic_page.unitDetails')}
              </li>
              <li
                className={`base ${
                  selectedSegment === segmentsData.employees ? 'selected' : ''
                }`}
              >
                <img
                  className="d-none d-sm-block"
                  src={
                    selectedSegment === segmentsData.employees
                      ? employeesActiveIcon
                      : employeesIcon
                  }
                  alt="check"
                />
                {t('addClinic_page.employees')}
              </li>
            </ul>
          </div>
          <div className="addClinic-page__main d-flex flex-column-reverse flex-sm-row mt-3 p-3">
            {selectedSegment === segmentsData.workingDays ? (
              <WorkingDays
                nextStepHandler={nextStepHandler}
                currentSegment={segmentsData.workingDays}
                initialState={state[segmentsData.workingDays]}
              />
            ) : selectedSegment === segmentsData.examinationTypes ? (
              <ExaminationTypes
                goBackHandler={goBackHandler}
                initialState={state[segmentsData.examinationTypes]}
                nextStepHandler={nextStepHandler}
                currentSegment={segmentsData.examinationTypes}
              />
            ) : selectedSegment === segmentsData.onlinePrescription ? (
              <OnlinePrescription
                nextStepHandler={nextStepHandler}
                currentSegment={segmentsData.onlinePrescription}
                goBackHandler={goBackHandler}
                initialState={state[segmentsData.onlinePrescription]}
              />
            ) : (
              /** selectedSegment === segmentsData.vitalSigns ? (
              <VitalSigns
                nextStepHandler={nextStepHandler}
                currentSegment={segmentsData.vitalSigns}
                goBackHandler={goBackHandler}
                initialState={state[segmentsData.vitalSigns]}
              />
            ) : **/
              <Employees
                isSubmitting={isSubmitting}
                nextStepHandler={nextStepHandler}
                goBackHandler={goBackHandler}
                currentSegment={segmentsData.employees}
                saveClinicData={saveClinicData}
                initialState={state[segmentsData.employees]}
                unitType={unitTypes.clinic}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default AddClinic;
