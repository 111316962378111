import moment from 'moment';
import bookingStatus from '../../assets/constants/bookingStatus';
import { UserRoles } from '../../assets/constants/roles';
import routes from '../../assets/constants/routes';
import apiService from '../../services/api';
import { redeemPoints } from '../../services/api/unit';
import {
  closeSocketEvent,
  eventsNames,
  listenToSocketEvent,
} from '../../services/socket';
import { Booking, ClinicData, Unit } from '../../types';
import { isAuthenticatedPhysician, storeData } from '../../utils/storage';
import {
  ADD_BOOKING,
  CANCEL_BOOKING,
  FOCUS_SEARCH_INPUT,
  PATIENT_PREVIOUS_BOOKINGS,
  REMOVE_SEARCHED_PATIENTS,
  RESET_BOOKING,
  RESET_HISTORY,
  SEARCH_PATIENTS,
  SET_ALLBOOKING,
  SET_BOOKING_DATE,
  SET_BOOKING_END_DATE,
  SET_CLINICIAN_UNITS,
  SET_CLINIC_BOOKINGS,
  SET_COLLAPSED_SLOTS,
  SET_CURRENT_BOOKING,
  SET_CURRENT_PATIENT,
  SET_CURRENT_SLOT,
  SET_CURRENT_UNIT_DETAILS,
  SET_EMP_CURRENT_UNIT,
  SET_LOADING,
  SET_PATIENT_ORDERS,
  SET_SHOW_ALL,
  SET_SHOW_SOME_SLOTS,
  SET_STATISTICS,
  SET_UNIT_EMPLOYEES,
  SHOW_ADD_PATIENTS_BUTTON,
} from './actionTypes';
import { resetReservation } from './board.actions';
import { Dispatch } from './types';

export const setClinicianUnits = (units: Unit[]) => (dispatch: Dispatch) => {
  dispatch({ type: SET_CLINICIAN_UNITS, payload: units });
};

export const getClinicianUnits = (history?: any) => async (
  dispatch: any,
  getState: any
) => {
  try {
    const {
      booking: { currentEmpUnit },
    } = getState();
    dispatch({ type: SET_LOADING, payload: true });

    const data = await apiService.getClinicianUnits();
    const units = data.data.units;
    // console.log('getClinicianUnits', units);
    dispatch({ type: SET_LOADING, payload: false });

    if (units.length > 0) {
      dispatch(setClinicianUnits(units));
      let unit = units[0];
      if (currentEmpUnit) {
        const i = units.findIndex((u: Unit) => u.sk === currentEmpUnit.sk);
        if (i >= 0) unit = units[i];
      }
      // console.log(unit);
      dispatch(setCurrentEmpUnit(unit));
      dispatch(setCurrentBooking(null));

      // console.log('redirectting...');
      if (history) {
        history.push(
          [
            UserRoles.Physician,
            UserRoles.LabTech,
            UserRoles.RadioTech,
          ].includes(unit.role)
            ? routes.BOARD
            : routes.BOOKING
        );
      }
      // if (history) history.push(routes.HOME);
    } else {
      if (isAuthenticatedPhysician()) {
        if (history) history.push(routes.SELECT_UNIT_TYPE);
        return;
      }
      if (history) history.push(routes.HOME);
    }
  } catch (error) {
    dispatch({ type: SET_LOADING, payload: { loading: false } });
    console.error('error[getClinicianUnits]', error);
  }
};

export const setCurrentEmpUnit = (unit: Unit) => async (
  dispatch: any,
  getState: any
) => {
  const {
    booking: { currentEmpUnit },
  } = getState();
  // if (currentEmpUnit && currentEmpUnit.sk === unit.sk) return;
  if (currentEmpUnit && currentEmpUnit.sk !== unit.sk) {
    closeSocketEvent(currentEmpUnit.sk);
  }
  storeData({ currentUnit: unit });
  dispatch({ type: SET_EMP_CURRENT_UNIT, payload: unit });
  dispatch(getUnitDetails(unit.sk));
  listenToSocketEvent(unit.sk, unit, dispatch);
};

export const getClinicUnitsWithUpdateCurrentUnit = (
  unit: any,
  history: any
) => async (dispatch: any) => {
  try {
    const data = await apiService.getClinicianUnits();
    const units = data.data.units;
    // console.log('getClinicianUnits', units);
    if (units.length > 0) {
      dispatch(setClinicianUnits(units));

      const u = units.find((un: any) => un.sk === unit.pk);
      if (u) dispatch(setCurrentEmpUnit(u));
      else dispatch(setCurrentEmpUnit(units[0]));
      // if (history) history.push(routes.HOME);
    } else {
      if (isAuthenticatedPhysician()) {
        if (history) history.push(routes.SELECT_UNIT_TYPE);
        return;
      }
      if (history) history.push(routes.HOME);
    }
  } catch (error) {
    console.error('error[getClinicianUnits]', error);
  }
};

export const getUnitDetails = (clinicId: string) => async (dispatch: any) => {
  try {
    if (!clinicId) return;
    const data = await apiService.getUnitDetails({ id: clinicId });
    const unitDetails = data.data.unit;
    if (unitDetails) {
      dispatch(setCurrentUnitDetails(unitDetails));
    }
  } catch (error) {
    console.error('error[getUnitDetails]', error);
  }
};

export const setCurrentUnitDetails = (unit: ClinicData) => async (
  dispatch: any
) => {
  dispatch({ type: SET_CURRENT_UNIT_DETAILS, payload: unit });
};

export const redeemUnitPoints = (id: string) => async (dispatch: any) => {
  try {
    const { data } = await redeemPoints({ id });
    const { unit } = data;
    dispatch(setCurrentUnitDetails(unit));
  } catch (error) {
    console.error(error);
  }
};

export const getUnitEmployees = (clinicId: string) => async (dispatch: any) => {
  try {
    // console.log('clinicId', clinicId);
    if (!clinicId) return;
    const data = await apiService.getUnitEmployees({ id: clinicId });
    const employees = data.data.employees;
    // console.log('getemployees', employees);
    if (employees) {
      dispatch({ type: SET_UNIT_EMPLOYEES, payload: employees });
    }
  } catch (error) {
    console.error('error[getUnitDetails]', error);
  }
};

export const setBookingFromSocket = (booking: Booking, eventName: string) => (
  dispatch: any,
  getState: any
) => {
  // console.log('setBookingFromSocket', booking);
  const {
    booking: { currentBooking, currentEmpUnit },
  } = getState();
  if (!booking) return;

  // if booking deleted or cancelled
  if (
    eventName === eventsNames.BookingDeleted ||
    booking.status === bookingStatus.canceled
  ) {
    // console.log('[DELETE_BOOKING || CANCEL_BOOKING]', booking);
    if (
      currentEmpUnit?.role === UserRoles.Physician &&
      booking?.ck === currentBooking?.ck
    ) {
      dispatch(resetReservation());
      dispatch({ type: RESET_HISTORY });
      dispatch(setCurrentPatient(null));
    }
    dispatch(setCurrentBooking(null));
    dispatch({
      type: CANCEL_BOOKING,
      payload: { booking, oldSk: +booking.date },
    });
    // if add or update booking
  } else {
    // console.log('[ADD_BOOKING || UPDATE_BOOKING]', booking);
    dispatch({ type: ADD_BOOKING, payload: booking });
    if (
      !currentBooking ||
      (currentBooking.pk === booking.pk && currentBooking.sk === booking.sk)
    ) {
      dispatch(setCurrentBooking(booking));
    }
  }
};

const setLoading = (value: boolean) => (dispatch: Dispatch) => {
  dispatch({ type: SET_LOADING, payload: value });
};

export const updateUnit = (payload: any) => async (
  dispatch: any,
  getState: any
) => {
  try {
    dispatch(setLoading(true));
    const data = await apiService.updateUnit(payload);
    const unit = data.data.unit;
    // console.log('updateUnit', unit);
    const { name: unitName } = unit;
    const {
      booking: { units },
    } = getState();
    // console.log('units[0]', units[0]);
    const unitIdx = units.findIndex((ele: Unit) => ele.sk === unit.pk);
    if (unitIdx >= 0) {
      units[unitIdx] = { ...units[unitIdx], unitName };
      setClinicianUnits(units);
      dispatch({ type: SET_EMP_CURRENT_UNIT, payload: units[unitIdx] });
    }
    dispatch(setCurrentUnitDetails(unit));
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setLoading(false));
    console.error('error[updateUnit]', error);
  }
};

export const resetBooking = () => async (dispatch: Dispatch) => {
  dispatch({ type: RESET_BOOKING });
};
export const resetCurrentUnit = () => async (dispatch: Dispatch) => {
  dispatch({ type: SET_CURRENT_UNIT_DETAILS, payload: null });
};

export const searchPatientByMobile = (mobile: string) => async (
  dispatch: any
) => {
  try {
    const data = await apiService.findPatientBy({ mobile });
    if (data.data.patients.length > 0) {
      dispatch({ type: SEARCH_PATIENTS, payload: data.data.patients });
    }
    dispatch({ type: SHOW_ADD_PATIENTS_BUTTON });
  } catch (error) {
    console.error('error[searchPatientByMobile]', error);
  }
};
export const searchPatientBookingByMobile = (
  mobile: string,
  unitId: string
) => async (dispatch: any) => {
  try {
    const data = await apiService.findPatientWithUnitBookingBy({
      mobile,
      unitId,
    });
    if (data.data.patients.length > 0) {
      dispatch({ type: SEARCH_PATIENTS, payload: data.data.patients });
    }
  } catch (error) {
    console.error('error[searchPatientByMobile]', error);
  }
};

export const removeSearchedPatients = () => async (dispatch: Dispatch) => {
  dispatch({ type: REMOVE_SEARCHED_PATIENTS });
};

export const setCurrentPatient = (patient: any) => (dispatch: Dispatch) => {
  dispatch({ type: SET_CURRENT_PATIENT, payload: patient });
};

export const addNewPatient = (payload: any, history: any) => async (
  dispatch: any
) => {
  try {
    const data = await apiService.addPatient(payload);
    // console.log('addPatient', data.data.patient);
    dispatch(setCurrentPatient(data.data.patient));
    history.push(routes.ADD_BOOKING);
  } catch (error) {
    console.error('error[addPatientt]', error);
  }
};

export const editPatient = (payload: any, history: any) => async (
  dispatch: any,
  getState: any
) => {
  try {
    await apiService.updatePatient(payload);
    // console.log('editPatient', patient);
    const {
      booking: { bookings },
    } = getState();
    // console.log('payload', payload);
    const {
      dob,
      age,
      firstName,
      lastName,
      gender,
      gov,
      pk,
      mobile: lmobile,
    } = payload;
    // console.log('currentBooking', currentBooking);
    const curBookInd = bookings.findIndex(
      (ele: Booking) => ele.patientId === payload.pk
    );
    if (curBookInd >= 0) {
      bookings[curBookInd] = {
        ...bookings[curBookInd],
        patient: {
          dob,
          age,
          name: { firstName, lastName },
          gender,
          gov,
          pk,
          lmobile,
        },
      };
      dispatch(
        updateBookingWithOutMsg(
          bookings[curBookInd].pk,
          bookings[curBookInd].sk,
          'status',
          {
            status: bookings[curBookInd].status,
          },
          true
        )
      );

      dispatch(setUnitBookings([...bookings]));
      dispatch(setCurrentBooking({ ...bookings[curBookInd] }));
    }
    history.push(routes.BOOKING);
  } catch (error) {
    console.error('error[editPatientt]', error);
  }
};

export const setBookingDate = (date: number) => (dispatch: Dispatch) => {
  dispatch({ type: SET_BOOKING_DATE, payload: date });
};

export const setBookingEndDate = (date: number) => (dispatch: Dispatch) => {
  dispatch({ type: SET_BOOKING_END_DATE, payload: date });
};

export const addBooking = (payload: any, history: any) => async (
  dispatch: any
) => {
  try {
    dispatch(setLoading(true));
    // console.log('payload', payload);
    const data = await apiService.addBooking(payload);
    const booking: Booking = data.data.booking;
    const bookingDate = new Date(
      new Date(booking.date).toJSON().slice(0, 10)
    ).getTime();
    dispatch(setBookingDate(bookingDate));
    dispatch(setCurrentBooking(booking));
    dispatch({ type: ADD_BOOKING, payload: booking });
    dispatch(setLoading(false));
    history.push(routes.BOOKING);
  } catch (error) {
    dispatch(setLoading(false));
    console.error('error[addBooking]', error);
  }
};

export const updateBooking = (
  clinicId: string,
  bookingDate: string,
  updateType:
    | 'status'
    | 'reschedule'
    | 'paymentStatus'
    | 'cost'
    | 'examinationType'
    | 'labBooking',
  newValue: any,
  history?: any
) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const data = await apiService.updateBooking({
      clinicId,
      bookingDate,
      updateType,
      newValue,
    });
    if (updateType !== 'labBooking') {
      const booking: Booking = data.data.booking;
      // console.log('updateBooking', booking);
      const bookDate = new Date(
        new Date(booking.date).toJSON().slice(0, 10)
      ).getTime();
      dispatch(setBookingDate(bookDate));
      if (booking.status === bookingStatus.canceled) {
        dispatch(setCurrentBooking(null));
        dispatch({
          type: CANCEL_BOOKING,
          payload: { booking, oldSk: bookingDate },
        });
      } else {
        dispatch({ type: ADD_BOOKING, payload: booking });
        dispatch(setCurrentBooking(booking));
      }
    }
    dispatch(setLoading(false));
    if (history) {
      history.push(routes.BOOKING);
    }
  } catch (error) {
    dispatch(setLoading(false));
    console.error('error[updateBooking]', error);
  }
};

export const updateBookingWithOutMsg = (
  clinicId: string,
  bookingDate: string,
  updateType: 'status',
  newValue: any,
  hideMsg: true
) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));

    const data = await apiService.updateBooking({
      clinicId,
      bookingDate,
      updateType,
      newValue,
      hideMsg,
    });
    const booking: Booking = data.data.booking;
    // console.log('updateBooking', booking);
    const bookDate = new Date(
      new Date(booking.date).toJSON().slice(0, 10)
    ).getTime();
    dispatch(setBookingDate(bookDate));
    if (booking.status === bookingStatus.canceled) {
      dispatch(setCurrentBooking(null));
      dispatch({
        type: CANCEL_BOOKING,
        payload: { booking, oldSk: bookingDate },
      });
    } else {
      dispatch({ type: ADD_BOOKING, payload: booking });
      dispatch(setCurrentBooking(booking));
    }

    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setLoading(false));
    console.error('error[updateBooking]', error);
  }
};

export const setCurrentBooking = (booking: Booking | null) => async (
  dispatch: Dispatch
) => {
  // if (!booking) dispatch({ type: SET_CURRENT_PATIENT, payload: null });
  dispatch({ type: RESET_HISTORY });
  dispatch({ type: SET_CURRENT_BOOKING, payload: booking });
};

export const updateCurrentBooking = (booking: Booking) => async (
  dispatch: any,
  getState: any
) => {
  const {
    booking: { currentBooking },
  } = getState();
  if (currentBooking && currentBooking.sk === booking.sk) {
    dispatch(setCurrentBooking(booking));
  }
};

export const setUnitBookings = (bookings: Booking[]) => async (
  dispatch: Dispatch
) => {
  dispatch({
    type: SET_CLINIC_BOOKINGS,
    payload: bookings,
  });
};

export const getClinicBookings = (
  clinicId: string,
  startDate: number,
  endDate: number
) => async (dispatch: any, getState: any) => {
  try {
    const {
      booking: { bookings },
    }: { booking: { bookings: Booking[] } } = getState();
    const data = await apiService.getClinicBookings({
      clinicId,
      startDate: moment(startDate).startOf('day').toDate().getTime(),
      endDate: moment(endDate).endOf('day').toDate().getTime(),
    });
    const unitBookings: Booking[] = data.data.bookings;
    const allBookings = [...unitBookings, ...bookings];
    const filteredArr = allBookings.reduce(
      (acc: Booking[], current: Booking) => {
        const x = acc.find((item) => item.date === current.date);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      },
      []
    );
    dispatch(setUnitBookings(filteredArr));
  } catch (error) {
    console.error('error[getClinicBooking]', error);
  }
};

export const getPatientPreviousBooking = (bookings: Booking[]) => async (
  dispatch: Dispatch
) => {
  dispatch({ type: PATIENT_PREVIOUS_BOOKINGS, payload: bookings });
};

export const getPatientOrders = (patietId: string, type: string) => async (
  dispatch: Dispatch
) => {
  try {
    const data = await apiService.getPatientOrders({ id: patietId, type });
    const orders = data.data.orders;
    // console.log('getPatientOrders', orders);
    dispatch({ type: SET_PATIENT_ORDERS, payload: orders });
  } catch (error) {
    console.error('error[getPatientOrders]', error);
  }
};

export const addPatientOrders = (
  patietId: string,
  payload: any,
  history?: any
) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    await apiService.addPatientOrders({
      id: patietId,
      ...payload,
    });
    // const orders = data.data;
    // console.log('addPatientOrders', orders);
    dispatch(setLoading(false));
    if (history) history.push(routes.BOOKING);
  } catch (error) {
    dispatch(setLoading(false));
    console.error('error[addPatientOrders]', error);
  }
};

export const shouldSearchInputFocus = (value: boolean) => (
  dispatch: Dispatch
) => {
  dispatch({ type: FOCUS_SEARCH_INPUT, payload: value });
};

export const getUnitStatistics = (
  clinicId: string,
  startDate: string,
  endDate: string
) => async (dispatch: Dispatch) => {
  try {
    const {
      data: { statistics },
    } = await apiService.getUnitMonthStatistics({
      clinicId,
      startDate,
      endDate,
    });
    // console.log('statistics', statistics);
    dispatch({ type: SET_STATISTICS, payload: statistics || [] });
  } catch (error) {
    console.error('error[getUnitStatistics]', error);
  }
};

export const setCurrentSlot = (slot: string) => (
  dispatch: Dispatch,
  getState: any
) => {
  const { booking: currentSlot } = getState();
  if (!slot || currentSlot === slot) return;
  dispatch({ type: SET_CURRENT_SLOT, payload: slot });
};

export const setCollapsedSlots = (slots: string) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_COLLAPSED_SLOTS,
    payload: slots,
  });
};
export const setShowAll = (showall: boolean) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_SHOW_ALL,
    payload: showall,
  });
};

export const setSomeSlotsShown = (show: boolean) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_SHOW_SOME_SLOTS,
    payload: show,
  });
};

export const setAllBooking = (allbooking: boolean) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_ALLBOOKING,
    payload: allbooking,
  });
};
