import React from 'react';
import VitalInput from '../VitalInputComponent/VitalInputComponent';

import '../AllInputComponent.scss';

const HeightInput = (props: any) => {
  const { onChange, values, bmi, setBmi } = props;

  return (
    <div className="col-md-12">
      <div className="form-group row vital-item">
        <label className="col-sm-3 col-form-label">Height:</label>
        <div className="col-sm-2">
          <VitalInput
            className="form-control"
            name={'height'}
            value={values.height || ''}
            type="number"
            onChange={(e: any) => {
              onChange(e.target.name, e.target.value);
            }}
          />
        </div>
        <div className="col-sm-1 ext">
          <span className="span-bottom">cm</span>
        </div>
      </div>
    </div>
  );
};

export default HeightInput;
