import React, { useState, useEffect } from 'react';
import './UnitDetails.scss';
import Button from '../../UI/Form/Button/Button';
import { useTranslation } from 'react-i18next';

import AddNewEmployess from './AddNewEmployess';
import MapModal from './MapModal/MapModal';

import governments from '../../../assets/constants/governments';
import areas from '../../../assets/constants/areas';
import roles, { UserRoles } from '../../../assets/constants/roles';

import { errorToast } from '../../../utils/toast';
import {
  mobileRegex,
  specialCharsWithNumers,
} from '../../../assets/constants/regexValues';
import { useSelector } from 'react-redux';
import { rootState } from '../../../store/reducers';
import { ClinicData } from '../../../types';
import parseArabic from '../../../utils/parseArabic';
import ApiService from '../../../services/api';
import { getPlacelatlng } from '../../../utils/location';
import { SubscriptionStatus } from '../../Subscription';

import mobileIcon from '../../../assets/img/mobile.svg';
import roleIcon from '../../../assets/img/role.svg';
import locationIcon from '../../../assets/img/location.svg';
import locationIcon2 from '../../../assets/img/location-3.svg';
import clinicIcon from '../../../assets/img/clinic.svg';
import arrowIcon from '../../../assets/img/arrow-down.svg';
import closeIcon from '../../../assets/img/close.svg';
import personalIcon from '../../../assets/img/personal.svg';
import phoneIcon from '../../../assets/img/phone.svg';
import questionIcon from '../../../assets/img/question.svg';
import question2Icon from '../../../assets/img/question2.svg';

type Props = {
  nextStepHandler: (payload: { type: string; data: any }) => Promise<void>;
  goBackHandler?: (payload: { type: string; data: any }) => void;
  currentSegment: string;
  // initialState: any;
  isSettingPage?: boolean;
  isSubmitting?: boolean;
  unit: ClinicData | null;
};

const UnitDetails: React.FC<Props> = ({
  nextStepHandler,
  currentSegment,
  // initialState,
  isSettingPage,
  isSubmitting,
  goBackHandler,
  unit,
}) => {
  const { t, i18n } = useTranslation();

  const [name, setName] = useState('');
  const [oldname, setoldName] = useState('');
  const [gov, setGov] = useState('');
  const [area, setArea] = useState('');
  const [phone, setPhone] = useState('');
  const [mobile, setMobile] = useState('');
  const [drTitle, setDrTitle] = useState('');
  const [drSubTitle, setDrSubTitle] = useState('');
  const [location, setLocation] = useState<{
    lat: number;
    lng: number;
  } | null>(null);
  const [address, setAddress] = useState('');
  const [employees, setEmployees] = useState<any[]>([]);
  const [removedEmployees, setRemovedEmployees] = useState<any[]>([]);
  const [addeddEmployees, setAddeddEmployees] = useState<any[]>([]);

  const [openModal, setOpenModal] = useState(false);
  const [showNotice, setShowNotice] = useState(false);

  const unitEmployees = useSelector(
    (statee: rootState) => statee.booking.unitEmployees
  );

  useEffect(() => {
    if (!unit) return;

    // console.log('unit', unit);
    if (unit.name) setName(unit.name || '');
    setoldName(unit.name || '');

    if (unit.gov) setGov(unit.gov || '');
    if (unit.area) setArea(unit.area || '');
    if (unit.phone) setPhone(unit.phone || '');
    if (unit.mobile) setMobile(unit.mobile || '');
    if (unit.drTitle) setDrTitle(unit.drTitle || '');
    if (unit.drSubTitle) setDrSubTitle(unit.drSubTitle || '');
    if (unit.location) setLocation(unit.location);
    if (unit.location && unit.location.address)
      setAddress(unit.location.address);
    //  dispatch(setCurrentEmpUnit(unit));
  }, [unit]);

  useEffect(() => {
    setEmployees(unitEmployees);
    setAddeddEmployees([]);
  }, [unitEmployees]);

  const saveClinicDetails = async () => {
    if (mobile) {
      const valid = mobileRegex.test(mobile);
      // console.log('valid', valid);
      if (!valid) {
        errorToast(
          t('addClinic_page.emp-errMsg', {
            mobile,
            unitType: unit?.type,
          })
        );
        return;
      }
    }
    let clinicData: any = {
      name,
      gov,
      area,
      phone,
      mobile,
      drTitle,
      drSubTitle,
      location: { ...location, address },
    };
    if (removedEmployees.length > 0) {
      clinicData = { ...clinicData, removedEmployees };
    }
    if (addeddEmployees.length > 0) {
      let isValid: boolean = false;
      let isValidMobile: boolean = false;
      for (const key of addeddEmployees) {
        isValid = !!key.mobile && !!key.role;
        isValidMobile = mobileRegex.test(key.mobile);
      }
      if (!isValid) {
        errorToast(t('employess_page.emp-errMsg'));
        return;
      }
      if (!isValidMobile) {
        errorToast(t('booking_page.valid-11'));
        return;
      }
      clinicData = { ...clinicData, addeddEmployees };
    }
    if (oldname !== name) clinicData = { ...clinicData, oldname };
    // console.log('clinic details', clinicData);
    await nextStepHandler({ type: currentSegment, data: clinicData });
    // setAddeddEmployees([]);
    // history.push(routes.SETTINGS);
  };

  const addEmployeeHandler = () => {
    const addedEmp = { mobile: '', role: UserRoles.Assistant };
    setAddeddEmployees([...addeddEmployees, addedEmp]);
  };

  const setInputHandler = (
    index: number,
    keyName: string,
    value: string | null
  ) => {
    if (!value) {
      addeddEmployees.splice(index, 1);
    } else {
      addeddEmployees[index] = {
        ...addeddEmployees[index],
        [keyName]: value,
      };
    }
  };

  const removeEmployeeHandler = (index: number) => {
    const epms = [...employees];
    const removedEmp = epms.splice(index, 1)[0];
    setEmployees([...epms]);
    setRemovedEmployees([...removedEmployees, removedEmp]);
  };

  const removeAddedEmployeeHandler = (
    mobileNum: string,
    role: string,
    index: number
  ) => {
    let emps = [...addeddEmployees];
    if (mobileNum) {
      emps = emps.filter(
        (emp) => emp.mobile !== mobileNum && emp.role !== role
      );
    } else {
      emps.splice(index, 1);
    }
    setAddeddEmployees([...emps]);
  };

  const changeClinicDetails = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const attrName = e.target.name;
    const attrValue = e.target.value;
    // console.log(attrName, attrValue);
    if (attrName === 'name') {
      setName(attrValue);
    } else if (attrName === 'gov') {
      setGov(attrValue);
    } else if (attrName === 'area') {
      setArea(attrValue);
    } else if (attrName === 'unitPhone') {
      const num = parseArabic(attrValue).replace(/[^0-9]/g, '');
      setPhone(num);
    } else if (attrName === 'unitMobile') {
      const num = parseArabic(attrValue).replace(/[^0-9]/g, '');
      setMobile(num);
    } else if (attrName === 'drTitle') {
      const val = parseArabic(attrValue).replace(specialCharsWithNumers, '');
      setDrTitle(val);
    } else if (attrName === 'drSubTitle') {
      const val = parseArabic(attrValue).replace(specialCharsWithNumers, '');
      setDrSubTitle(val);
    }
  };

  const openCloseModalHandler = () => {
    setOpenModal(!openModal);
  };

  const openMapModalHandler = () => {
    openCloseModalHandler();
  };

  const setLocationAndOpenModal = (lat: number, lng: number) => {
    setLocation({ lat, lng });
    openMapModalHandler();
  };

  const setLocationFromGov = async () => {
    const { data } = await ApiService.searchPlaces({ value: gov });
    const firstPlace = data.results[0].place_id;
    // console.log('data.results[0]', data.results[0]);
    if (!firstPlace) return;
    const { lat, lng, address: addr } = await getPlacelatlng(firstPlace);
    // console.log('lat, lng, addr', lat, lng, addr);
    setAddress(addr);
    setLocationAndOpenModal(lat, lng);
  };

  const checkLocationHandler = () => {
    // check unit location details
    if (location && location.lat && location.lng) {
      openMapModalHandler();
      // request browser location
    } else if (window.navigator.geolocation) {
      // Geolocation available
      window.navigator.geolocation.getCurrentPosition(
        (position: Position) => {
          const { latitude, longitude } = position.coords;
          // console.log('position', position);
          setLocationAndOpenModal(latitude, longitude);
        },
        (err) => {
          // console.error('err', err);
          // errorToast(err.message);
          setLocationFromGov();
        }
      );
    }
  };

  const changeAddressHandler = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    // console.log('e.target.value', e.target.value);
    setAddress(e.target.value);
  };

  const showHideNotice = () => {
    setShowNotice(!showNotice);
  };

  return (
    <div className="unit-details-container d-flex flex-column">
      {openModal && (
        <MapModal
          isOpen={openModal}
          onModalClose={openCloseModalHandler}
          confirmHandler={({ lat, lng, address: newAddress }) => {
            // console.log('[unit details component]', lat, lng, newAddress);
            if (lat && lng) setLocation({ lat, lng });
            if (newAddress) setAddress(newAddress);
            openCloseModalHandler();
          }}
          location={location}
        />
      )}
      {isSettingPage && (
        <div className="d-flex justify-content-between">
          <SubscriptionStatus unit={unit} />
          <div className="button-actions d-flex align-self-end mb-4">
            <Button
              type="button"
              name={t('setting_page.save')}
              saveData={saveClinicDetails}
              isSubmitting={isSubmitting}
            />
            <Button
              type="button"
              name={t('addBooking_page.back')}
              btnType="back"
              saveData={goBackHandler}
            />
          </div>
        </div>
      )}
      <div className="d-flex flex-column">
        <div className="unit-details-container__main  d-flex">
          {/* unit details */}
          <div className="unit-container">
            {/*  name and location */}
            <div className="details d-flex flex-column align-self-start p-3">
              <h5>{t('addClinic_page.unitDetails')}</h5>
              <div className="name-gov d-flex justify-content-between p-3 mt-3">
                {/* first part */}
                {/* <div> */}
                {/* name */}
                <div>
                  <div className="lock-icon d-flex align-items-center">
                    <img src={clinicIcon} alt="type name"></img>
                    <h6>{t('addClinic_page.name')}</h6>
                  </div>
                  <div className="passwordInput mt-3">
                    <input
                      name="name"
                      type="text"
                      value={name}
                      placeholder={t('addClinic_page.name')}
                      onChange={changeClinicDetails}
                    />
                  </div>
                </div>
                {/* unit city */}
                <div>
                  <div className="lock-icon d-flex align-items-center">
                    <img src={locationIcon} alt="lockIcon"></img>
                    <h6>{t('addClinic_page.city')}</h6>
                  </div>
                  <div className="passwordInput mt-3">
                    <select
                      name="gov"
                      value={gov}
                      onChange={changeClinicDetails}
                    >
                      <option value=""></option>
                      {Object.keys(governments).map((key) => (
                        <option key={key} value={key}>
                          {governments[key][i18n.language]}
                        </option>
                      ))}
                    </select>
                    <img className="arrow" src={arrowIcon} alt="arrowIcon" />
                  </div>
                </div>
                <div>
                  <div className="lock-icon d-flex align-items-center">
                    <img src={locationIcon} alt="lockIcon"></img>
                    <h6>{t('addClinic_page.area')}</h6>
                  </div>
                  <div className="passwordInput mt-3">
                    <select
                      name="area"
                      value={area}
                      onChange={changeClinicDetails}
                    >
                      <option value=""></option>
                      {areas[gov] ? (
                        Object.keys(areas[gov]).map((key) => (
                          <option key={key} value={key}>
                            {areas[gov][key][i18n.language]}
                          </option>
                        ))
                      ) : (
                        <option></option>
                      )}
                    </select>
                    <img className="arrow" src={arrowIcon} alt="arrowIcon" />
                  </div>
                </div>
                {/* </div> */}
              </div>
            </div>
            {/* dr title and unit telephone numbers */}
            <div className="pres d-flex flex-column p-3 mt-4">
              <div className="notice d-flex align-items-center ">
                <h5>{t('addClinic_page.onlineDetails')} </h5>

                <img
                  src={showNotice ? questionIcon : question2Icon}
                  alt=""
                  onClick={showHideNotice}
                  onBlur={() => {
                    setShowNotice(false);
                  }}
                  tabIndex={1}
                />
                {showNotice && <p>{t('addClinic_page.notice')}</p>}
              </div>
              <div className="pres-online d-flex justify-content-between p-3 mt-3">
                {/* first part */}
                <div>
                  <div className="name-gov dr d-flex flex-column">
                    {/* dr title */}
                    <div>
                      <div className="lock-icon d-flex align-items-center">
                        <img src={personalIcon} alt="type name"></img>
                        <h6>{t('addClinic_page.dr-title')}</h6>
                      </div>
                      <div className="passwordInput mt-3">
                        <input
                          className="shared"
                          name="drTitle"
                          type="text"
                          value={drTitle}
                          placeholder={t('addClinic_page.dr-title-holder')}
                          onChange={changeClinicDetails}
                          maxLength={30}
                        />
                      </div>
                    </div>
                    {/* dr sub title */}
                    <div className="mt-3">
                      <div className="lock-icon d-flex align-items-center">
                        <img src={personalIcon} alt="type name"></img>
                        <h6>{t('addClinic_page.dr-subTitle')}</h6>
                      </div>
                      <div className="passwordInput mt-3">
                        <textarea
                          className="shared right"
                          name="drSubTitle"
                          value={drSubTitle}
                          rows={3}
                          placeholder={t('addClinic_page.dr-subtitle-holder')}
                          onChange={changeClinicDetails}
                          maxLength={130}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="tel d-flex flex-column mt-4">
                    {/* unit phone */}
                    <div>
                      <div className="lock-icon d-flex align-items-center">
                        <img src={phoneIcon} alt="type name"></img>
                        <h6>{t('addClinic_page.tel')}</h6>
                      </div>
                      <div className="passwordInput mt-3">
                        <input
                          name="unitPhone"
                          type="number"
                          value={phone}
                          placeholder={t('addClinic_page.mobile-holder')}
                          onChange={changeClinicDetails}
                          maxLength={10}
                        />
                      </div>
                    </div>
                    {/* unit mobile */}
                    <div className="mt-3">
                      <div className="lock-icon mobile-icon d-flex align-items-center">
                        <img src={mobileIcon} alt="type name"></img>
                        <h6>{t('addClinic_page.mobile')}</h6>
                      </div>
                      <div className="passwordInput mt-3">
                        <input
                          name="unitMobile"
                          type="number"
                          value={mobile}
                          placeholder={t('addClinic_page.mobile-holder')}
                          onChange={changeClinicDetails}
                          maxLength={11}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* second part */}
                <div className="map-container ">
                  {location && location.lat && location.lng ? (
                    <>
                      <div
                        className="flex-fill"
                        style={{
                          overflow: 'hidden',
                          height: '17.7rem',
                        }}
                      >
                        <iframe
                          title="map"
                          width="100%"
                          height="400"
                          frameBorder="0"
                          style={{
                            border: 0,
                            borderRadius: '1rem',
                            marginTop: '-100px',
                            marginBottom: '-145px',
                            pointerEvents: 'none',
                          }}
                          src={`https://maps.google.com/maps?q= + ${location.lat} + , + ${location.lng} + &t=&z=15&ie=UTF8&iwloc=&output=embed`}
                        ></iframe>
                      </div>
                      <div className="d-flex align-items-center mt-3">
                        <div className=" btn-container">
                          <button
                            className="d-flex justify-content-center align-items-center"
                            onClick={checkLocationHandler}
                          >
                            <img src={locationIcon2} alt="" />
                            <span>
                              {location && location.lat && location.lng
                                ? t('addClinic_page.edit-location')
                                : t('addClinic_page.add-location')}
                            </span>
                          </button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div
                      className="no-map d-flex flex-column justify-content-center align-items-center"
                      onClick={checkLocationHandler}
                    >
                      <img src={locationIcon} alt="" />
                      <p>{t('addClinic_page.pick')}</p>
                    </div>
                  )}

                  {/* unit address */}
                  <div className="mt-3">
                    <div className="lock-icon d-flex align-items-center">
                      <img src={locationIcon} alt="type name"></img>
                      <h6>{t('addClinic_page.address')}</h6>
                    </div>
                    <div className="passwordInput mt-3">
                      <textarea
                        name="address"
                        className="shared address"
                        value={address}
                        rows={2}
                        placeholder={t('addClinic_page.address-holder')}
                        onChange={changeAddressHandler}
                        maxLength={97}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* unit employees */}
          <div className="employees-container align-self-start p-3">
            <h5>{t('addClinic_page.empDetails')}</h5>
            <div className="description d-flex justify-content-between ">
              <p className="descriptionText p-3 mt-3">
                {t('employess_page.description')}
              </p>
            </div>
            <div className="mt-3">
              {employees &&
                employees.length > 0 &&
                employees.map((emp, index) => (
                  <div
                    key={`clinEmp-${index}-${emp.mobile}`}
                    className="employees d-flex p-3 justify-content-between"
                  >
                    <div>
                      <div className="lock-icon mobile-icon d-flex align-items-center">
                        <img src={mobileIcon} alt="mobile icon" />
                        <h6>{t('setting_page.mobile')}</h6>
                      </div>
                      <div className="passwordInput mt-3">
                        <input
                          name="mobile"
                          type="number"
                          defaultValue={emp.mobile}
                          placeholder={t('setting_page.mobile')}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="">
                      <div className="lock-icon d-flex align-items-center">
                        <img src={roleIcon} alt="lockIcon"></img>
                        <h6>{t('employess_page.emp-role')}</h6>
                        <div className="close-iconn">
                          <img
                            src={closeIcon}
                            alt="close icon"
                            onClick={() => {
                              removeEmployeeHandler(index);
                            }}
                          />
                        </div>
                      </div>
                      <div className="passwordInput mt-3">
                        <select name="role" defaultValue={emp.role} disabled>
                          <option value="">
                            {t('register_page.location')}
                          </option>
                          {Object.keys(roles).map((key) => (
                            <option key={key} value={key}>
                              {roles[key][i18n.language]}
                            </option>
                          ))}
                        </select>
                        <img
                          className="arrow"
                          src={arrowIcon}
                          alt="arrowIcon"
                        />
                      </div>
                    </div>
                  </div>
                ))}
              {unit &&
                addeddEmployees &&
                addeddEmployees.length > 0 &&
                addeddEmployees.map((ele, eleIndex) => (
                  <AddNewEmployess
                    key={`addedEmp-${eleIndex}`}
                    index={eleIndex}
                    unitType={unit?.type}
                    removeAddedEmployeeHandler={removeAddedEmployeeHandler}
                    addEmpHandler={setInputHandler}
                  />
                ))}
              <p className="add mt-3 p-3">
                <span onClick={addEmployeeHandler}>
                  {t('setting_page.add-emp')}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnitDetails;
