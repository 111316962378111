import React, { useState, useEffect } from 'react';
import './ClinicBookingDetails.scss';
import { useTranslation } from 'react-i18next';
import Button from '../../UI/Form/Button/Button';
import CancelBookingModal from './CancelBookingModal/CancelBookingModal';

import { Booking, ClinicData } from '../../../types';
import getFullName from '../../../utils/getFullName';
import { setGanderByLang } from '../../../assets/constants/gender';
import {
  formatTimeAmPm,
  formatDateWithDay,
  getAgeFromBD,
} from '../../../utils/moment';
import bookingStatus from '../../../assets/constants/bookingStatus';

import calenderIcon from '../../../assets/img/calender.svg';
import clockIcon from '../../../assets/img/clock.svg';
import bookingIcon from '../../../assets/img/medical-degree.svg';
import editIcon from '../../../assets/img/edit.svg';
import nameIcon from '../../../assets/img/personal.svg';
import mobileIcon from '../../../assets/img/mobile.svg';
import genderIcon from '../../../assets/img/gender.svg';
import days from '../../../assets/constants/days';
import paymentStatus from '../../../assets/constants/paymentStatus';
import { useHistory } from 'react-router-dom';
import routes from '../../../assets/constants/routes';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPatient } from '../../../store/actions';
import { rootState } from '../../../store/reducers';
import { UserRoles } from '../../../assets/constants/roles';
import { StartExaminationAction } from '../../../store/actions/board.actions';

type Props = {
  currentBooking: Booking | null;
  updateBookingStatusHandler: (status: string) => void;
  payBookingHandler: (status: string) => void;
  clinic: ClinicData;
};

const ClinicBookingDetails: React.FC<Props> = ({
  currentBooking,
  updateBookingStatusHandler,
  payBookingHandler,
  clinic,
}) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [isActiveBooking, setIsActiveBooking] = useState(false);
  const currentEmpUnit = useSelector(
    (state: rootState) => state.booking.currentEmpUnit
  );
  const bookings = useSelector((state: rootState) => state.booking.bookings);
  const bookingDate = useSelector(
    (state: rootState) => state.booking.bookingDate
  );

  useEffect(() => {
    const isActive =
      bookings.length > 0 &&
      bookings.some(
        (ele) =>
          ele.status === bookingStatus.active &&
          new Date(ele.date).getDate() === new Date(bookingDate).getDate()
      );

    // console.log('isActive', isActive);
    setIsActiveBooking(isActive);
  }, [bookingDate, bookings]);

  const calcAgeHandler = (patient: any) => {
    let age: any;
    if (patient && patient.dob) {
      const patientAge = getAgeFromBD(new Date(patient.dob));
      age = `${patientAge.split(' ')[0]} ${t(
        'booking_page.' + patientAge.split(' ')[1]
      )}`;
    } else {
      age = `${patient.age} ${t('booking_page.age')}`;
    }

    return age;
  };

  const getCustomTypeName = (key: string) => {
    return clinic?.examinationTypes[key].name
      ? clinic?.examinationTypes[key].name
      : key;
  };

  const openModalHandler = () => {
    setOpenModal(!openModal);
  };

  const goToEditPatientHandler = () => {
    if (!currentBooking || currentBooking.date < Date.now()) return;
    history.push({
      pathname: routes.ADD_PATIENT,
      state: { patient: currentBooking.patient },
    });
  };

  const goToEditBookingHandler = () => {
    if (
      !currentBooking ||
      [bookingStatus.canceled, bookingStatus.completed].includes(
        currentBooking.status
      )
    )
      return;
    dispatch(setCurrentPatient(currentBooking.patient));
    history.push({
      pathname: routes.ADD_BOOKING,
      state: { booking: currentBooking },
    });
  };

  return (
    <div className="booking-details-container">
      <CancelBookingModal
        isOpen={openModal}
        onModalClose={openModalHandler}
        confirmCancelHandler={() => {
          openModalHandler();
          updateBookingStatusHandler(bookingStatus.canceled);
        }}
      />
      <div className="booking-actions align-items-center d-flex justify-content-between">
        <h6>{t('addBooking_page.booking')}</h6>
        <div className="button-actions d-flex">
          <Button
            type="button"
            name={t('booking_page.cancel')}
            btnType="warning"
            isDisabled={
              !currentBooking ||
              ![bookingStatus.new].includes(currentBooking.status)
            }
            saveData={openModalHandler}
          />
          {currentEmpUnit?.role !== UserRoles.Physician && (
            <Button
              type="button"
              name={t('booking_page.dr-enter')}
              isDisabled={
                !currentBooking ||
                isActiveBooking ||
                ![bookingStatus.new, bookingStatus.hold].includes(
                  currentBooking.status
                )
              }
              saveData={() => {
                dispatch(StartExaminationAction(true));
                updateBookingStatusHandler(bookingStatus.active);
              }}
            />
          )}
        </div>
      </div>
      <div className="booking-details-container--data align-items-center d-flex flex-column flex-sm-row justify-content-between mt-5">
        <div className="patient align-self-start">
          <div className="patient-header d-flex flex-fill justify-content-between align-items-center">
            <p>{t('booking_page.patient')}</p>
            <div
              className="patient-header-edit"
              onClick={goToEditPatientHandler}
            >
              <img src={editIcon} alt="editIcon" />
            </div>
          </div>
          {currentBooking && currentBooking.patient && (
            <div className="patient-details d-flex flex-column justify-content-between">
              <div className="booking-item align-items-center d-flex">
                <img src={nameIcon} alt="mobile icon" />
                <h6 className="ar-font">
                  {currentBooking.patient.name
                    ? getFullName(currentBooking.patient.name)
                    : ''}
                </h6>
              </div>
              <div className="booking-item align-items-center d-flex">
                <img src={genderIcon} alt="mobile icon" />
                <h6>
                  {currentBooking.patient.gender
                    ? setGanderByLang(currentBooking.patient.gender)
                    : ''}
                </h6>
              </div>

              <div className="booking-item align-items-center d-flex">
                <img src={mobileIcon} alt="mobile icon" />
                <h6 className="mobile">
                  {currentBooking.patient.lmobile
                    ? currentBooking.patient.lmobile
                    : ''}
                </h6>
              </div>
              <div className="booking-item align-items-center d-flex">
                <img src={calenderIcon} alt="mobile icon" />
                <h6>{calcAgeHandler(currentBooking.patient)}</h6>
              </div>
            </div>
          )}
        </div>

        <div className="booking align-self-start">
          <div className="patient-header d-flex flex-fill justify-content-between align-items-center">
            <p>{t('booking_page.booking')}</p>
            <div
              className="patient-header-edit"
              onClick={goToEditBookingHandler}
            >
              <img src={editIcon} alt="editIcon" />
            </div>
          </div>
          {currentBooking && currentBooking.sk && (
            <div className="booking-details">
              <div className="booking-item align-items-center d-flex">
                <img src={calenderIcon} alt="mobile icon" />
                <h6>
                  {`${
                    days[
                      formatDateWithDay(new Date(currentBooking.date))
                        .split(' ')[1]
                        .toLocaleLowerCase()
                    ][i18n.language]
                  } ${
                    formatDateWithDay(new Date(currentBooking.date)).split(
                      ' '
                    )[0]
                  }`}
                </h6>
              </div>
              <div className="booking-item align-items-center d-flex">
                <img src={bookingIcon} alt="mobile icon" />
                <h6>
                  {i18n.exists(
                    'examinationAndPrices_page.' + currentBooking.type
                  )
                    ? t('examinationAndPrices_page.' + currentBooking.type)
                    : getCustomTypeName(currentBooking.type)}
                </h6>
              </div>
              <div className="booking-item align-items-center d-flex">
                <img src={clockIcon} alt="mobile icon" />
                <h6>
                  {`${
                    formatTimeAmPm(new Date(currentBooking.date)).split(' ')[0]
                  } ${t(
                    'booking_page.' +
                      formatTimeAmPm(new Date(currentBooking.date)).split(
                        ' '
                      )[1]
                  )}`}
                </h6>
              </div>
              <div className="booking-item payment d-flex align-items-center justify-content-between">
                <div className="d-flex">
                  <img src={bookingIcon} alt="mobile icon" />
                  <h6>
                    {`${currentBooking.cost} ${t(
                      'examinationAndPrices_page.egp'
                    )}`}
                  </h6>
                </div>
                {[paymentStatus.refunded, paymentStatus.not_paid].includes(
                  currentBooking.paymentStatus
                ) && (
                  <Button
                    type="button"
                    name={t('booking_page.pay')}
                    saveData={() => {
                      payBookingHandler(paymentStatus.paid);
                    }}
                  />
                )}
                {currentBooking.paymentStatus === paymentStatus.paid && (
                  <Button
                    type="button"
                    name={t('booking_page.refund')}
                    btnType="warning"
                    saveData={() => {
                      payBookingHandler(paymentStatus.refunded);
                    }}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ClinicBookingDetails;
