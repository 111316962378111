import React, { useState, useEffect } from 'react';
import './ExaminationTypes.scss';
import { useTranslation } from 'react-i18next';

import ExaminationType from './ExaminationType/ExaminationType';
import { getRandomColor, colors } from '../../../utils/getRandomColor';
import Button from '../../UI/Form/Button/Button';
import { errorToast } from '../../../utils/toast';
import backArrow from '../../../assets/img/backArrow.svg';
import { ClinicData } from '../../../types';
import { SubscriptionStatus } from '../../Subscription';
import EventTracker from '../../../utils/analytics';

let data: any = {};

type Props = {
  nextStepHandler: (payload: { type: string; data: any }) => void;
  goBackHandler: (payload: { type: string; data: any }) => void;
  currentSegment: string;
  initialState: any;
  isSettingPage?: boolean;
  isSubmitting?: boolean;
  unit?: ClinicData | null;
};

type Examine = {
  key: string;
  type: string;
  name: string;
  price: string;
  time: string;
  color: string;
  shouldToggle: boolean;
  isCustom: boolean;
  isFollowUp: boolean;
  period?: string;
  value?: string;
};

const ExaminationTypes: React.FC<Props> = ({
  nextStepHandler,
  currentSegment,
  goBackHandler,
  initialState,
  isSettingPage,
  isSubmitting,
  unit,
}) => {
  const { t } = useTranslation();
  const [customExamination, setCustomExamination] = useState(2);

  const [examinationTypes, setExaminationTypes] = useState<Examine[]>([
    {
      key: 'normal',
      name: 'normal',
      type: 'normal',
      price:
        initialState.normal && initialState.normal.price + ''
          ? initialState.normal.price
          : '',
      time:
        initialState.normal && initialState.normal.time
          ? initialState.normal.time
          : '',
      color: colors.normal,
      shouldToggle:
        initialState.normal && initialState.normal.hasOwnProperty('open')
          ? initialState.normal.open
          : true,
      isCustom: false,
      isFollowUp: false,
    },
    {
      key: 'followUp',
      name: 'followUp',
      type: 'followUp',
      price:
        initialState.followUp && initialState.followUp.price + ''
          ? initialState.followUp.price
          : '',
      time:
        initialState.followUp && initialState.followUp.time
          ? initialState.followUp.time
          : '',
      period:
        initialState.followUp && initialState.followUp.period
          ? initialState.followUp.period
          : '',
      color: colors.followUp,
      shouldToggle:
        initialState.followUp && initialState.followUp.hasOwnProperty('open')
          ? initialState.followUp.open
          : initialState.followUp && initialState.followUp
          ? true
          : false,
      isCustom: false,
      isFollowUp: true,
    },
    {
      key: 'urgent',
      name: 'urgent',
      type: 'urgent',
      price:
        initialState.urgent && initialState.urgent.price + ''
          ? initialState.urgent.price
          : '',
      time:
        initialState.urgent && initialState.urgent.time
          ? initialState.urgent.time
          : '',
      color: colors.urgent,
      shouldToggle:
        initialState.urgent && initialState.urgent.hasOwnProperty('open')
          ? initialState.urgent.open
          : initialState.urgent && initialState.urgent
          ? true
          : false,
      isCustom: false,
      isFollowUp: false,
    },
  ]);

  const setInputHandler = (
    name: string,
    color: string,
    keyName: string,
    value: string | null,
    isFirstToggle: boolean = false
  ) => {
    // console.log(name, color, keyName, value, isFirstToggle);
    if (value === null && !isFirstToggle) {
      const ff = {
        [name]: {
          ...data[name],
          open: false,
        },
      };
      if (keyName) ff[name][keyName] = value;
      data = { ...data, ...ff };
      // delete data[name];
      // if (!['normal', 'urgent', 'followUp'].includes(name)) {
      //   removeExminationHandler(name);
      // }
    } else {
      const ff = {
        [name]: {
          ...data[name],
          color,
          open: true,
        },
      };
      if (keyName) ff[name][keyName] = value;
      data = { ...data, ...ff };
    }
    // console.log('data[setInputHandler]', data);
  };

  useEffect(() => {
    // console.log('initialState', initialState);
    if (Object.keys(initialState).length > 0) {
      const { normal, urgent, followUp, ...customTypes } = initialState;
      data = { ...initialState };
      // console.log('data[useEffect]', data);
      let clinExams = [...examinationTypes];
      if (customTypes && Object.keys(customTypes).length > 0) {
        Object.keys(customTypes).forEach((ele: any) => {
          clinExams = clinExams.filter((field: any) => field.key !== ele);
          const addedExamine = {
            key: ele,
            name: ele,
            type: ele,
            price: data[ele].price,
            time: data[ele].time,
            color: data[ele].color,
            value: data[ele].name,
            shouldToggle: data[ele].hasOwnProperty('open')
              ? data[ele].open
              : true,
            isCustom: true,
            isFollowUp: false,
          };
          clinExams.push(addedExamine);
          setCustomExamination(+ele.split('m')[1] + 1);
        });
      }
      setExaminationTypes([...clinExams]);
    }
    // eslint-disable-next-line
  }, []);

  const goBack = () => {
    goBackHandler({ type: currentSegment, data: initialState });
  };

  const addExaminationHandler = () => {
    const color = getRandomColor(customExamination);
    if (!data[`custom${customExamination}`]) {
      data = {
        ...data,
        [`custom${customExamination}`]: {
          price: 0,
          time: '',
          color,
          name: '',
        },
      };
    }
    const addedExamine = {
      key: 'custom' + customExamination,
      name: 'custom' + customExamination,
      type: 'custom-' + customExamination,
      price: '',
      time: '',
      color,
      shouldToggle: true,
      isCustom: true,
      isFollowUp: false,
    };
    setInputHandler('custom' + customExamination, color, '', null, true);
    setExaminationTypes([...examinationTypes, addedExamine]);
    setCustomExamination((normal) => normal + 1);
  };

  const saveExminationsAndPriceshandler = async () => {
    // console.log('data', data);
    EventTracker.sendEvent('SET_EXAMINATION_TYPES', {});

    if (Object.keys(data).length <= 0) {
      errorToast(t('employess_page.choose_one'));
      return;
    }

    const isAllClosed = Object.keys(data).every(
      (ele) => data[ele].hasOwnProperty('open') && data[ele].open === false
    );
    // console.log('isAllClosed', isAllClosed);

    if (isAllClosed) {
      errorToast(t('employess_page.choose_one'));
      return;
    }

    for (const ele of Object.keys(data)) {
      if (
        // custom types should include name
        (!['normal', 'urgent', 'followUp'].includes(ele) &&
          !data[ele].name &&
          data[ele].open) ||
        // normal and urgent should have price and time > 0
        (!(
          (['normal', 'urgent'].includes(ele) &&
            +data[ele].price > 0 &&
            data[ele].time) ||
          // custom types shouldn't have price > 0
          (!['normal', 'urgent'].includes(ele) &&
            +data[ele].price >= 0 &&
            data[ele].time)
        ) &&
          data[ele].open)
      ) {
        errorToast(t('employess_page.fill_choosed'));
        return;
      }
    }

    // console.log('data', data);

    nextStepHandler({ type: currentSegment, data });
  };

  // const removeExminationHandler = (key: string) => {
  //   const newExaminationTypes = [...examinationTypes];
  //   const filteredTypes = newExaminationTypes.filter(
  //     (ele: any) => ele.key !== key
  //   );
  //   setExaminationTypes([...filteredTypes]);
  //   delete data[key];
  // };

  return (
    <div className="examinationTypes-container d-flex flex-column">
      {isSettingPage && (
        <div className="d-flex justify-content-between">
          <SubscriptionStatus unit={unit} />
          <div className="button-actions d-flex align-self-end mb-4">
            <Button
              type="button"
              name={t('setting_page.save')}
              saveData={saveExminationsAndPriceshandler}
              isSubmitting={isSubmitting}
            />
            <Button
              type="button"
              name={t('addBooking_page.back')}
              btnType="back"
              saveData={goBack}
            />
          </div>
        </div>
      )}

      <div className="description d-flex justify-content-between ">
        <p className="descriptionText p-4 mb-4">
          {t('examinationAndPrices_page.description')}
        </p>
      </div>

      {/* 
      
      data[ele.name] && data[ele.name].open
                ? data[ele.name].open
                : 
      */}
      <div className="examinationTypes-container__main">
        {examinationTypes.map((ele, index) => (
          <ExaminationType
            key={`examination-${ele.key}-${index}`}
            type={ele.type}
            name={ele.name}
            color={
              data[ele.name] && data[ele.name].color
                ? data[ele.name].color
                : ele.color
            }
            isCustom={ele.isCustom}
            isFollowUp={ele.isFollowUp}
            shouldToggle={
              data[ele.name] && data[ele.name].shouldToggle
                ? data[ele.name].shouldToggle
                : ele.shouldToggle
            }
            price={
              data[ele.name] && data[ele.name].price + ''
                ? data[ele.name].price
                : ele.price
            }
            time={
              data[ele.name] && data[ele.name].time
                ? data[ele.name].time
                : ele.time
            }
            period={
              data[ele.name] && data[ele.name].period
                ? data[ele.name].period
                : ele.period
            }
            value={data[ele.name] ? data[ele.name].name : ele.value}
            setInputHandler={setInputHandler}
          />
        ))}
      </div>
      <button
        className="add-examination link-button mt-2"
        onClick={addExaminationHandler}
      >
        {t('examinationAndPrices_page.add')}
      </button>
      {!isSettingPage && (
        <>
          <Button
            saveData={() => {
              saveExminationsAndPriceshandler();
            }}
            name={t('working_days_page.next')}
          />
          <div
            className="back go-back align-self-end"
            onClick={() => {
              goBack();
            }}
          >
            <span>{t('otp_page.back')}</span>
            <img src={backArrow} alt="back" />
          </div>
        </>
      )}
    </div>
  );
};

export default ExaminationTypes;
