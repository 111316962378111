import React, { useEffect, useState } from 'react';
import { Collapse } from 'react-collapse';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import bookingStatus from '../../../../assets/constants/bookingStatus';
import {
  OutLineCheck,
  PauseIcon,
  SolidCheck,
} from '../../../../assets/constants/images';
import paymentStatus from '../../../../assets/constants/paymentStatus';
import avatarIcon from '../../../../assets/img/avatar.svg';
import collapseIcon from '../../../../assets/img/collapse.svg';
import GirlAvatarIcon from '../../../../assets/img/GirlAvatar.svg';
import moreIcon from '../../../../assets/img/more.svg';
import showIcon from '../../../../assets/img/show.svg';
import {
  getBookingInHour,
  groupTimeSlot,
  getResrvedTime,
} from '../../../../services/timeSlots';
import {
  setCollapsedSlots,
  setCurrentBooking,
  setCurrentSlot,
  setSomeSlotsShown,
  shouldSearchInputFocus,
} from '../../../../store/actions';
import { rootState } from '../../../../store/reducers';
import { Booking, TimeSlot } from '../../../../types';
import {
  getBookingColor,
  getBookingStatusColor,
} from '../../../../utils/booking';
import getFullName from '../../../../utils/getFullName';
import { formatTimeAmPm, getAgeFromBD } from '../../../../utils/moment';
import './UnitPanelBookings.scss';

type Props = {
  isAllSlotsShown: boolean;
  isAllBookings: boolean;
};

const UnitPanelBookings: React.FC<Props> = ({ isAllBookings }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [timeSlots, setTimeSlots] = useState<any>({});

  const collapsedSlots = useSelector(
    (state: rootState) => state.booking.collapsedSlots
  );

  const showAll = useSelector((state: rootState) => state.booking.showAll);
  const showSomeSlots = useSelector(
    (state: rootState) => state.booking.someSlotsShown
  );
  const currentBooking = useSelector(
    (state: rootState) => state.booking.currentBooking
  );

  const allBookings = useSelector((state: rootState) => state.booking.bookings);
  const bookingDate = useSelector(
    (state: rootState) => state.booking.bookingDate
  );
  const currentUnitDetails = useSelector(
    (state: rootState) => state.booking.currentUnitDetails
  );

  useEffect(() => {
    setTimeSlots(null);
    // dispatch(setcurrentCollapsedIndex(''));
    if (!currentUnitDetails) return;

    const sDate = new Date(bookingDate).setHours(0, 0, 0, 0);
    const eDate = new Date(bookingDate).setHours(23, 59, 0, 0);

    const filteredBookings = allBookings.filter(
      (ele) => ele.date >= sDate && ele.date <= eDate
    );
    if (!isAllBookings) {
      const nonCompletedBookings = filteredBookings.filter(
        (booking) => booking.status !== bookingStatus.completed
      );
      updateBookingSlots(nonCompletedBookings);
      return;
    }

    updateBookingSlots(filteredBookings);
    // if (currentBooking) openBookingSlot(currentBooking);
    // eslint-disable-next-line
  }, [allBookings, bookingDate, currentUnitDetails, isAllBookings]);

  // show/hide all slots
  useEffect(() => {
    const collapsedData = { ...collapsedSlots };
    Object.keys(collapsedSlots).forEach((slot) => {
      if (showAll && !showSomeSlots) {
        collapsedData[slot] = true;
      } else if (!showAll && !showSomeSlots) {
        collapsedData[slot] = false;
      } else if ((showAll && showSomeSlots) || (!showAll && showSomeSlots)) {
        if (collapsedSlots[slot] === false) collapsedData[slot] = false;
        else collapsedData[slot] = true;
      }
    });
    dispatch(setCollapsedSlots({ ...collapsedData }));
    // eslint-disable-next-line
  }, [showAll]);

  const updateBookingSlots = (bookingArr: Booking[]) => {
    setTimeSlots(null);
    if (!currentUnitDetails) return;
    const slots = groupTimeSlot(
      bookingArr,
      currentUnitDetails,
      new Date(bookingDate)
    );
    let collapsedData = { ...collapsedSlots };
    if (Object.keys(collapsedSlots).length === 0 && bookingArr.length > 0) {
      Object.keys(slots).forEach((slot) => {
        collapsedData[slot] = true;
      });
    } else if (isAllBookings && showAll) {
      Object.keys(slots).forEach((slot) => {
        if (!Object.keys(collapsedSlots).includes(slot)) {
          const obj = {
            [slot]: true,
          };
          collapsedData = { ...collapsedData, ...obj };
        }
      });
    } else if (isAllBookings && !showAll) {
      Object.keys(slots).forEach((slot) => {
        if (!Object.keys(collapsedSlots).includes(slot)) {
          const obj = {
            [slot]: false,
          };
          collapsedData = { ...collapsedData, ...obj };
        } else {
          // collapsedData[slot] = false;
        }
      });
    }
    dispatch(setCollapsedSlots({ ...collapsedData }));

    setTimeSlots(slots);
    if (
      bookingArr.length > 0 &&
      (!currentBooking ||
        (currentBooking &&
          currentBooking.status === bookingStatus.completed &&
          !isAllBookings))
    ) {
      const activeBookingIndex = bookingArr.findIndex(
        (booking: any) => booking.status === bookingStatus.active
      );

      // set current displaying booking to the active booking if found
      // otherwise set it to the first booking in array
      const activeBooking =
        activeBookingIndex >= 0
          ? bookingArr[activeBookingIndex]
          : bookingArr[0];
      // showCollapsedHandler(slots);
      dispatch(setCurrentBooking(activeBooking));
      // openBookingSlot(activeBooking);
    } else {
      // if (currentBooking) openBookingSlot(currentBooking);
    }
  };

  const getNumberOfBookingsInTimeSlot = (timeSlotsData: TimeSlot[]) => {
    return timeSlotsData.filter((slot: TimeSlot) => slot.booking).length;
  };

  const getEmptyPlacesInTimeSlot = (
    timeSlotsData: TimeSlot[],
    slot: string
  ) => {
    const bookingInHour = getBookingInHour(currentUnitDetails); // 4
    const reservedTime = getResrvedTime(currentUnitDetails); // 15
    const kk = timeSlotsData.map((slot: any) => {
      return new Date(slot.booking.date).getMinutes();
    });
    const emptyPlaces = [];
    let i = 0;

    while (i < bookingInHour) {
      if (!kk.includes(reservedTime * i)) emptyPlaces.push(reservedTime * i);
      i += 1;
    }
    const now = new Date();
    let availableooking = true;
    if (+slot.split(':')[0] === now.getHours()) {
      emptyPlaces.forEach((min: any) => {
        if (min < now.getMinutes()) {
          availableooking = false;
        } else {
          availableooking = true;
        }
      });
    }
    return availableooking;
  };

  const calcAgeHandler = (patient: any) => {
    let age: any;
    if (patient && patient.dob) {
      const patientAge = getAgeFromBD(new Date(patient.dob));
      age = `${patientAge.split(' ')[0]} ${t(
        'booking_page.' + patientAge.split(' ')[1]
      )}`;
    } else {
      age = `${patient.age} ${t('booking_page.age')}`;
    }

    return age;
  };

  const showHideCollapsedHandler = (slot: string) => {
    const collapsedData = { ...collapsedSlots };
    collapsedData[slot] = !collapsedData[slot];
    dispatch(setCollapsedSlots({ ...collapsedData }));
    dispatch(setSomeSlotsShown(true));
  };

  const setCurrentBookingHandler = (booking: Booking | null) => {
    if (!booking || currentBooking?.sk === booking.sk) return;
    if (booking) dispatch(setCurrentBooking(booking));
  };

  const setTimeSlotAmPmHandler = (timeslot: string) => {
    const hour = timeslot.split(':')[0];
    const minute = timeslot.split(':')[1];
    const timeSlotDate = new Date().setHours(+hour, +minute, 0, 0);
    return `${formatTimeAmPm(new Date(timeSlotDate)).split(' ')[0]} ${t(
      'booking_page.' + formatTimeAmPm(new Date(timeSlotDate)).split(' ')[1]
    )}`;
  };

  const addBookingHandler = (slot: string) => {
    dispatch(shouldSearchInputFocus(true));
    if (!slot) return;
    dispatch(setCurrentSlot(slot));
  };

  return (
    <>
      {timeSlots && Object.keys(timeSlots).length > 0
        ? Object.keys(timeSlots)
            .sort()
            .map((timSlot) => (
              <div
                key={`timSlot-${timSlot}`}
                className="booking-item-container"
                style={{
                  border: `2px solid var(--color-primary-dark1)`,
                }}
              >
                <div
                  className="date-time d-flex justify-content-between align-items-center"
                  style={{
                    backgroundColor: 'var(--color-primary-dark1)',
                  }}
                  onClick={() => {
                    showHideCollapsedHandler(timSlot);
                  }}
                >
                  <div>
                    <img
                      src={
                        collapsedSlots && collapsedSlots[timSlot]
                          ? collapseIcon
                          : moreIcon
                      }
                      alt="bookings"
                      onClick={() => {
                        showHideCollapsedHandler(timSlot);
                      }}
                    />
                  </div>
                  {/* slot time and number of bookings in slot */}
                  <div className="numbers flex-fill d-flex justify-content-between">
                    <p>{setTimeSlotAmPmHandler(timSlot)}</p>
                    <p>
                      {`${getNumberOfBookingsInTimeSlot(timeSlots[timSlot])}${
                        currentUnitDetails &&
                        currentUnitDetails.type === 'clinic'
                          ? '/' + getBookingInHour(currentUnitDetails)
                          : ''
                      }`}
                    </p>
                  </div>
                </div>
                <div className="booking-item-container-details">
                  <Collapse
                    isOpened={
                      // isAllBookings && timeSlots[timSlot].length==0  ?false:
                      collapsedSlots && collapsedSlots[timSlot]
                    }
                  >
                    {/* bookings in slot */}
                    {timeSlots[timSlot].map((ele: TimeSlot, ind: number) => {
                      return (
                        ele &&
                        ele.booking &&
                        ele.booking.sk && (
                          <div
                            key={`ele-${ind}-${timSlot}-${ele.booking.sk}`}
                            onClick={() => {
                              setCurrentBookingHandler(ele.booking);
                            }}
                            className={`booking-item d-flex align-items-center justify-content-between ${
                              currentBooking &&
                              currentBooking.sk === ele.booking.sk
                                ? 'current'
                                : ''
                            }`}
                            style={{
                              backgroundColor: `${getBookingColor(
                                ele.booking,
                                currentUnitDetails
                              )}`,
                            }}
                          >
                            <div className="d-flex align-items-center flex-fill">
                              <img
                                className="avatar"
                                src={
                                  ele
                                    ? ele.booking.patient.gender === 'male'
                                      ? avatarIcon
                                      : GirlAvatarIcon
                                    : ''
                                }
                                alt="avatarIcon"
                              />
                              {/* patient name and age */}
                              <div className="d-flex flex-column justify-content-start align-items-start flex-fill">
                                <p className="ar-font">
                                  {getFullName(
                                    ele && ele.booking
                                      ? ele.booking.patient.name
                                      : null
                                  )}
                                </p>
                                <span>
                                  {ele && ele.booking
                                    ? calcAgeHandler(ele.booking.patient)
                                    : ''}
                                </span>
                              </div>
                            </div>
                            {/* booking status and paid icons */}
                            <div
                              className={`d-flex icons ${ele.booking.status}`}
                            >
                              {/* paid icon */}
                              {ele.booking.paymentStatus !==
                              paymentStatus.paid ? (
                                <span className="payment d-flex align-items-center justify-content-center">
                                  $
                                </span>
                              ) : null}

                              {/* status icons */}
                              <span className={`status  ${ele.booking.status}`}>
                                {ele.booking.status === bookingStatus.hold ? (
                                  <PauseIcon
                                    fill={getBookingStatusColor(
                                      ele.booking.status
                                    )}
                                  />
                                ) : ele.booking.status ===
                                  bookingStatus.completed ? (
                                  <SolidCheck
                                    fill={getBookingStatusColor(
                                      ele.booking.status
                                    )}
                                  />
                                ) : ele.booking.status ===
                                  bookingStatus.active ? (
                                  <OutLineCheck
                                    fill={getBookingStatusColor(
                                      ele.booking.status
                                    )}
                                  />
                                ) : null}
                              </span>
                            </div>
                          </div>
                        )
                      );
                    })}
                    {/* if length of slot is not complete show empty div with add booking button */}
                    {getNumberOfBookingsInTimeSlot(timeSlots[timSlot]) <
                      getBookingInHour(currentUnitDetails) &&
                    getEmptyPlacesInTimeSlot(timeSlots[timSlot], timSlot) ? (
                      <div className="booking-item d-flex align-items-centerd-flex justify-content-center">
                        {currentUnitDetails &&
                        currentUnitDetails.type === 'clinic' &&
                        (+timSlot.split(':')[0] >= new Date().getHours() ||
                          new Date(bookingDate) > new Date()) ? (
                          <button
                            className="d-flex justify-content-center align-items-center"
                            onClick={() => {
                              addBookingHandler(timSlot);
                            }}
                          >
                            <img src={showIcon} alt="showIcon" />
                            <span>{t('booking_page.add-booking')}</span>
                          </button>
                        ) : null}
                      </div>
                    ) : null}
                  </Collapse>
                </div>
              </div>
            ))
        : null}
    </>
  );
};

export default UnitPanelBookings;
