import { getClinicBookings } from '../../services/api/booking';
import {
  endPatientBooking,
  findPatientById,
  getPatientBookingHistory,
} from '../../services/api/patient';
import { warnToast } from '../../utils/toast';
import {
  RESET_BOARD_BOOKING,
  RESET_HISTORY,
  RESET_RESERVATION,
  SET_ACTIVE_SECTION,
  SET_ATTACHMENTS,
  SET_BOARD_BOOKINGS,
  SET_CURRENT_ENCOUNTER,
  SET_CURRENT_PATIENT,
  SET_CURRENT_VIEW,
  SET_HIDE_ALL,
  SET_HISTORY,
  SET_PHYS_ALLBOOKINGS,
  SET_PHYS_COLLAPSED_SLOTS,
  SET_SOMESLOTS_OPEN,
  SET_TESTS,
  TOGGLE_BOARD_MENU,
  UPDATE_RESERVATION,
  START_EXAMINATION,
  SET_BOARD_CURRENT_BOOKING,
  RESET_BOARD,
} from '../actions/actionTypes';
import { Booking } from '../../types';
import { IS_ATTACHMENT_UPLOADING, SET_EXPIRATION_MODAL } from './actionTypes';

export const updateReservation = (data: any) => async (dispatch: any) => {
  dispatch({ type: UPDATE_RESERVATION, payload: data });
};

export const setCurrentViewAction = (view: string) => async (dispatch: any) => {
  dispatch({ type: SET_CURRENT_VIEW, payload: { view } });
};

export const setTests = ({
  tests,
  patientId,
  encounter,
  testType,
}: any) => async (dispatch: any, getState: any) => {
  const {
    booking: { currentPatient },
  } = getState();
  const {
    board: { currentBooking },
  } = getState();
  if (
    currentBooking?.patientId === patientId ||
    currentPatient?.pk === patientId
  )
    dispatch({ type: SET_TESTS, payload: { encounter, tests, testType } });
};

export const endBookingAction = (encounter: any) => async (dispatch: any) => {
  try {
    const {
      data: { booking },
    } = await endPatientBooking(encounter);
    dispatch(updateCurrentBooking(booking));
    dispatch({
      type: UPDATE_RESERVATION,
      payload: { status: booking.status, lastChange: null },
    });
  } catch (error) {
    console.error(error);
  }
};

export const getPatientBookingHistoryAction = (id: any, unit: string) => async (
  dispatch: any,
  getState: any
) => {
  try {
    dispatch({ type: RESET_HISTORY });
    const { data } = await getPatientBookingHistory({ id, unit });
    const { history, labHistory, radHistory } = data;
    const {
      board: { currentBooking },
    } = getState();
    // console.log({ history, labHistory, radHistory });
    const sortedHistory = history.sort((a: any, b: any) => {
      const dateA = new Date(a._ct);
      const dateB = new Date(b._ct);
      return dateB.getTime() - dateA.getTime();
    });
    if (currentBooking && currentBooking.patient.pk === id) {
      const currentEnc = sortedHistory.find((enc: any) =>
        enc.encounter.includes(currentBooking.sk)
      );

      if (currentEnc) {
        dispatch({
          type: UPDATE_RESERVATION,
          payload: {
            ...currentEnc,
            orders: [...currentEnc.lab, ...currentEnc.rad],
            lastChange: null,
          },
        });
        dispatch({
          type: SET_ATTACHMENTS,
          payload: currentEnc.attachments || [],
        });
      }

      dispatch({
        type: SET_HISTORY,
        payload: { history: sortedHistory },
      });
      for (const enc of labHistory) {
        dispatch(
          setTests({
            encounter: enc.sk,
            patientId: enc.patient.pk,
            tests: enc.tests,
            testType: 'lab',
          })
        );
      }
      for (const enc of radHistory) {
        dispatch(
          setTests({
            encounter: enc.sk,
            patientId: enc.patient.pk,
            tests: enc.tests,
            testType: 'rad',
          })
        );
      }
    }
  } catch (error) {
    console.error(error);
  }
};

export const getPatientHistoryAction = (
  id: any,
  unit: string,
  t: any
) => async (dispatch: any, getState: any) => {
  try {
    dispatch({ type: RESET_HISTORY });

    const { data } = await getPatientBookingHistory({ id, unit });
    const { history, labHistory, radHistory } = data;
    const {
      booking: { currentPatient },
    } = getState();
    // console.log({ history, labHistory, radHistory });

    const sortedHistory = history.sort((a: any, b: any) => {
      const dateA = new Date(a._ct);
      const dateB = new Date(b._ct);
      return dateB.getTime() - dateA.getTime();
    });
    if (sortedHistory.length === 0) warnToast(t('clinicBoard_page.noBooking'));

    const [currentEnc, ...encounters] = sortedHistory;
    if (currentEnc) {
      dispatch({
        type: UPDATE_RESERVATION,
        payload: {
          ...currentEnc,
          orders: [...currentEnc.lab, ...currentEnc.rad],
          allergy: currentPatient.allergy,
          chronic: currentPatient.chronic,
          lastChange: null,
        },
      });
      dispatch({
        type: SET_ATTACHMENTS,
        payload: currentEnc.attachments || [],
      });
    }

    dispatch({
      type: SET_HISTORY,
      payload: { history: encounters },
    });

    for (const enc of labHistory) {
      dispatch(
        setTests({
          encounter: enc.sk,
          patientId: enc.patient.pk,
          tests: enc.tests,
          testType: 'lab',
        })
      );
    }
    for (const enc of radHistory) {
      dispatch(
        setTests({
          encounter: enc.sk,
          patientId: enc.patient.pk,
          tests: enc.tests,
          testType: 'rad',
        })
      );
    }
  } catch (error) {
    console.error(error);
  }
};

export const resetReservation = () => async (dispatch: any) => {
  dispatch({ type: RESET_RESERVATION });
};

export const isAttachmentUploading = (value: boolean) => async (
  dispatch: any
) => {
  dispatch({ type: IS_ATTACHMENT_UPLOADING, payload: value });
};

export const getCurrentPatientProfileAction = (
  id: string,
  unit: string
) => async (dispatch: any) => {
  try {
    const { data } = await findPatientById({ id, unit });
    const { patient } = data;
    dispatch({ type: SET_CURRENT_PATIENT, payload: patient });
    dispatch({
      type: UPDATE_RESERVATION,
      payload: {
        allergy: patient.allergy || [],
        chronic: patient.chronic || [],
        lastChange: null,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const setBoardCurrentBooking = (booking: Booking | null) => async (
  dispatch: any
) => {
  dispatch({ type: RESET_HISTORY });
  dispatch({ type: SET_BOARD_CURRENT_BOOKING, payload: booking });
};

export const updateCurrentBooking = (booking: Booking) => async (
  dispatch: any,
  getState: any
) => {
  const {
    board: { currentBooking },
  } = getState();
  if (currentBooking && currentBooking.sk === booking.sk) {
    dispatch(setBoardCurrentBooking(booking));
  }
};

export const getClinicBookingsAction = (
  clinicId: string,
  startDate: number,
  endDate: number
) => async (dispatch: any) => {
  try {
    const data = await getClinicBookings({
      clinicId,
      startDate,
      endDate,
    });

    dispatch({
      type: SET_BOARD_BOOKINGS,
      payload: { bookings: data.data.bookings },
    });
  } catch (error) {
    console.error('error[getClinicBooking]', error);
  }
};

type Encounter = {
  diag: any[];
  pres: any[];
  allergy: any[];
  symp: any[];
  rad: any[];
  lab: any[];
  vitals: any;
  chronic: [];
  orders: [];
};
export const setCurrentEncounterAction = (encounter: Encounter) => async (
  dispatch: any
) => {
  dispatch({
    type: SET_CURRENT_ENCOUNTER,
    payload: {
      ...encounter,
      orders: [...(encounter.rad || []), ...(encounter.lab || [])],
    },
  });
};

export const toggleMenuAction = (open: boolean) => async (dispatch: any) => {
  dispatch({
    type: TOGGLE_BOARD_MENU,
    payload: {
      menuOpen: open,
    },
  });
};
export const StartExaminationAction = (start: boolean) => async (
  dispatch: any
) => {
  dispatch({
    type: START_EXAMINATION,
    payload: {
      startExam: start,
    },
  });
};

export const setActiveSectionAction = (
  column: number,
  section: string
) => async (dispatch: any) => {
  dispatch({
    type: SET_ACTIVE_SECTION,
    payload: { activeSection: { column, section } },
  });
};

export const resetBoardBooking = () => async (dispatch: any) => {
  dispatch({ type: RESET_BOARD_BOOKING });
};

export const setExpirationModal = (value: boolean) => async (dispatch: any) => {
  dispatch({ type: SET_EXPIRATION_MODAL, payload: value });
};
export const setPhysCollapsedSlots = (slots: any[]) => async (
  dispatch: any
) => {
  dispatch({
    type: SET_PHYS_COLLAPSED_SLOTS,
    payload: slots,
  });
};
export const setPhysAllBookings = (allBookings: boolean) => async (
  dispatch: any
) => {
  dispatch({
    type: SET_PHYS_ALLBOOKINGS,
    payload: allBookings,
  });
};

export const setHideFlag = (hideFlag: boolean) => async (dispatch: any) => {
  dispatch({
    type: SET_HIDE_ALL,
    payload: hideFlag,
  });
};

export const setSomeSlotsOpen = (someSlots: boolean) => async (
  dispatch: any
) => {
  dispatch({
    type: SET_SOMESLOTS_OPEN,
    payload: someSlots,
  });
};

export const resetBoard = () => async (dispatch: any) => {
  dispatch({
    type: RESET_BOARD,
  });
};
