const d = window.location.hostname.replace(/^www\./, '').split('.');
while (d.length > 2) {
  d.shift();
}
const url = 'https://api.' + d.join('.');

// const uatUrl = 'http://localhost:3001';
const uatUrl = 'https://api.codebee.xyz';

const baseUrl = process.env.NODE_ENV === 'production' ? url : uatUrl;

export const apiUrl: string = `${baseUrl}/api`;

export const socketUrl: string = baseUrl;
