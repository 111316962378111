import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import unitConfig from '../../assets/constants/unitConfig';
import ExpirationAlert from '../../components/Subscription/ExpirationAlert';
import WithSubscription from '../../hocs/WithSubscription';
import { setExpirationModal } from '../../store/actions/board.actions';
import { rootState } from '../../store/reducers';
import unitTypes from '../../utils/unitTypes';
import ClinicBoard from './ClinicBoard/ClinicBoard';
import LabBoard from './LabBoard/LabBoard';

const dateFromToday = (date: Date) => {
  const date1 = new Date().getTime();
  const date2 = new Date(date).getTime();
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

const Board: React.FC = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const currentUnitDetails = useSelector(
    (state: rootState) => state.booking.currentUnitDetails
  );
  const openExpirationModal = useSelector(
    (state: rootState) => state.board.openExpirationModal
  );
  useEffect(() => {
    const checkLanguage = async (lang: string) => {
      await i18n.changeLanguage(lang);
    };
    if (i18n.language === 'ar') {
      checkLanguage('en');
    }
  }, [i18n]);

  const setLoginCookie = (COOKIENAME: string) => {
    // current time
    const d = new Date();
    // beginning of next calendar day
    d.setTime(d.getTime() + 6 * 60 * 60 * 1000);
    document.cookie = COOKIENAME + '=1;expires=' + d.toUTCString() + ';path=/';
  };

  const isCookieSet = (name: string) => {
    const _name = name + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let c of ca) {
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(_name) === 0) {
        return true;
      }
    }
    return false;
  };

  const showAlert = () => {
    if (
      currentUnitDetails &&
      currentUnitDetails.subscription &&
      ((currentUnitDetails.subscription.name === 'payAsYouGo' &&
        currentUnitDetails.subscription.balance &&
        currentUnitDetails.subscription.balance <=
          unitConfig.alertMinBalance) ||
        (!currentUnitDetails.subscription.name &&
          currentUnitDetails.subscription.expirationDate &&
          dateFromToday(
            new Date(currentUnitDetails.subscription.expirationDate)
          ) <= unitConfig.alertMinDays))
    ) {
      if (!isCookieSet(currentUnitDetails.pk))
        dispatch(setExpirationModal(true));
    }
  };
  useEffect(() => {
    showAlert();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUnitDetails]);
  return (
    <WithSubscription currentUnitDetails={currentUnitDetails}>
      {currentUnitDetails?.type === unitTypes.clinic ? (
        <ClinicBoard />
      ) : [unitTypes.lab, unitTypes.image].includes(
          currentUnitDetails?.type || ''
        ) ? (
        <LabBoard />
      ) : null}

      {currentUnitDetails?.subscription && (
        <ExpirationAlert
          unit={currentUnitDetails}
          open={openExpirationModal}
          closeModal={() => {
            setLoginCookie(currentUnitDetails.pk);
            dispatch(setExpirationModal(false));
          }}
        />
      )}
    </WithSubscription>
  );
};

export default Board;
