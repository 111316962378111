import bookingStatus from '../../assets/constants/bookingStatus';
import { Booking } from '../../types';
import {
  ADD_ATTACHMENTS_FILE,
  ADD_BOARD_BOOKING,
  DELETE_ATTACHMENTS_FILE,
  IS_ATTACHMENT_UPLOADING,
  RESET_BOARD_BOOKING,
  RESET_RESERVATION,
  SET_ACTIVE_SECTION,
  SET_ATTACHMENTS,
  SET_BOARD_BOOKINGS,
  SET_CURRENT_ENCOUNTER,
  SET_CURRENT_VIEW,
  SET_HIDE_ALL,
  SET_HISTORY,
  SET_PHYS_ALLBOOKINGS,
  SET_PHYS_COLLAPSED_SLOTS,
  SET_SOMESLOTS_OPEN,
  SET_TESTS,
  TOGGLE_BOARD_MENU,
  START_EXAMINATION,
  UPDATE_RESERVATION,
  SET_BOARD_CURRENT_BOOKING,
  RESET_BOARD,
} from '../actions/actionTypes';
import { RESET_HISTORY, SET_EXPIRATION_MODAL } from './../actions/actionTypes';

const initialState: any = {
  bookings: [],
  labBookings: [],
  menuOpen: true,
  startExam: false,
  currentBooking: null,
  reservation: {
    vitals: {},
    symp: [],
    diag: [],
    chronic: [],
    orders: [],
    allergy: [],
    pres: [],
    tests: [],
    attachments: [],
    lastChange: null,
  },
  history: [],
  labEncounters: [],
  radEncounters: [],
  currentEnc: null,
  activeSection: {
    column: -1,
    section: null,
  },
  currentView: 'clinicalExam',
  physCollapsedSlots: [],
  physAllBookings: true,
  hideFlag: true,
  someSlotsOpen: true,
  isRequestFinished: false,
  attachments: [],
  isAttachmentsReset: false,
  openExpirationModal: false,
  deletedAttachments: null,
  isAttachmentsUploading: false,
};

const addBooking = (state: any, action: any) => {
  let bookings = [...state.bookings];
  const newBooking: Booking = action.payload;

  if (new Date(newBooking.date).getDate() !== new Date().getDate())
    return state;

  if (
    newBooking &&
    [bookingStatus.canceled, bookingStatus.deleted].includes(newBooking.status)
  ) {
    bookings = bookings.filter((ele) => ele.date !== newBooking.date);
  } else {
    const filteredBooking = bookings.findIndex(
      (ele) => ele.sk === newBooking.sk
    );
    if (filteredBooking >= 0) {
      bookings[filteredBooking] = newBooking;
    } else {
      bookings.push(newBooking);
    }
  }

  bookings.sort((a, b) => a.date - b.date);
  return { ...state, bookings };
};

const setTestsData = (state: any, action: any) => {
  const labEncounters = [...state.labEncounters];
  const radEncounters = [...state.radEncounters];

  const { tests, testType, encounter } = action.payload;
  if (testType === 'lab') {
    for (const test of tests) {
      const index = labEncounters.findIndex(
        (e: any) => e.enc === encounter && e.code === test.code
      );
      if (index >= 0) {
        labEncounters[index] = { ...labEncounters[index], ...test };
      } else {
        labEncounters.push({ encounter, ...test });
      }
    }
  } else {
    for (const test of tests) {
      const index = radEncounters.findIndex(
        (e: any) => e.enc === encounter && e.code === test.code
      );
      if (index >= 0) {
        radEncounters[index] = { ...radEncounters[index], ...test };
      } else {
        radEncounters.push({ encounter, ...test });
      }
    }
  }
  return { ...state, labEncounters, radEncounters };
};

const resetBooking = (state: any) => {
  return {
    ...state,
    bookings: [],
    labBookings: [],
    menuOpen: true,
    startExam: false,
    currentBooking: null,
    reservation: {
      vitals: {},
      symp: [],
      diag: [],
      chronic: [],
      orders: [],
      allergy: [],
      pres: [],
      tests: [],
      attachments: [],
      lastChange: null,
    },
    history: [],
    labEncounters: [],
    radEncounters: [],
    currentEnc: null,
    activeSection: {
      column: -1,
      section: null,
    },
    isRequestFinished: false,
    attachments: [],
    isAttachmentsReset: false,
    deletedAttachments: null,
    isAttachmentsUploading: false,
  };
};

export default function Board(state = initialState, action: any): any {
  switch (action.type) {
    case UPDATE_RESERVATION:
      return {
        ...state,
        reservation: { ...state.reservation, ...action.payload },
      };
    case SET_ATTACHMENTS:
      return {
        ...state,
        attachments: [...action.payload],
        isAttachmentsReset: false,
      };
    case ADD_ATTACHMENTS_FILE:
      return {
        ...state,
        attachments: [...state.attachments, action.payload],
        reservation: { ...state.reservation, lastChange: new Date() },
      };
    case DELETE_ATTACHMENTS_FILE:
      const allImages = [...state.attachments];
      const deletedAttachments = allImages.splice(action.payload, 1);
      return {
        ...state,
        attachments: [...allImages],
        deletedAttachments: deletedAttachments[0],
      };
    case SET_HISTORY:
      const currentEnc =
        action.payload.history.length > 0 ? action.payload.history[0] : null;
      return {
        ...state,
        history: action.payload.history,
        currentEnc,
        isAttachmentsReset: false,
        deletedAttachments: null,
        isAttachmentsUploading: false,
      };
    case RESET_HISTORY:
      return {
        ...state,
        history: [],
        labEncounters: [],
        radEncounters: [],
      };
    case RESET_RESERVATION:
      return {
        ...state,
        reservation: {
          vitals: {},
          symp: [],
          diag: [],
          chronic: [],
          orders: [],
          allergy: [],
          pres: [],
          tests: [],
          attachments: [],
          lastChange: null,
        },
        attachments: [],
        isAttachmentsReset: true,
        deletedAttachments: null,
        isAttachmentsUploading: false,
      };
    case SET_BOARD_BOOKINGS:
      return {
        ...state,
        bookings: action.payload.bookings,
        isRequestFinished: true,
      };
    case SET_BOARD_CURRENT_BOOKING:
      return { ...state, currentBooking: action.payload };
    case SET_CURRENT_ENCOUNTER:
      return {
        ...state,
        reservation: { ...state.reservation, ...action.payload },
      };
    case ADD_BOARD_BOOKING:
      return addBooking(state, action);
    case TOGGLE_BOARD_MENU:
      return {
        ...state,
        menuOpen: action.payload.menuOpen,
      };
    case START_EXAMINATION:
      return {
        ...state,
        startExam: action.payload.startExam,
      };
    case SET_ACTIVE_SECTION:
      return { ...state, activeSection: action.payload.activeSection };
    case RESET_BOARD_BOOKING:
      return resetBooking(state);
    case SET_CURRENT_VIEW:
      return { ...state, currentView: action.payload.view };
    case IS_ATTACHMENT_UPLOADING:
      return {
        ...state,
        isAttachmentsUploading: action.payload,
        reservation: { ...state.reservation, lastChange: new Date() },
      };
    case SET_TESTS:
      return setTestsData(state, action);
    case SET_PHYS_COLLAPSED_SLOTS:
      return { ...state, physCollapsedSlots: action.payload };
    case SET_PHYS_ALLBOOKINGS:
      return { ...state, physAllBookings: action.payload };
    case SET_HIDE_ALL:
      return { ...state, hideFlag: action.payload };
    case SET_SOMESLOTS_OPEN:
      return { ...state, someSlotsOpen: action.payload };
    case SET_EXPIRATION_MODAL:
      return { ...state, openExpirationModal: action.payload };
    case RESET_BOARD: {
      return initialState;
    }
    default:
      return state;
  }
}
