import React, { useEffect, useState } from 'react';
import ReactModal, { Styles } from 'react-modal';
import { useSelector } from 'react-redux';
import printIcon from '../../../../../assets/img/Mask Group 360.svg';
import { searchPresc } from '../../../../../services/api/search';
import * as PrescriptionService from '../../../../../services/prescription';
import { printPrescription } from '../../../../../services/print';
import { rootState } from '../../../../../store/reducers';
import SectionHeaderComponent from '../../SectionHeaderComponent/SectionHeaderComponent';
import './PrescriptionModal.scss';

type Props = {
  isOpen: boolean;
  onRequestClose: (data: any[]) => void;
  data: any[];
  symp?: any[];
};

const customStyles: Styles = {
  overlay: {
    background: 'none',
  },
  content: {
    position: 'absolute',
    top: 'none',
    left: '290px',
    right: '20px',
    bottom: '0',
    border: '1px solid #ccc',
    background: '#fff',
    // overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    borderRadius: '4px',
    outline: 'none',
    padding: '20px',
    minHeight: '300px',
    boxShadow: '0px 0px 20px #0000004D',
  },
};
const periods = ['days', 'weeks', 'months'];
const unitsOfOral = ['Cm', 'Small spoon', 'Big spoon'];
const durations = [
  '1 a day',
  '2 a day',
  '3 a day',
  '4 a day',
  'Morning',
  'Evening',
  'After  Meals',
  'Before Meals',
];

const PrescriptionModal = (props: Props) => {
  const { isOpen, onRequestClose, data, symp } = props;
  // const { t } = useTranslation();
  const [drugGroups, setDrugGroups] = useState<any>({});
  const [selectedDrugs, selectDrug] = useState<any>(data);
  const currentPatient = useSelector(
    (state: rootState) => state.booking.currentPatient
  );
  const onSelectDrug = (drug: any) => {
    selectDrug((selectedDrugss: any[]) => [...selectedDrugss, drug]);
  };
  const onRemoveDrug = (drug: any) => {
    selectDrug((selectedDrugss: any[]) =>
      selectedDrugss.filter((d: any) => d !== drug)
    );
  };
  const onChangeInput = (e: any, drug: any) => {
    const i = selectedDrugs.findIndex(
      (d: any) => d.tradeName === drug.tradeName
    );
    const selectedDrugsData = [...selectedDrugs];
    selectedDrugsData[i][e.target.name] = e.target.value;
    selectDrug(selectedDrugsData);
  };
  const handlePrint = () => {
    printPrescription(currentPatient, selectedDrugs);
  };
  useEffect(() => {
    Search({ symptoms: symp?.map((s: any) => s.code) });
    // eslint-disable-next-line
  }, [JSON.stringify(symp)]);

  const Search = async (payload: any = {}) => {
    try {
      const { data: searchData } = await searchPresc(payload);
      setDrugGroups(PrescriptionService.getDrugsGroupedBySciName(searchData));
    } catch (error) {
      //
      // console.log(error);
    }
  };
  useEffect(() => {
    selectDrug([...data]);
  }, [data]);

  return (
    <ReactModal
      isOpen={isOpen}
      closeTimeoutMS={500}
      shouldCloseOnOverlayClick={true}
      onRequestClose={() => {
        onRequestClose(selectedDrugs);
      }}
      style={customStyles}
      ariaHideApp={false}
    >
      <div className="row prescription-modal">
        <div className="container-fluid">
          <div className="row prescription-modal-header">
            <div className="col-md-11">
              <SectionHeaderComponent
                title={'Prescription'}
                column={0}
                hasButton={true}
                // tslint:disable-next-line: no-empty
                onTextChange={(value: string) => {
                  Search({
                    text: value,
                    symptoms: symp?.map((s: any) => s.code),
                  });
                }}
                // tslint:disable-next-line: no-empty
                onDone={() => {
                  onRequestClose(selectedDrugs);
                }}
              />
            </div>
            <div className="col-md-1">
              <div className="p-2 d-flex justify-content-center ">
                <div className="p-2 print">
                  {/* eslint-disable-next-line */}
                  <a
                    className="btn btn-primary btn-lg align-self-center "
                    onClick={handlePrint}
                  >
                    <img src={printIcon} alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row prescription-modal-body">
            <div className="col-md-5">
              <div className="search-result">
                {Object.keys(drugGroups).map((group: any) => {
                  return (
                    <ul
                      className="list-group list-group-flush"
                      key={`group-${group}`}
                    >
                      <li className="list-group-item head">{group}</li>
                      {drugGroups[group].map((drug: any, i: number) => {
                        const exist = selectedDrugs.find(
                          (d: any) => d.tradeName === drug.tradeName
                        );
                        return (
                          <li
                            key={`d-${i}`}
                            className={`list-group-item ${
                              exist ? 'exist' : ''
                            }`}
                            onClick={() => {
                              if (exist) return;
                              onSelectDrug(drug);
                            }}
                          >
                            {drug.tradeName}
                          </li>
                        );
                      })}
                    </ul>
                  );
                })}
              </div>
            </div>

            <div className="col-md-7">
              <div className="selected-drugs" id="ffd">
                {selectedDrugs.map((drug: any) => {
                  return (
                    <div
                      className="form-row"
                      key={'selected-' + drug.tradeName}
                    >
                      <div className="form-group  form-inline col-md-12">
                        <div className="d-flex justify-content-between toget-newline">
                          <div className="controller-container title">
                            <label>{drug.tradeName}</label>

                            <button
                              type="button"
                              className="btn remove "
                              onClick={() => {
                                onRemoveDrug(drug);
                              }}
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="controller-container ">
                            <div className="controller-container bills">
                              <input
                                type="number"
                                className={
                                  drug.unit === 'Small spoon' ||
                                  drug.unit === 'Cm' ||
                                  drug.unit === 'Big spoon'
                                    ? 'form-control start '
                                    : 'form-control '
                                }
                                name="quantity"
                                value={drug.quantity}
                                onChange={(e) => onChangeInput(e, drug)}
                              />

                              {drug.unit === 'Small spoon' ||
                              drug.unit === 'Cm' ||
                              drug.unit === 'Big spoon' ? (
                                <select
                                  id="unitState"
                                  name="unit"
                                  value={drug.unit}
                                  className="form-control end"
                                  onChange={(e) => onChangeInput(e, drug)}
                                >
                                  {unitsOfOral.map((key: any) => {
                                    return (
                                      <option value={key} key={key}>
                                        {key}
                                      </option>
                                    );
                                  })}
                                </select>
                              ) : (
                                <label className="control-label end unit">
                                  {drug.unit}
                                </label>
                              )}
                            </div>
                            <div className="controller-container period-group">
                              <label className="control-label start">
                                Every
                              </label>
                              <select
                                id="inputState"
                                name="every"
                                className="form-control"
                                value={drug.every}
                                onChange={(e) => onChangeInput(e, drug)}
                              >
                                {durations.map((key: any) => {
                                  return (
                                    <option value={key} key={key}>
                                      {key}
                                    </option>
                                  );
                                })}
                                <option></option>
                              </select>
                            </div>
                            <div className="controller-container duration-group">
                              <label className="control-label start">For</label>

                              <input
                                type="number"
                                className="form-control start"
                                id="inputCity"
                                onChange={(e) => onChangeInput(e, drug)}
                                name="duration"
                                value={drug.duration}
                              />
                              <select
                                id="inputState"
                                name="durationExt"
                                value={drug.durationExt}
                                className="form-control end"
                                onChange={(e) => onChangeInput(e, drug)}
                              >
                                {periods.map((key: any) => {
                                  return (
                                    <option value={key} key={key}>
                                      {key}
                                    </option>
                                  );
                                })}
                                <option></option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};
export default PrescriptionModal;
