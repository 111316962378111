import React from 'react';
import labIcon from '../../assets/img/Mask Group 327.svg';
import historyIcon from '../../assets/img/Mask Group 330.svg';
import radiologyIcon from '../../assets/img/Mask Group 46.svg';
import clinicalExamIcon from '../../assets/img/Mask Group 53.svg';
import { formatCAlDate } from '../../utils/moment';

type BoardMenuProps = {
  currentView: any;
  setCurrentView: (view: string) => void;
  filteredHistory: any;
  setCurrentEncounter: React.Dispatch<any>;
  currentEncounter: any;
};

const BoardMenu: React.FC<BoardMenuProps> = ({
  currentView,
  setCurrentView,
  filteredHistory,
  setCurrentEncounter,
  currentEncounter,
}) => {
  return (
    <div className="list">
      <div className="list-group">
        <div
          className={`list-group-item list-group-item-action ${
            currentView === 'clinicalExam' ? 'active' : ''
          }`}
          onClick={() => {
            setCurrentView('clinicalExam');
          }}
        >
          <div className="d-flex w-100 justify-content-start">
            <img src={clinicalExamIcon} alt="" />
            <div className="item-title">Clinical Exam</div>
          </div>
        </div>

        <div
          className={`list-group-item list-group-item-action history ${
            currentView === 'historyView' ? 'active' : ''
          }`}
          onClick={() => {
            if (filteredHistory.length > 0) {
              setCurrentView('historyView');
              setCurrentEncounter(filteredHistory[0]);
            }
          }}
        >
          <ul className="list-group history-date-list">
            <li
              className={`list-group-item head d-flex w-100 justify-content-start`}
            >
              <img src={historyIcon} alt="" />
              <div className="item-title">History</div>
            </li>
            {currentView === 'historyView'
              ? filteredHistory.map((enc: any) => {
                  return (
                    <li
                      key={enc._ct}
                      className={`list-group-item ${
                        enc.sk === currentEncounter.sk ? 'selected' : ''
                      }`}
                      onClick={(e) => {
                        e.stopPropagation();
                        setCurrentEncounter(enc);
                      }}
                    >
                      {formatCAlDate(enc._ct)}
                    </li>
                  );
                })
              : filteredHistory &&
                filteredHistory.length > 0 && (
                  <li className="list-group-item">
                    {formatCAlDate(filteredHistory[0]._ct)}
                  </li>
                )}
          </ul>
        </div>

        <div
          className={`list-group-item list-group-item-action ${
            currentView === 'radiologyView' ? 'active' : ''
          }`}
          onClick={() => {
            setCurrentView('radiologyView');
          }}
        >
          <div className="d-flex w-100 justify-content-start">
            <img src={radiologyIcon} alt="" />
            <div className="item-title">Radiology</div>
          </div>
        </div>

        <div
          className={`list-group-item list-group-item-action ${
            currentView === 'labView' ? 'active' : ''
          }`}
          onClick={() => {
            setCurrentView('labView');
          }}
        >
          <div className="d-flex w-100 justify-content-start">
            <img src={labIcon} alt="" />
            <div className="item-title">Lab Results</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoardMenu;
