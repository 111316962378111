import React, { useEffect, useState } from 'react';
import './UnitName.scss';

import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import Input from '../../UI/Form/Input/Input';
import Button from '../../UI/Form/Button/Button';

import RegisterClinicDetails from '../../Layout/RegisterClinicDetails/RegisterClinicDetails';
import formValidationSchema from './FormValidation';
import governments from '../../../assets/constants/governments';
import areas from '../../../assets/constants/areas';

import Select from '../../UI/Form/Select/Select';
import { useLocation, useHistory } from 'react-router-dom';
import routes from '../../../assets/constants/routes';

import locationIcon from '../../../assets/img/location.svg';
import clinicIcon from '../../../assets/img/clinic.svg';
import labIcon from '../../../assets/img/labIcon.svg';
import imagingCenterIcon from '../../../assets/img/imagingCenterIcon.svg';
import apiService from '../../../services/api';
import EventTracker from '../../../utils/analytics';

type Props = {
  nextStepHandler: (payload: { type: string; data: any }) => void;
  currentSegment: string;
};

const UnitName: React.FC<Props> = ({ nextStepHandler, currentSegment }) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const [govSelcted, setGovSelcted] = useState('');
  const [name, setName] = useState('');
  const [promoCode, setPromo] = useState('');
  const [icon, setIcon] = useState('');
  const [gov, setGov] = useState('');
  const [area, setArea] = useState('');

  const setValuesHandler = (
    pageName: string,
    pageIcon: string,
    pagLocation: string,
    pagArea: string,
    promo: string
  ) => {
    setName(pageName);
    setIcon(pageIcon);
    setGov(pagLocation);
    setArea(pagArea);
    setPromo(promo);
  };
  useEffect(() => {
    const pathName = location.pathname;
    if (pathName === routes.ADD_CLINIC) {
      setValuesHandler(
        t('addClinic_page.clin-name'),
        clinicIcon,
        t('addClinic_page.clin-gov'),
        t('addClinic_page.clin-area'),
        t('addClinic_page.promoCode')
      );
    } else if (pathName === routes.ADD_LAB) {
      setValuesHandler(
        t('addClinic_page.lab-name'),
        labIcon,
        t('addClinic_page.lab-gov'),
        t('addClinic_page.clin-area'),
        t('addClinic_page.promoCode')
      );
    } else if (pathName === routes.ADD_IMAGING_CENTER) {
      setValuesHandler(
        t('addClinic_page.image-name'),
        imagingCenterIcon,
        t('addClinic_page.image-gov'),
        t('addClinic_page.clin-area'),
        t('addClinic_page.promoCode')
      );
    } else {
      history.push(routes.HOME);
    }
  }, [t, location, history]);

  return (
    <div className="clinic-name-container d-flex flex-column-reverse flex-sm-row ">
      <div className="form-container d-flex align-items-center justify-content-center flex-column p-3 p-sm-5">
        <div className="align-items-center align-self-stretch d-flex flex-column mt-0 mt-sm-5">
          <div className="form-container__form  align-items-center d-flex flex-column p-5">
            <Formik
              initialValues={{
                name: '',
                gov: '',
                area: '',
                promoCode: '',
              }}
              validationSchema={formValidationSchema(t)}
              onSubmit={async (values) => {
                // console.log('values', values);
                let valid = true;
                if (values.promoCode) {
                  valid = false;
                  const { data } = await apiService.validatePromoCode({
                    promoCode: values.promoCode,
                  });
                  if (data) valid = data.valid;
                }
                EventTracker.sendEvent('SET_UNIT_NAME', { ...values });

                if (valid) {
                  console.log(values);
                  nextStepHandler({ data: values, type: currentSegment });
                }
              }}
            >
              {({ handleSubmit, values }) => (
                <form
                  onSubmit={handleSubmit}
                  className="mt-3"
                  autoComplete="off"
                >
                  <div>
                    <div className="lock-icon d-flex align-items-center">
                      <img src={icon} alt="type name"></img>
                      <h6>{name}</h6>
                    </div>
                    <div className="passwordInput mt-3">
                      <Input name="name" type="text" placeholder={name} />
                    </div>
                  </div>
                  <div>
                    <div className="lock-icon d-flex align-items-center">
                      <img src={locationIcon} alt="lockIcon"></img>
                      <h6>{gov}</h6>
                    </div>
                    <div className="passwordInput mt-3">
                      <Select
                        label={t('register_page.location')}
                        name="gov"
                        options={Object.keys(governments).map((key) => ({
                          label:
                            i18n.language === 'ar'
                              ? governments[key].ar
                              : governments[key].en,
                          value: key,
                        }))}
                      />
                      {console.log()}
                    </div>
                  </div>
                  <div>
                    <div className="lock-icon d-flex align-items-center">
                      <img src={locationIcon} alt="lockIcon"></img>
                      <h6>{area}</h6>
                    </div>
                    <div className="passwordInput mt-3">
                      <Select
                        label={t('register_page.area')}
                        name="area"
                        options={
                          areas[values.gov]
                            ? Object.keys(areas[values.gov]).map((key) => ({
                                label:
                                  i18n.language === 'ar'
                                    ? areas[values.gov][key].ar
                                    : areas[values.gov][key].en,
                                value: key,
                              }))
                            : Object.keys({})
                        }
                      />
                    </div>
                  </div>
                  <hr className="promo-separator" />
                  <div>
                    <div className="promo d-flex align-items-center justify-content-center">
                      <h6>{t('addClinic_page.hasPromo')}</h6>
                    </div>
                    <div className="passwordInput mt-3">
                      <Input
                        name="promoCode"
                        type="text"
                        placeholder={promoCode}
                      />
                    </div>
                  </div>
                  <Button type="submit" name={t('login_page.next')} />
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <RegisterClinicDetails />
    </div>
  );
};

export default UnitName;
